import styled from "styled-components";

export const DateAndTime = styled.div`
    span {
        white-space: nowrap;
    }
    span:first-of-type:after { 
        content: ', ';
    }
    @media screen and (max-width: 812px){
        span:first-of-type:after { 
            content: ', \\a ';
            white-space: pre;
        }
    }
`;
//container is a temp hack for the top nav bar menu, once it's replaced, it should be removed and only fluid should remain
export const MainContainer = styled.div.attrs((props: { fluid?: boolean }) => ({ className: props.fluid ? 'fluid container' : 'container' })) <{ fluid?: boolean }>`
    display: block;
    max-width: 100%;

    &.fluid {
        width:100%;
    }

    //mobile
    @media only screen and (max-width: 767.98px){
        :not(.fluid) {
            width:90%;
            margin-left: 1em;
            margin-right: 1em;
        }
    }

    //tablet
    @media only screen and (min-width: 768px) and (max-width: 991.98px){
        :not(.fluid) {
            width:723px;
            margin-left: auto;
            margin-right: auto;
        }
    }    

    //small monitor
    @media only screen and (min-width: 992px) and (max-width: 1199.98px){
        :not(.fluid) {
            width:933px;
            margin-left: auto;
            margin-right: auto;
        }
    }


    //large
    @media only screen and (min-width: 1200px) {
        :not(.fluid) {
            width:1127px;
            margin-left: auto;
            margin-right: auto;
        }
    } 
`;

export const TwoEqualColumnsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
`;

// export const ThreeEqualColumnsContainer = styled(TwoEqualColumnsContainer)`
//     grid-template-columns: 1fr 1fr 1fr;
// `

// export const TwoEqualColumnsSegmentContainer = styled(TwoEqualColumnsContainer)`
//     *:first-child {
//         margin-top:1rem;
//     }
//     *:last-child {
//         margin-bottom:1rem;
//     }

// `


export const AttachedChildrenContainer = styled.div`
    display: grid;
    & *:first-child {
        border-radius: 0.27rem 0 0 0.27rem;
    }
    & *:last-child {
        border-radius: 0 0.27rem 0.27rem 0;
    }
    & > *:not(:first-child) {
        margin-left:0px !important;
    }
    & > *:not(:last-child) {
        margin-right:0px !important;
    }
`;
export const AttachedChildrenContainerLeft = styled(AttachedChildrenContainer)`
    grid-template-columns: 1fr auto;
`;
export const ModalActionContainer = styled.div`
    background: var(--segmentBackgroundColor);
    padding: 1em 2em;
    text-align: right;
    & > * {
        margin-right:10px;
        &:last-child {
            margin-right:0px;
        }
    }
    & > button {
        min-width: 120px;
    }
`;
export const ModalHeaderContainer = styled.div`
    background: var(--segmentBackgroundColor);
    padding: 1em 2em 0.75em;
    font-size: 1.25rem;
`;
export const ModalContentContainer = styled.div`
    padding: 1em 2em;
`;

export const ManagementContainer = styled.div`
    padding-bottom: 1em;
`