import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";

export default class RealTimeConnection {
    name: string;
    groupId: string;
    sessionId: string;
    hubConnection: HubConnection | null = null;
    subscribedGroups: string[] = [];
    connectionState: HubConnectionState = HubConnectionState.Disconnected;
    onRealtimeReconnected: (() => Promise<void>) | undefined;
    getValidToken: (() => Promise<string | undefined>) | undefined;
    trace: boolean | null = JSON.parse(window.localStorage.getItem('trace') ?? "false");

    constructor(name: string, getValidToken: () => Promise<string | undefined>, groupId: string, sessionId: string, onRealtimeReconnected?: () => Promise<void>) {
        this.name = name;
        this.getValidToken = getValidToken;
        this.groupId = groupId;
        this.sessionId = sessionId;
        this.onRealtimeReconnected = onRealtimeReconnected;
    }

    async getToken(): Promise<string> {
        if (this.trace) console.debug('realTimeConnection', 'get token');
        if (this.getValidToken) {
            const token = await this.getValidToken();
            if (token) return token;
        }
        if (this.trace) console.error('real time requires token')
        return '';
    }


    async createHubConnection() {
        if (this.hubConnection !== null) return;
        this.hubConnection = new HubConnectionBuilder()
            .configureLogging(this.trace ? LogLevel.Debug : LogLevel.Error)
            .withUrl(process.env.REACT_APP_API_REALTIME_URL!, {
                //skipNegotiation: true,
                //transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => this.getToken(),
                headers: { "groupId": this.groupId }
            })
            .withAutomaticReconnect()
            .build();

        try {
            await this.hubConnection.start();
            //console.log('realTimeConnection','hub connection started', this.name);
            this.hubConnection?.onclose((error) => {
                console.error('realTimeConnection', 'SignalR', 'Connection Error', this.name, error);
                this.connectionState = this.hubConnection!.state;
            });
            //console.log('realTimeConnection','hubConnection', this.name, this.hubConnection)
            this.connectionState = this.hubConnection!.state;
            this.hubConnection?.onreconnecting(() => { this.connectionState = this.hubConnection!.state });
            this.hubConnection?.onreconnected(() => { this.connectionState = this.hubConnection!.state; this.rejoinGroups(); this.Reconnected(); });
        } catch (error) {
            console.error('realTimeConnection', 'Error establishing conection: ', this.name, error);
        }

    }

    Reconnected = async () => {
        if (this.trace) console.debug('realTimeConnection', 'Reconnected', this.onRealtimeReconnected !== undefined)
        if (this.onRealtimeReconnected) await this.onRealtimeReconnected();
    }

    async stopHubConnection() {
        try {
            await this.hubConnection?.stop();
            this.hubConnection = null;
        } catch (error) {
            console.error('realTimeConnection', error);
        }
    }

    async rejoinGroups() {
        this.subscribedGroups.forEach(async (groupName) => {
            if (this.trace) console.debug('realTimeConnection', 'rejoin group', groupName);
            await this.hubConnection?.invoke('AddToGroup', groupName, this.sessionId);
        })
    }

    async joinRealTimeGroup(groupName: string) {
        if (this.trace) console.debug('realTimeConnection', 'joinRealTimeGroup', groupName, this.hubConnection?.state)
        if (this.hubConnection?.state !== HubConnectionState.Connected || !groupName) {
            return;
        }
        //TODO: check if groupName was passed in and if it's not in the current groups
        //console.log('realTimeConnection','SignalR', 'Join Group', groupName);
        await this.hubConnection?.invoke('AddToGroup', groupName, this.sessionId);
        this.subscribedGroups.push(groupName);
    }

    async leaveRealTimeGroup(groupName: string, stopConnectionIfLast: boolean) {
        if (this.hubConnection?.state !== HubConnectionState.Connected) {
            return;
        }
        try {
            await this.hubConnection?.invoke('RemoveFromGroup', groupName);
            const index = this.subscribedGroups.indexOf(groupName, 0);
            if (index > -1) this.subscribedGroups.slice(index, 1);
            if (stopConnectionIfLast) {
                this.stopHubConnection();
            }
        } catch (error) {
            //console.error('realTimeConnection',error);
        }
    }

    async disconnect() {
        await this.hubConnection?.stop();
    }

}