
//visibilityOptions, isVisible, user-profileVisibility, groupTab/profile various uses
export enum VisibilityMode {
    Hidden = 0,
    OnlyMe = 1,
    Organizers = 2,
    GroupMembers = 3,
    Users = 4,
    Anonymous = 5
}

//GroupTabs
export enum VisibilityModeReadable {
    Organizers = 2,
    Members = 3,
    Everyone = 5
}