import { RestClient } from '@musicproworkshop/musicproworkshop.components';
import { ISessionQuestion } from 'features/questions/model';

const Questions = {
    list: (groupId: string, sessionId: string): Promise<ISessionQuestion[]> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/questions`),
    add: (groupId: string, sessionId: string, sessionQuestion: ISessionQuestion): Promise<ISessionQuestion> => RestClient.post(`/groups/${groupId}/sessions/${sessionId}/questions`, sessionQuestion),
    update: (groupId: string, sessionId: string, questionId: string, sessionQuestion: ISessionQuestion) => RestClient.put<ISessionQuestion>(`/groups/${groupId}/sessions/${sessionId}/questions/${questionId}`, sessionQuestion),
    delete: (groupId: string, sessionId: string, questionId: string) => RestClient.del(`/groups/${groupId}/sessions/${sessionId}/questions/${questionId}`)
}

const ApiClient = { Questions };
export default ApiClient;