import styled from "styled-components";

export const Statistic = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: 0 1.5em 1em;
    max-width: none;
    flex: 0 1 auto;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;

    &.horizontal {
        flex-direction: row;
        align-items: center;

        .value {
            display: inline-block;
            vertical-align: middle;
        }

        .label {
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 0 0.75em;
        }
    }

    .value {
        font-size: 4rem;
        font-weight: 400;
        line-height: 1em;
        color: var(--segmentTextColor);
        text-transform: uppercase;
        text-align:center;
        display: flex;
        align-items: center;
        justify-content: center;

        svg, i {
            margin-left:5px;
        }
    }

    .label {
        font-size:1em;
        font-weight: bold;
        color: var(--segmentSubtleTextColor);
        text-transform: uppercase;
        text-align:center;
    }

    .value ~ .label {
        margin-top: 0;
    }

    &.mini {
        .value {
            font-size: 1.5rem;
        }
    }

    &.small {
        .value {
            font-size: 3rem;
        }
    }

    &.small.horizontal {
        .value {
            font-size: 2rem;
        }
    }

    &.primary .value,
    &.primary .label {
        color:var(--primaryColor);
    }

    &.secondary .value, 
    &.secondary .label {
        color: var(--secondaryColor);
    }

    &.negative .value, 
    &.negative .label {
        color: #DB2828;
    }
    
    &.positive .value, 
    &.positive .label {
        color: #21BA45;
    }

    &.inverted .value, 
    &.inverted .label {
        color: #FFF;
    }
        
    
`


export const Statistics = styled.div<{ itemsPerRow?: number }>`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 1em -0.5em -1em;

    &:first-child {
        margin-top: 0;
    }
    ${Statistic}+${Statistic} {
        margin: 0 0 0 0.5em;
    }

    ${Statistic} {
        min-width:calc(${props => (100 / props.itemsPerRow!) + '%'} - 10px);
        margin: 0 0 1em;
    }

    &.right { 
        justify-content: flex-end;
    }
    &.right ${Statistic}.horizontal {
        justify-content: flex-end;
    }

    &.compact {
        ${Statistic} {
            margin-bottom: 0;
        }
    }

`;