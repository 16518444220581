import React from 'react'
import { Statistic as StatisticItem } from '../Styled';

export const Statistic = ({ label, value, className, horizontal, ...rest }: { label?: string, value?: string | number, className?: string, horizontal?: boolean, title?: string, as?: React.ElementType, to?: string }) => {
    return (
        <StatisticItem className={`${className}${horizontal ? ' horizontal' : ''}`} {...rest}>
            <div className='value'>{value}</div>
            {label && <div className='label'>{label}</div>}

        </StatisticItem >
    )
}

export default Statistic
