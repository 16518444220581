import { makeAutoObservable, runInAction } from "mobx";
import RealTimeConnection from "app/common/realtime/realTimeConnection";
import { groupBy, parseDate } from "app/common/utils";
import { IComment } from "features/comments/model/comment";
import commentsApiClient from "./commentsApi";

export class CommentsStore {
    constructor() {
        makeAutoObservable(this);
    }

    realTimeConnection: RealTimeConnection | undefined = undefined;
    loadingComments = false;
    comments = new Map();//: IComment[] = []
    realTimeGroup: string = '';
    sortByTrackTime: boolean = false;
    highlightedCommentId: string | undefined = undefined;
    subjectType: string | undefined = undefined;

    getValidToken: (() => Promise<string | undefined>) | undefined;
    userName: string = '';
    groupId: string = '';
    sessionId: string = '';

    //grouped by parent Id and sorted by date (render with no parent id, then for each one render all children)
    get commentsByParent() {
        return this.groupCommentsByParent(Array.from(this.comments.values()));
    }

    get timedComments() {

        return Array.from(this.comments.values()).filter(c => c.trackTime > 0);
    }

    //get comments with trackTime>0

    groupCommentsByParent(comments: IComment[]) {
        let sortedComments: IComment[] = [];
        if (this.sortByTrackTime && this.subjectType === 'track') {
            const commentsWithTime = comments.filter(c => c.trackTime > 0).sort((a, b) => {
                if (a.trackTime === b.trackTime)
                    return a.createdAt.getTime() - b.createdAt.getTime();
                else
                    return a.trackTime - b.trackTime;
            });
            if (commentsWithTime.length > 0) {
                const commentsWithoutTimeBefore = comments.filter(c => c.trackTime <= 0 && c.createdAt.getTime() < commentsWithTime[0].createdAt.getTime());
                const commentsWithoutTimeAfter = comments.filter(c => c.trackTime <= 0 && c.createdAt.getTime() > commentsWithTime[0].createdAt.getTime());
                sortedComments = commentsWithoutTimeBefore.concat(commentsWithTime).concat(commentsWithoutTimeAfter);
            }
        }
        if (comments.length > 0 && sortedComments.length === 0) {
            //either this isn't track comments or we had no comments with a time, just sort by createdAt
            sortedComments = comments.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
        }

        return Object.entries(groupBy(sortedComments, 'replyToId'));
    }

    initialize = async (getValidToken: () => Promise<string | undefined>, userName: string, groupId: string, sessionId: string, realTimeGroup: string) => {
        if (this.realTimeGroup === realTimeGroup) return;

        this.getValidToken = getValidToken;
        this.userName = userName
        this.groupId = groupId;
        this.sessionId = sessionId;
        this.realTimeGroup = realTimeGroup;

        runInAction(async () => {
            this.realTimeConnection = new RealTimeConnection('comments', getValidToken, this.groupId, this.sessionId, this.onRealtimeReconnected);
            await this.realTimeConnection?.createHubConnection();
            await this.realTimeConnection?.joinRealTimeGroup(realTimeGroup);
            this.subscribeToRealtimeEvents();
        });
    }
    onRealtimeReconnected = async () => {
        if (this.realTimeConnection?.trace) console.debug('commentsStore', 'onRealtimeReconnected');
    }

    setCommentsGroupId = (groupId: string) => {
        this.groupId = groupId;
    }

    loadComments = async (subjectType: string, subjectId: string) => {
        if (!this.groupId) return;
        this.subjectType = subjectType;
        this.loadingComments = true;
        try {
            let items = await commentsApiClient.list(this.groupId, subjectType, subjectId);

            //this.comments.clear();
            runInAction(() => {
                this.loadingComments = false;
                items.forEach(comment => {
                    this.setCommentProps(comment, this.userName, `${subjectType}_${subjectId}`);
                    //TODO the "id" should be subjectType_subjectId_commentId
                    //TODO if the comment has no parent, set the parent to subjectType_subjectId. instead of root in the widget, use that string to filter out only relevant comments. 
                    this.comments.set(comment.id, comment);
                });
            })
        } catch (error) {
            runInAction(() => {
                this.loadingComments = false;
            })

        }
    }

    getCommentsByParent = async (subjectType: string, subjectId: string) => {
        await this.loadComments(subjectType, subjectId);
        return this.groupCommentsByParent(Array.from(this.comments.values()));
    }

    subscribeToRealtimeEvents = () => {
        this.realTimeConnection?.hubConnection?.on('ReceiveComment', comment => {
            runInAction(() => {
                if (this.comments.has(comment.id)) {
                    let existingComment = this.comments.get(comment.id);
                    existingComment.body = comment.body;
                    this.comments.delete(existingComment.id);
                    this.comments.set(existingComment.id, existingComment);
                } else {
                    this.setCommentProps(comment, this.userName, `${comment.subjectType}_${comment.subjectId}`)
                    this.comments.set(comment.id, comment);
                }
            });
        })
        this.realTimeConnection?.hubConnection?.on('DeleteComment', commentId => {
            runInAction(() => {
                if (this.comments.has(commentId)) {
                    this.comments.delete(commentId);
                }
            });
        })
    }

    getCommentObject = (commentBody: string, subjectType: string, subjectId: string, parentId?: string, trackTime?: number) => {
        return {
            body: commentBody,
            replyToId: parentId ?? null,
            subjectType: subjectType,
            subjectId: subjectId,
            trackTime: trackTime ?? null
        };
    }

    addComment = async (commentBody: string, subjectType: string, subjectId: string, parentId?: string, trackTime?: number) => {
        let comment = this.getCommentObject(commentBody, subjectType, subjectId, parentId, trackTime);
        try {
            if (this.realTimeConnection?.hubConnection?.state !== "Connected") return false;
            await this.realTimeConnection?.hubConnection?.invoke('SendComment', this.realTimeGroup, comment);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    updateComment = async (commentId: string, commentBody: string) => {
        let comment = { id: commentId, body: commentBody };
        try {
            if (this.realTimeConnection?.hubConnection?.state !== "Connected") return false;
            await this.realTimeConnection?.hubConnection?.invoke('SendComment', this.realTimeGroup, comment);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    clearComments = () => {
        this.comments.clear();
    }

    setCommentProps = (comment: IComment, username?: string, subject?: string) => {
        comment.createdAt = parseDate(comment.createdAt)!;
        comment.isAuthor = username ? comment.authorUsername === username : false;
        if (!comment.replyToId && subject) {
            comment.replyToId = subject;
        }
        return comment;
    }

    deleteComment = async (commentId: string) => {
        //this.submitting = true;
        try {

            if (!this.groupId) return;
            if (this.realTimeConnection?.hubConnection?.state === "Connected") {
                await this.realTimeConnection?.hubConnection?.invoke('DeleteComment', this.realTimeGroup, commentId);
            } else {
                await commentsApiClient.delete(this.groupId, commentId);
            }
            runInAction(() => {
                this.comments.delete(commentId);
                //this.submitting = false;
            })

        } catch (error) {
            runInAction(() => {
                //this.submitting = false;
            })
        }
    }

    setSortByTrackTime = (on: boolean) => {
        this.sortByTrackTime = on;
    }

    closestComment = (time: number, limit: number = 3) => {
        if (this.timedComments?.length === 0) return undefined;
        var closest = this.timedComments.reduce(function (prev, curr) {
            return (Math.abs(curr.trackTime - time) < Math.abs(prev.trackTime - time) ? curr : prev);
        });
        if (Math.abs(closest.trackTime - time) < limit) {
            return closest;
        }
        return undefined;
    }
    highlightCommentByTime = (time?: number, limit: number = 3) => {
        if (!time) {
            this.highlightedCommentId = undefined;
            return;
        }
        let closest = this.closestComment(time, limit);
        this.highlightedCommentId = closest?.id;
    }

}