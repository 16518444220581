import { keyframes } from "styled-components";

export const LoaderAnimation = keyframes`
    100%{transform: rotate(360deg);}
`;

export const ShimmerAnimation = keyframes`
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
`
