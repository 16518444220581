import { LinkOptionType } from ".";

export const linkOptions: LinkOptionType[] = [
    { value: 'bandcamp', label: 'bandcamp', color: '#1da0c3', defaultValue: 'https://bandcamp.com' },
    { value: 'facebook', label: 'Facebook', color: '#0a82ed', defaultValue: 'https://facebook.com' },
    { value: 'instagram', label: 'Instagram', color: undefined, defaultValue: 'https://www.instagram.com/' },
    { value: 'linkedin', iconName: 'linkedin-in', label: 'LinkedIn', color: '#1873b9', defaultValue: 'https://www.linkedin.com/in/' },
    { value: 'twitch', label: 'Twitch', color: '#a970ff', defaultValue: 'https://www.twitch.tv/' },
    { value: 'twitter', label: 'Twitter', color: '#1ca0f2', defaultValue: 'https://twitter.com/' },
    { value: 'soundcloud', label: 'SoundCloud', color: '#ff6200', defaultValue: 'https://soundcloud.com/' },
    { value: 'spotify', label: 'Spotify', color: '#19d55d', defaultValue: 'https://open.spotify.com/artist/' },
    { value: 'vimeo', label: 'Vimeo', color: '#1a2e3a', defaultValue: 'https://www.vimeo.com/' },
    { value: 'youtube', label: 'YouTube', color: '#f00', defaultValue: 'https://youtube.com/channel/' },
    { value: 'audius', collection: 'fac', label: 'Audius', color: '#CC0FE0', defaultValue: 'https://audius.co' },
    { value: 'linkify', iconName: 'link', label: 'Other', color: undefined, defaultValue: 'https://' },
]
