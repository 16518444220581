import React from 'react'
import { ButtonBase } from '.'
import { ISessionButtonProps } from './SessionButtonProps'

export const SessionPlayerButton = ({ sessionPath, buttonColor = 'primary', right = true, hoverEffect = false }: ISessionButtonProps) => {
    return (
        <ButtonBase text='Open Player' iconName='music' title='Live Player' buttonColor={buttonColor} right={right} hoverEffect={hoverEffect} to={`${sessionPath}${sessionPath ? '/' : ''}player`} />
    )
}


export default SessionPlayerButton
