import React, { useEffect, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ButtonColor, FormInputContainer, SubHeader } from '@musicproworkshop/musicproworkshop.components';
import { TextButton } from 'app/common/controls';
import { ModalActionContainer, ModalHeaderContainer } from '../Styled';
import { MessageContainer, MessageContent } from './styled';
import NiceModalContainer, { ModalSize } from './NiceModalContainer';

export interface IConfirmProps {
    confirmHeader?: string;
    confirmContent?: React.ReactNode;
    confirmButtonText?: string;
    confirmButtonColor?: ButtonColor;
    cancelButtonText?: string;
    cancelButtonColor?: ButtonColor;
    confirmVerificationText?: string;
    confirmVerificationAnswer?: string;
    onConfirmed?: () => void;
}
interface IProps extends IConfirmProps {
    closeModal: () => void;
}
const ConfirmModalBody = ({ confirmHeader, confirmContent, confirmButtonText = 'Confirm', confirmButtonColor = 'primary', cancelButtonText = 'Cancel', cancelButtonColor = 'secondary', confirmVerificationText, confirmVerificationAnswer, onConfirmed, closeModal }: IProps) => {
    const [content, setContent] = useState<React.ReactNode>(confirmContent);
    const [valid, setValid] = useState<boolean>(false);
    const handleDecision = (confirmed: boolean) => {
        if (onConfirmed && confirmed) onConfirmed();
        closeModal();
    }

    useEffect(() => {
        //console.debug('ConfirmModalBody.useEffect', confirmVerificationText);
        if (confirmVerificationText) {
            if (confirmContent) {
                const content = <>{confirmContent}<br />
                    {confirmVerificationText} ({confirmVerificationAnswer})
                    <FormInputContainer>
                        <div className='input'>
                            <input type="text" placeholder={confirmVerificationAnswer} onChange={(e) => setValid(e.target.value === confirmVerificationAnswer)} />
                        </div>
                    </FormInputContainer>
                </>;
                setContent(content);
            } else {
                setContent(confirmVerificationText);
            }
        } else {
            setValid(true);
        }

    }, [confirmContent, confirmVerificationAnswer, confirmVerificationText]);

    return (
        <MessageContainer>
            {confirmHeader &&
                <ModalHeaderContainer>
                    <SubHeader headerColor='secondary' upper>{confirmHeader}</SubHeader>
                </ModalHeaderContainer>
            }

            {content && <MessageContent>{content}</MessageContent>}
            <ModalActionContainer>
                <TextButton text={cancelButtonText} title={cancelButtonText} buttonColor={cancelButtonColor} onClick={() => handleDecision(false)} />
                <TextButton text={confirmButtonText} title={confirmButtonText} buttonColor={confirmButtonColor} onClick={() => handleDecision(true)} disabled={!valid} />
            </ModalActionContainer>

        </MessageContainer>
    )

}

interface IConfirmModalProps extends IConfirmProps { size: ModalSize, blurring?: boolean, onlyCloseOnButton?: boolean }
const ConfirmModal = NiceModal.create(({ size, blurring, onlyCloseOnButton, ...confirmProps }: IConfirmModalProps) => {
    const modal = useModal();
    return <NiceModalContainer modalIsOpen={modal.visible} body={<ConfirmModalBody {...confirmProps} closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} blurring={blurring} onlyCloseOnButton={onlyCloseOnButton} />
});

export default ConfirmModal;