import { differenceInSeconds } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { parseDate } from "app/common/utils";
import { IMusicSubmissionFormValues, IPlaylistTrack, ISubmissionInvite, ITrack, ITrackSubmission, ITrackSubmissionFile, ITrackSubmissionManage, TrackPlaybackStatus } from "../model";
import tracksApiClient from "./tracksApi";

export class TracksStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingPlaylist = false;
    savingPlaylist = false;
    loadingTrack = false;
    uploadingMusic = false;
    uploadingPart: number = 0;
    uploadProgress: number = 0;
    playlist: IPlaylistTrack[] = [];
    track: ITrack | null = null;

    getFormData = (track: IMusicSubmissionFormValues, startIndex: number) => {
        try {
            let formData = new FormData();
            if (track.artist) formData.append('Artist', track.artist);
            if (track.title) formData.append('Title', track.title);
            if (track.memberUsername) formData.append('MemberUsername', track.memberUsername);
            if (track.hidden) formData.append('hidden', 'true');
            if (track.files) {
                //const f = track.files[0];
                //if a file was already added, set lastPart=false and return formData
                let partNumber = 0;
                for (var [index, f] of track.files.entries()) {
                    if (!f.deleted) ++partNumber;
                    if (index >= startIndex) {
                        const fileData = {
                            fileName: f.currentFileName,
                            partNumber: partNumber,
                            partName: f.partName,
                            id: f.id,
                            duration: f.duration,
                            peaks: JSON.stringify(f.peaks),
                            deleted: f.deleted
                        }
                        if (f.file) {
                            formData.append('Files', f.file);
                            fileData.fileName = f.file.name;
                            formData.append('FileData', JSON.stringify(fileData));
                            formData.append('lastPart', JSON.stringify(index === track.files!.length - 1));
                            this.uploadingPart = index;
                            return formData;
                        } else {
                            formData.append('FileData', JSON.stringify(fileData));
                        }
                    }
                }
            }
            if (track.url) {
                formData.append('Url', track.url);
                formData.append('FileData', JSON.stringify({ duration: track.duration }));
            }

            return formData;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    saveTrack = async (groupId: string, sessionId: string, track: IMusicSubmissionFormValues, inviteCode?: string) => {
        if (!track.artist || !track.title) return;
        if (!track.files && !track.url) return;

        this.uploadingMusic = true;
        this.uploadingPart = -1;
        try {
            let result: ITrackSubmission | null = null;
            let startIndex = 0;
            let filesAdded = 0;
            while ((track.files?.length ?? 1) > startIndex) {
                const formData = this.getFormData(track, startIndex);
                filesAdded = formData?.getAll('FileData').length ?? 0;
                startIndex = startIndex + filesAdded;
                if (!formData) {
                    this.uploadingMusic = false;
                    //TODO: show error
                    return;
                }
                // for (var pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                result = await tracksApiClient.saveTrack(groupId, sessionId, formData, track.id, inviteCode, (progress) => { this.uploadProgress = progress });
                track.id = result.id;
            }
            if (result) this.setTrackProps(result);
            runInAction(() => {
                this.uploadingMusic = false;
            })
            return result;

        } catch (error) {
            runInAction(() => {
                this.uploadingMusic = false;
            })
            console.error(error);
            toast.error('Problem submitting track');
        }
    }

    loadPlaylist = async (groupId: string, sessionId: string, manage?: boolean, videoStreamStartTime?: Date) => {
        this.loadingPlaylist = true;
        try {
            const playlist = await tracksApiClient.playlist(groupId, sessionId, manage ?? false);
            runInAction(() => {
                this.setPlaylist(playlist, videoStreamStartTime);
                this.loadingPlaylist = false;
            })
            return playlist;
        } catch (error) {
            runInAction(() => {
                this.loadingPlaylist = false;
            })
            toast.error('problem loading playlist');
        }
    }

    setPlaylist = (playlist: IPlaylistTrack[], videoStreamStartTime?: Date) => {
        let displayTrackNumber = 0;
        playlist.forEach(track => {
            if (!track.hidden) {
                displayTrackNumber++;
                track.displayTrackNumber = displayTrackNumber;
            }
            this.setTrackProps(track, videoStreamStartTime);
        })
        this.playlist = playlist;

    }

    loadTrackDetails = async (groupId: string, sessionId: string, trackId: string) => {
        try {
            this.loadingTrack = true;
            let track = await tracksApiClient.trackDetails(groupId, sessionId, trackId);
            runInAction(() => {
                this.track = track;
                this.loadingTrack = false;
            })
            return track;

        } catch (error) {
            runInAction(() => {
                this.loadingTrack = false;
            })
        }
    }
    clearTrack = () => {
        this.track = null;
    }


    updateTrackMetadata = async (groupId: string, sessionId: string, track: IMusicSubmissionFormValues) => {
        if (!track.artist || !track.title || !track.id) return;
        this.uploadingMusic = true;
        try {
            await tracksApiClient.updateTrackMetadata(groupId, sessionId, track.id!, track);
            runInAction(() => {
                this.setTrackProps(track as ITrackSubmission)
                this.track = track as ITrack;
                this.uploadingMusic = false;
                return track;
            })
        } catch (error) {
            runInAction(() => {
                this.uploadingMusic = false;
            })
            console.error(error)
            toast.error('Problem updating track');
        }
    }

    deleteTrack = async (groupId: string, sessionId: string, trackId: string, onlyFiles?: boolean) => {
        this.uploadingMusic = true;
        try {
            await tracksApiClient.deleteTrack(groupId, sessionId, trackId, onlyFiles);
            runInAction(() => {
                this.uploadingMusic = false;
            })

        } catch (error) {

            runInAction(() => {
                this.uploadingMusic = false;
            })
            toast.error('Problem deleting track');
        }
    }

    setTrackPlaybackStatus = (trackId: string, status: TrackPlaybackStatus) => {
        const trackIndex = this.playlist.findIndex(t => t.id === trackId);
        this.playlist[trackIndex].playbackStatus = status;
    }

    getTracksBySubmitter = (userName: string) => {
        let userTracks = this.playlist.filter(t => t.memberUsername === userName && !t.hidden).sort((a, b) => a.trackNumber - b.trackNumber);
        //console.log('getTrackBySubmitter', 'for', userName, toJS(userTracks), toJS(this.playlist))
        //if (userTracks) console.log('getTrackBySubmitter', toJS(userTracks[0]));
        return userTracks ? userTracks : [];
    }

    setTrackProps = (track: IPlaylistTrack, videoStreamStartTime?: Date) => {
        //console.log(track, videoStreamStartTime)
        if (track.playbackStarted) track.playbackStarted = parseDate(track.playbackStarted)!;
        if (videoStreamStartTime) track.playbackStartedSeconds = differenceInSeconds(track.playbackStarted, videoStreamStartTime);
        this.setSubmissionProps(track as ITrackSubmissionManage);
    }
    setSubmissionProps = (track: ITrackSubmissionManage) => {
        if (track.lastProcessingUpdate) track.lastProcessingUpdate = parseDate(track.lastProcessingUpdate)!;
        if (track.submittedOn) track.submittedOn = parseDate(track.submittedOn)!;
        if (track.files) {
            track.files.forEach(f => {
                this.setTrackFileProps(f as ITrackSubmissionFile)
            })
        }
    }
    setTrackFileProps = (trackFile: ITrackSubmissionFile) => {
        if (trackFile.lastProcessingUpdate) trackFile.lastProcessingUpdate = parseDate(trackFile.lastProcessingUpdate)!;
    }


    ///  ******************** MANAGEMENT *********************
    //These should be moved to separate store
    updateTrackSession = async (groupId: string, sessionId: string, trackId: string) => {
        this.uploadingMusic = true;
        try {
            await tracksApiClient.updateTrackSession(groupId, sessionId, trackId);
            runInAction(() => {
                //TODO: Call injected method to update groupSessionStore.item.submittedTracks
                this.uploadingMusic = false;
            })
        } catch (error) {
            runInAction(() => {
                this.uploadingMusic = false;
            })
            toast.error('Problem moving track');
        }
    }

    updateTrackTimestamp = async (groupId: string, sessionId: string, trackId: string, totalSeconds: number, videoStreamStartTime?: Date) => {
        this.uploadingMusic = true;
        try {
            const track = await tracksApiClient.updateTrackTimestamp(groupId, sessionId, trackId, { 'totalSeconds': totalSeconds });
            runInAction(() => {
                this.setTrackProps(track, videoStreamStartTime);
                const index = this.playlist.findIndex(t => t.id === trackId);
                this.playlist.splice(index, 1, track);
                this.uploadingMusic = false;
            })
        } catch (error) {
            runInAction(() => {
                this.uploadingMusic = false;
            })
            toast.error('Problem updating track start time');
        }
    }

    savePlaylist = async (groupId: string, sessionId: string, tracks: ITrack[]) => {
        this.savingPlaylist = true;
        try {
            await tracksApiClient.updatePlaylist(groupId, sessionId, tracks);
            runInAction(() => {
                this.savingPlaylist = false;
            })
        } catch (error) {
            runInAction(() => {
                this.savingPlaylist = false;
            })
            toast.error('problem updating playlist');
        }
    }

    loadInvites = async (groupId: string, sessionId: string) => {
        try {
            let invites = await tracksApiClient.invites(groupId, sessionId);
            invites.forEach(i => {
                i.expiresOn = parseDate(i.expiresOn)!;
            });
            runInAction(() => {
                //this.invites = invites;
            })
            return invites;
        } catch (error) {
            console.error(error)
        }
        return [];
    }

    saveInvite = async (groupId: string, sessionId: string, invite: ISubmissionInvite) => {
        try {
            var result = invite.id ?
                await tracksApiClient.updateInvite(groupId, sessionId, invite.id, invite) :
                await tracksApiClient.createInvite(groupId, sessionId, invite);

            runInAction(() => {
                //this.invites = invites;
            })
            if (result.expiresOn) result.expiresOn = parseDate(result.expiresOn);
            return result;
        } catch (error) {
            console.error(error)
        }
        return null;
    }

    deleteInvite = async (groupId: string, sessionId: string, inviteId: string) => {
        try {
            await tracksApiClient.deleteInvite(groupId, sessionId, inviteId);
            runInAction(() => {
                //this.invites = invites;
            })
        } catch (error) {
            console.error(error)
        }
    }

    checkInvite = async (groupId: string, sessionId: string, inviteId: string) => {
        try {
            var result = await tracksApiClient.checkInvite(groupId, sessionId, inviteId);
            return result;
        } catch (error) {
            console.error(error)
        }
        return 0;
    }

}