import React from 'react'
import { SubHeader } from '@musicproworkshop/musicproworkshop.components';
import { ModalActionContainer, ModalHeaderContainer } from '../Styled';
import { MessageContainer, MessageContent } from 'app/common/modal/styled';

interface IMessageProps {
    header?: React.ReactNode;
    content?: React.ReactNode;
    button?: React.ReactNode;
}

export const ModalMessage = ({ header, content, button }: IMessageProps) => {
    return (
        <MessageContainer>
            {header &&
                <ModalHeaderContainer>
                    <SubHeader headerColor='secondary' upper>{header}</SubHeader>
                </ModalHeaderContainer>
            }

            {content && <MessageContent>{content}</MessageContent>}
            {button &&
                <ModalActionContainer>
                    {button}
                </ModalActionContainer>
            }

        </MessageContainer>
    )
}

export default ModalMessage
