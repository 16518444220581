import React, { useState } from 'react'
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { FieldError, SubHeader, TextInput } from "@musicproworkshop/musicproworkshop.components";
import { useAccountStore } from 'app/stores/useRootStore';
import { ModalActionContainer, ModalContentContainer, ModalHeaderContainer } from 'app/common/Styled';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { FormSubmitButton } from 'app/common/controls';
import { FormElement } from "app/common/formInputs";
import { IUserFormValues, UserFormValues } from 'features/account/model/user';

const ChangePassword = ({ closeModal }: { closeModal: () => void }) => {
    const { changePassword } = useAccountStore();

    const [formValues] = useState<IUserFormValues>(new UserFormValues());

    const validationSchema = Yup.object({
        currentPassword: Yup.string().required('Current Password is Required'),
        password: Yup.string().required('Password is Required')
    })

    const onFormSubmit = async (data: IUserFormValues, formikHelpers: FormikHelpers<IUserFormValues>): Promise<void> => {
        try {
            await changePassword(data);
            closeModal();
        }
        catch (error: any) {
            formikHelpers.setErrors({ error })

        }
    }



    return (
        <Formik validationSchema={validationSchema} initialValues={formValues} onSubmit={onFormSubmit}>
            {({ handleSubmit, dirty, isValid, isSubmitting, errors }) => (
                <FormElement as={Form} onSubmit={handleSubmit} style={{ padding: 0 }}>
                    <ModalHeaderContainer>
                        <SubHeader headerColor='primary' upper>Change Password</SubHeader>
                    </ModalHeaderContainer>

                    <ModalContentContainer>

                        <TextInput name='currentPassword' label='Current Password' type='password' autoComplete='password' autoCorrect='false' icon='lock' iconPosition='left' showLiveFeedback />

                        <TextInput name='password' label='New Password' type='password' autoComplete='password' autoCorrect='false' icon='lock' iconPosition='left' showLiveFeedback />

                        {/* {forgotPasswordVisibile && forgotPasswordMessage && <Message content={forgotPasswordMessage} header='Email Sent' />} */}

                        <ErrorMessage
                            name='error' render={() =>
                                <FieldError style={{ marginBottom: 10 }}>{String(errors.error)}</FieldError>}
                        />

                    </ModalContentContainer>
                    <ModalActionContainer>
                        <FormSubmitButton text='Submit' title='Change Password' buttonColor='primary' fluid loading={isSubmitting} disabled={!dirty || !isValid} />
                    </ModalActionContainer>

                </FormElement>
            )}
        </Formik>


    );
};

export default ChangePassword

export const ChangePasswordModal = NiceModal.create(({ size }: { size: ModalSize }) => {
    const modal = useModal();
    return <NiceModalContainer modalIsOpen={modal.visible} body={<ChangePassword closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} />
});