import { VisibilityMode } from 'app/common/visibility';
import { IGroupContentTabSettings } from "features/contentitems/model";
import { IMembersTabContentSettings } from 'features/members/model/membersTabContentSettings';
import { INewsTabContentSettings } from "features/news/model";
import { ISessionsTabContentSettings } from "features/sessionlist/model";
import { TabContentType } from "./tabContentType";

export interface IGroupTab {
    id: string;
    sectionName: string;
    tabHeader: string;
    sortOrder: number;
    contentType: TabContentType;
    tabContentSettings: string | IGroupContentTabSettings | INewsTabContentSettings | ISessionsTabContentSettings | IMembersTabContentSettings;
    visibilityMode: VisibilityMode;
    defaultTabNewContentPriority: number;
    defaultTabPriority: number;
    defaultTabGuest: boolean;
    hasNewContent: boolean;
    deleted: boolean;
}

export class GroupTabFormValues implements Partial<IGroupTab>{
    id = '';
    sectionName = '';
    tabHeader = '';
    sortOrder = 0;
    contentType: TabContentType = TabContentType.GroupContent;
    visibilityMode: VisibilityMode = VisibilityMode.Organizers;
    defaultTabNewContentPriority = 0;
    defaultTabPriority = 0;
    defaultTabGuest = false;

    constructor(init?: IGroupTab) {
        Object.assign(this, init);
    }
}
