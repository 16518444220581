import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { SubHeader } from "@musicproworkshop/musicproworkshop.components";
import { ModalActionContainer, ModalContentContainer, ModalHeaderContainer } from 'app/common/Styled';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { TextButton } from 'app/common/controls';
import { useAccountStore } from 'app/stores/useRootStore';
import { IUserFormValues } from 'features/account/model/user';
import ValidationErrors from "features/account/views/ValidationError";

const ConfirmEmail = ({ closeModal }: { closeModal: () => void }) => {
    const { confirmEmail } = useAccountStore();
    const [confirming, setConfirming] = useState(true);
    const [confirmError, setConfirmError] = useState<AxiosResponse>();
    const { search } = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        if (searchParams.has('email') && searchParams.has('token')) {
            const data: IUserFormValues = {
                email: searchParams.get('email')!,
                token: searchParams.get('token')!,
                error: null
            };

            confirmEmail(data.token!, data.email!)
                .then(() => {
                    setConfirming(false);
                })
                .catch((error) => {
                    setConfirmError(error);
                    setConfirming(false);
                });

        }

    }, [search, confirmEmail])

    const close = () => {
        closeModal();
    }

    return (
        <div style={{ padding: 0 }}>
            <ModalHeaderContainer>
                <SubHeader headerColor='primary' upper>{confirming ? "Confirming Email" : confirmError ? "Email Confirmation Failed" : "Email Confirmed"}</SubHeader>
            </ModalHeaderContainer>

            {confirmError && (
                <ModalContentContainer>
                    <ValidationErrors errors={confirmError} />
                </ModalContentContainer>
            )}

            <ModalActionContainer>
                <TextButton text='OK' title='Close Message' loading={confirming} disabled={confirming} fluid style={{ marginTop: '40px' }} onClick={close} />
            </ModalActionContainer>

        </div>
    )
}

export default ConfirmEmail

export const ConfirmEmailModal = NiceModal.create(({ size }: { size?: ModalSize }) => {
    const modal = useModal();
    return <NiceModalContainer modalIsOpen={modal.visible} body={<ConfirmEmail closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} />
});