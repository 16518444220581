import { runInAction, toJS, makeAutoObservable } from "mobx";
import { v4 as uuid } from 'uuid';
import { HubConnectionState } from "@microsoft/signalr";
import RealTimeConnection from "app/common/realtime/realTimeConnection";
import { IGroupSession, SessionStatus } from "features/sessiondetails/model";
import { IPlaylistTrack, TrackSubmissionType, TrackPlaybackStatus, ITrackFile } from "features/tracks/model";
import { PlayerControlMessage, UserStatusMessage, UserMessageAction, UserMessageState, IPlaybackState, IPlayerFileInfo, PlayerFileLoadingState, PlayerFilePlaybackState } from "../model";

export class PlayerStore {
    constructor() {
        makeAutoObservable(this);
    }

    realTimeConnection: RealTimeConnection | undefined = undefined;
    initializing: boolean = false;
    subscribed: boolean = false;
    trackLoadedBy: string = '';
    useHighQuality: boolean = JSON.parse(window.localStorage.getItem("useHighQuality") ?? 'false');
    projected: boolean = JSON.parse(window.localStorage.getItem("projected") ?? 'false');
    syncOn: boolean = true;
    muted: boolean = false;
    volume: number = 0.75;
    currentPlayerTime: number = 0;

    syncSelectedTrackId: string | undefined = undefined;
    syncSelectedTrack: IPlaylistTrack | null = null;
    currentTrackParts: Map<number, IPlayerFileInfo> = new Map();
    incomingPlaybackState: IPlaybackState = { isPlaying: false, currentTime: 0 };
    syncDisabled: boolean = false;
    lastFinishedTrackId: string | undefined = undefined;
    videoStreamProgress: number = 0;
    //trackLoading: boolean = false;

    getValidToken: (() => Promise<string | undefined>) | undefined;
    userName: string = '';
    groupId: string = '';
    sessionId: string = '';
    isHost: boolean = false;
    sessionStatus: SessionStatus | null = null;
    hidden: boolean = false;

    //injected methods from trackSubmissionStore
    setPlaylist: ((playlist: IPlaylistTrack[], videoStreamStartTime?: Date) => void) | undefined;
    setTrackPlaybackStatus: ((trackId: string, status: TrackPlaybackStatus) => void) | undefined;

    get recap() {
        return this.sessionStatus === SessionStatus.Ended;
    }

    get sessionGroupName() {
        return `live_${this.sessionId}`;
    }

    get hostGroupName() {
        return `${this.sessionGroupName}_hosts`;
    }

    get userGroupName() {
        return this.userName ? `${this.sessionGroupName}_${this.userName}` : undefined;
    }

    get trackLoading(): boolean {
        return this.playerLoadingState === PlayerFileLoadingState.Loading;
    }

    get currentPlayerIsPlaying(): boolean {
        return this.playerPlaybackState === PlayerFilePlaybackState.Playing;
    }

    get playerLoadingState(): PlayerFileLoadingState {
        if ([...this.currentTrackParts.values()].filter(x => x.loadingState === PlayerFileLoadingState.Loading).length > 0) {
            //if any track part is loading
            return PlayerFileLoadingState.Loading;
        } else if (this.currentTrackParts.size > 0 && [...this.currentTrackParts.values()].filter(x => x.loadingState === PlayerFileLoadingState.Loaded).length === this.currentTrackParts.size) {
            //if all tracks parts are loaded
            return PlayerFileLoadingState.Loaded;
        }
        return PlayerFileLoadingState.Unknown;
    }

    get playerPlaybackState(): PlayerFilePlaybackState {
        if ([...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.Seeking).length > 0) {
            //if any track part is seeking
            return PlayerFilePlaybackState.Seeking;
        } else if (this.currentTrackParts.size > 0 && [...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.Ready).length === this.currentTrackParts.size) {
            //if all track parts are ready
            return PlayerFilePlaybackState.Ready;
        } else if ([...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.InteractionRequired).length > 0) {
            //if any track part can't play, set the InetractionRequired state so a modal will be displayed
            return PlayerFilePlaybackState.InteractionRequired;
        } else if ([...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.Playing).length > 0) {
            //if any track part is playing, assume we're in playing state
            return PlayerFilePlaybackState.Playing;
        } else if ([...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.Paused).length > 0) {
            //if any track part is paused, assume we're in paused state
            return PlayerFilePlaybackState.Paused;
        } else if (this.currentTrackParts.size > 0 && [...this.currentTrackParts.values()].filter(x => x.playbackState === PlayerFilePlaybackState.FinishedPlaying).length === this.currentTrackParts.size) {
            //if any track part is paused, assume we're in paused state
            return PlayerFilePlaybackState.FinishedPlaying;
        }

        return PlayerFilePlaybackState.Unknown;

    }

    get currentUserIsInControl() {
        return this.trackLoadedBy !== '' && this.userName === this.trackLoadedBy && !this.projected;
    }

    get qualityDisabled() {
        return this.syncSelectedTrack === undefined || this.syncSelectedTrack?.submissionType === TrackSubmissionType.VideoUrl;
    }

    joinRealTimeSessionGroups = async (sessionId: string) => {
        if (this.realTimeConnection?.hubConnection?.state !== HubConnectionState.Connected) {
            return;
        }
        await this.realTimeConnection?.hubConnection?.invoke('JoinLiveSessionGroups', sessionId);
    }

    initializePlayer = async (getValidToken: () => Promise<string | undefined>, userName: string, groupId: string, session: IGroupSession, setPlaylist: (playlist: IPlaylistTrack[], videoStreamStartTime?: Date) => void, setTrackPlaybackStatus: (trackId: string, status: TrackPlaybackStatus) => void, hidden: boolean = false) => {
        //console.log('playerStore', 'initializePlayer', 'for sessionId', sessionId);
        if (this.sessionId === session.id) return;

        this.resetPlayer();

        this.getValidToken = getValidToken;
        this.userName = userName
        this.groupId = groupId;
        this.sessionId = session.id;
        this.isHost = session.isHost;
        this.sessionStatus = session.status;
        this.hidden = hidden;

        this.setPlaylist = setPlaylist;
        this.setTrackPlaybackStatus = setTrackPlaybackStatus;

        //Don't connect to realtime for recaps
        if (this.recap) return;
        if (this.realTimeConnection?.trace) console.debug('Connect to InSyncPlayer Server');
        runInAction(async () => {
            this.realTimeConnection = new RealTimeConnection('player', getValidToken, this.groupId, this.sessionId, this.onRealtimeReconnected);
            await this.realTimeConnection?.createHubConnection();
            //await this.realTimeConnection?.joinRealTimeGroup(realTimeGroup);
            //console.log('playerStore', 'initializePlayer', 'for sessionId', sessionId, 'hub connection created', 'hub state', this.realTimeConnection?.hubConnection?.state);
            this.subscribeToPlayerRealtimeEvents(hidden);
            if (this.sessionId) {
                await this.joinRealTimeSessionGroups(this.sessionId);
                runInAction(() => {
                    this.subscribed = true;
                })
            }
            await this.sendUserStatusMessage(hidden ? UserMessageAction.HiddenConnected : UserMessageAction.Connected);

        });

    }

    resetPlayer = () => {
        this.setSelectedTrack(null);
        this.syncSelectedTrackId = undefined;
        this.incomingPlaybackState = { isPlaying: false, currentTime: 0 };
        this.currentPlayerTime = 0;
        this.sessionId = '';
        this.sessionStatus = null;
        this.lastFinishedTrackId = undefined;
    }

    getTrackFile = (trackFile: ITrackFile): IPlayerFileInfo | undefined => {
        if (trackFile.fileName) {
            let file = trackFile.fileName;
            if (this.useHighQuality && (trackFile.highQualityExt?.length ?? 0) > 0) {
                file = file.replace('.mp3', '.' + trackFile.highQualityExt);
            }
            return {
                uriWithQuality: `${process.env.REACT_APP_AZUREMUSIC_URI}${file}`,
                partNumber: trackFile.partNumber,
                partName: trackFile.partName,
                loadingState: undefined,
                playbackState: undefined,
                timeToLoad: 0,
            };
        }
        return undefined;
    }

    setSelectedTrack = (track: IPlaylistTrack | null) => {
        if (this.syncSelectedTrack === track) return;
        this.syncSelectedTrack = track;
        //set parts
        this.currentTrackParts.clear();
        if (this.syncSelectedTrack?.submissionType === TrackSubmissionType.VideoUrl) {
            this.currentTrackParts.set(1, {
                uriWithQuality: this.syncSelectedTrack?.url,
                partNumber: 1,
                partName: 'Video',
                loadingState: undefined,
                playbackState: undefined,
                timeToLoad: 0,
            });
        } else if (track?.files && (track.files.length ?? 0) > 0) {
            track.files.forEach(file => {
                const trackFile = this.getTrackFile(file);
                if (trackFile) {
                    this.currentTrackParts.set(trackFile.partNumber!, trackFile);
                }
            });
        }
    }

    setTrackPartLoadingState = (partNumber: number, state: PlayerFileLoadingState, timeToLoad: number) => {
        const trackPart = this.currentTrackParts.get(partNumber);
        if (trackPart) {
            //console.debug('playerStore', 'setTrackPartLoadingState', 'partNumber', partNumber, 'state', state);

            trackPart.loadingState = state;
            switch (state) {
                case PlayerFileLoadingState.Loading:
                    trackPart.loadStarted = new Date();
                    trackPart.timeToLoad = 0;
                    break;
                case PlayerFileLoadingState.Loaded:
                    trackPart.timeToLoad = timeToLoad;
                    break;
            }
        }
    }

    setTrackPartPlaybackState = (partNumber: number, state: PlayerFilePlaybackState) => {
        const trackPart = this.currentTrackParts.get(partNumber);
        if (trackPart) {
            //console.debug('playerStore', 'setTrackPartPlaybackState', 'partNumber', partNumber, 'state', state);
            trackPart.playbackState = state;
            if (state === PlayerFilePlaybackState.Playing) trackPart.timeToLoad = 0;//we're already playing, we won't need to account for load times on further seeks
        }
    }

    subscribeToPlayerRealtimeEvents = (hidden: boolean) => {
        //console.log('playerStore', 'subscribeToPlayerRealtimeEvents', 'for sessionId', this.sessionId);
        this.realTimeConnection?.hubConnection?.on('PlayerControlMessage', (playerControlMessage: PlayerControlMessage) => {
            runInAction(() => {
                //console.log('playerStore', 'player control message received', playerControlMessage);
                if (playerControlMessage.selectedTrackId == null && playerControlMessage.playbackState.isPlaying === false && playerControlMessage.playbackState.currentTime === 0) {
                    this.syncSelectedTrack = null;
                    this.syncSelectedTrackId = undefined;
                    this.incomingPlaybackState = { isPlaying: false, currentTime: 0 };
                } else {
                    if (this.syncSelectedTrackId !== playerControlMessage.selectedTrackId && playerControlMessage.selectedTrackId !== undefined) {
                        this.trackLoadedBy = playerControlMessage.sender!;
                        this.syncSelectedTrackId = playerControlMessage.selectedTrackId;
                        if (playerControlMessage.currentTrack) this.setSelectedTrack(playerControlMessage.currentTrack);
                    }
                    if (this.trackLoadedBy === playerControlMessage.sender && this.syncOn) {
                        //console.log('playerStore', 'this.incomingPlaybackState', toJS(this.incomingPlaybackState), 'playerControlMessage.playbackState', playerControlMessage.playbackState);
                        if (playerControlMessage.playbackState.currentTime < 0 || Math.abs(this.currentPlayerTime - playerControlMessage.playbackState.currentTime) < 2) {
                            this.incomingPlaybackState = { currentTime: -1, isPlaying: playerControlMessage.playbackState.isPlaying, mutedParts: playerControlMessage.playbackState.mutedParts, soloParts: playerControlMessage.playbackState.soloParts };
                        }
                        else {
                            this.incomingPlaybackState = playerControlMessage.playbackState;
                            if (playerControlMessage.playbackState.currentTime === 0 && !playerControlMessage.playbackState.isPlaying) {
                                //This is a Stop/Rewind message, reset the displayed time as well
                                this.currentPlayerTime = 0;
                            }
                        }
                    }
                }

            })
        })

        if (!hidden) {
            this.realTimeConnection?.hubConnection?.on('StateRequested', () => {
                runInAction(async () => {
                    await this.sendUserStatusMessage(UserMessageAction.StateChanged);
                })
            })
        }

        this.realTimeConnection?.hubConnection?.on('SessionStatusChanged', (status: SessionStatus) => {
            runInAction(async () => {
                this.sessionStatus = status;
                if (status === SessionStatus.Ended) {
                    this.realTimeConnection?.disconnect();
                }
            })
        })

        this.realTimeConnection?.hubConnection?.on('PlaylistUpdated', (playlist: IPlaylistTrack[]) => {
            runInAction(() => {
                if (this.setPlaylist) this.setPlaylist(playlist);
                //update currently loaded track's metadata
                if (this.syncSelectedTrack) {
                    const selectedTrack = playlist.find(t => t.id === this.syncSelectedTrackId);
                    //this will update everything except the file parts (so it won't interrupt playback)
                    if (selectedTrack) this.syncSelectedTrack = selectedTrack;
                }
            })
        })

    }

    onRealtimeReconnected = async () => {
        if (this.realTimeConnection?.trace) console.debug('playerStore', 'onRealtimeReconnected');//, 'Send message and sync');
        if (this.sessionId) {
            await this.joinRealTimeSessionGroups(this.sessionId);
        }
        await this.sendUserStatusMessage(this.hidden ? UserMessageAction.HiddenConnected : UserMessageAction.Connected);
    }

    sendPlayerCommand = async (selectedTrackId?: string, currentTrack?: IPlaylistTrack, isPlaying?: boolean, currentTime?: number, mutedParts?: number, soloParts?: number, sendTo?: string) => {
        try {
            //console.log('playerStore', 'sendPlayerCommand', 'for sessionId', this.sessionId);

            if (!this.isHost || !this.syncOn || !this.subscribed || this.projected) return;
            //Only send control messages if the current user loaded the track or it's a different track.
            if (selectedTrackId === this.syncSelectedTrackId && this.trackLoadedBy !== this.userName) {
                return;
            }
            if (currentTrack) currentTrack = toJS(currentTrack);
            let playerControlMessage: PlayerControlMessage = {
                messageId: uuid(),
                sessionId: this.sessionId,
                selectedTrackId: selectedTrackId,
                currentTrack: currentTrack,
                playbackState: {
                    isPlaying: isPlaying ?? false,
                    currentTime: currentTime ?? 0,
                    mutedParts: mutedParts ?? this.incomingPlaybackState.mutedParts,
                    soloParts: soloParts ?? this.incomingPlaybackState.soloParts
                }
            }
            if (sendTo) playerControlMessage.recipient = sendTo;
            //console.log('sendPlayerCommand', playerControlMessage);
            await this.realTimeConnection?.hubConnection?.invoke('SendPlayerControlMessage', playerControlMessage)
        } catch (error) {
            console.error(error);
        }
    }

    sendUserStatusMessage = async (action: UserMessageAction) => {
        //if data was passed in, shift it
        if (!this.subscribed) return;
        //console.log('playerStore', 'sendUserStatusMessage', 'for sessionId', this.sessionId, 'action', action, 'track', this.syncSelectedTrack?.id);

        //if (action === UserMessageAction.TrackStartedPlaying && this.setTrackPlaybackStatus) {
        //this.setTrackPlaybackStatus(data!, TrackPlaybackStatus.Playing);
        //} else 
        if (action === UserMessageAction.TrackFinishedPlaying && this.setTrackPlaybackStatus) {
            this.lastFinishedTrackId = this.syncSelectedTrackId;
            if (this.syncSelectedTrackId) this.setTrackPlaybackStatus(this.syncSelectedTrackId, TrackPlaybackStatus.Played);
        }

        let data = 0;
        // add the state
        data = data ^ UserMessageState.Connected; //this is really not needed...
        if (this.useHighQuality) data = data ^ UserMessageState.QualityHigh;
        if (this.syncOn) data = data ^ UserMessageState.SyncOn;
        if (this.currentPlayerIsPlaying) data = data ^ UserMessageState.IsPlaying;
        if (this.lastFinishedTrackId !== undefined && this.syncSelectedTrackId === this.lastFinishedTrackId) data = data ^ UserMessageState.IsFinished;
        if (this.currentUserIsInControl) data = data ^ UserMessageState.UserInControl;
        if (this.projected) data = data ^ UserMessageState.Projected;

        try {
            let userStatusMessage: UserStatusMessage = {
                messageId: uuid(),
                deviceId: window.localStorage.getItem('device'),
                sessionId: this.sessionId,
                action: action,
                trackId: this.syncSelectedTrack?.id,
                data: data
            };
            //console.log('playerStore', 'sendUserStatusMessage', 'for sessionId', this.sessionId, 'action', action, 'data', data, userStatusMessage);
            await this.realTimeConnection?.hubConnection?.invoke('SendUserStatusMessage', userStatusMessage);
        } catch (error) {
            console.error(error);
        }
    }

    sendPlayerSyncMessage = (sendTo: string) => {
        this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack ?? undefined, this.incomingPlaybackState.isPlaying, this.currentPlayerTime, this.incomingPlaybackState.mutedParts, this.incomingPlaybackState.soloParts, sendTo);
    }

    play = () => {
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, true, -1);
        } else {
            this.incomingPlaybackState = { isPlaying: true, currentTime: -1, mutedParts: this.incomingPlaybackState.mutedParts, soloParts: this.incomingPlaybackState.soloParts };
        }
    }

    pause = () => {
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, false, -1);
        } else {
            this.incomingPlaybackState = { isPlaying: false, currentTime: -1, mutedParts: this.incomingPlaybackState.mutedParts, soloParts: this.incomingPlaybackState.soloParts };
        }
    }

    rewind = () => {
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, false, 0);
        } else {
            this.incomingPlaybackState = { isPlaying: false, currentTime: 0, mutedParts: this.incomingPlaybackState.mutedParts, soloParts: this.incomingPlaybackState.soloParts };
        }
    }
    seek = (seekTime: number) => {
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, this.incomingPlaybackState.isPlaying, seekTime);
        } else {
            this.incomingPlaybackState = { isPlaying: this.incomingPlaybackState.isPlaying, currentTime: seekTime, mutedParts: this.incomingPlaybackState.mutedParts, soloParts: this.incomingPlaybackState.soloParts };
        }
    }

    mutePart = (partNumber: number) => {
        let mutedParts = this.incomingPlaybackState.mutedParts ?? 0;
        mutedParts ^= (1 << partNumber);
        let soloParts = this.incomingPlaybackState.soloParts ?? 0;
        if (soloParts > 0) {
            soloParts = ((1 << this.currentTrackParts.size + 1) - 1);
            soloParts ^= mutedParts;
        }
        //console.debug('mutePart', 'soloed', soloParts, 'muted', mutedParts);
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, this.incomingPlaybackState.isPlaying, this.incomingPlaybackState.currentTime, mutedParts, soloParts);
        } else {
            this.incomingPlaybackState = { isPlaying: this.incomingPlaybackState.isPlaying, currentTime: this.incomingPlaybackState.currentTime, mutedParts: mutedParts, soloParts: soloParts };
        }
    }

    soloParts = (partNumber: number, ctrlDown?: boolean) => {
        let soloParts = 0;
        if (ctrlDown) {
            soloParts = this.incomingPlaybackState.soloParts ?? 0;
            soloParts ^= (1 << partNumber);
        } else if (1 << partNumber === this.incomingPlaybackState.soloParts) {
            //single soloed part, turning it off just means we're not soloing any part
            soloParts = 0;
        } else {
            soloParts = (1 << partNumber);
        }
        let mutedParts = (1 << this.currentTrackParts.size + 1) - 1;
        mutedParts ^= soloParts;
        //console.debug('soloParts', 'soloed', soloParts, 'muted', mutedParts);
        if (this.syncOn && !this.recap) {
            this.sendPlayerCommand(this.syncSelectedTrackId, this.syncSelectedTrack!, this.incomingPlaybackState.isPlaying, this.incomingPlaybackState.currentTime, soloParts ? mutedParts : 0, soloParts);
        } else {
            this.incomingPlaybackState = { isPlaying: this.incomingPlaybackState.isPlaying, currentTime: this.incomingPlaybackState.currentTime, mutedParts: soloParts ? mutedParts : 0, soloParts: soloParts };
        }
    }


    playbackEnded = () => {

        this.currentPlayerTime = -1;
        this.incomingPlaybackState.isPlaying = false;

        if (this.syncOn && !this.recap) {
            this.sendUserStatusMessage(UserMessageAction.TrackFinishedPlaying);
        }
    }

    setProjected = (value: boolean) => {
        window.localStorage.setItem("projected", JSON.stringify(value))
        this.projected = value;
        this.sendUserStatusMessage(UserMessageAction.ProjectedStateChanged);
    }

    setUseHighQuality = (value: boolean) => {
        window.localStorage.setItem("useHighQuality", JSON.stringify(value))
        this.useHighQuality = value;
        //this.setSelectedTrack(this.syncSelectedTrack);
        if (this.syncSelectedTrack?.submissionType !== TrackSubmissionType.VideoUrl) {
            this.syncSelectedTrack?.files?.forEach(file => {
                const trackFile = this.getTrackFile(file);
                if (trackFile) {
                    this.currentTrackParts.set(trackFile.partNumber!, trackFile);
                }
            });

        }
        this.incomingPlaybackState = { isPlaying: this.incomingPlaybackState.isPlaying, currentTime: this.currentPlayerTime, mutedParts: this.incomingPlaybackState.mutedParts, soloParts: this.incomingPlaybackState.soloParts };
        this.sendUserStatusMessage(UserMessageAction.StateChanged);
    }

    setSyncOn = (value: boolean) => {
        this.syncOn = value;
        this.sendUserStatusMessage(UserMessageAction.StateChanged);
    }

    //global muted (the user muted the player)
    setMuted = (value: boolean) => {
        this.muted = value;
    }

    setVolume = (value: number) => {
        this.volume = value;
    }

    setCurrentPlayerTime = (value: number) => {
        this.currentPlayerTime = Math.trunc(value);
        if (value < 0) this.incomingPlaybackState = { isPlaying: false, currentTime: -1 };
    }

    loadTrack = (selectedTrack: IPlaylistTrack) => {
        if (this.syncSelectedTrack?.id !== selectedTrack.id) {
            this.trackLoadedBy = this.userName;
            this.syncSelectedTrackId = selectedTrack.id;
            this.setSelectedTrack(selectedTrack);
        }
        //this.syncOn = false;
        //this.syncDisabled = true;
    }

    disableSync = () => {
        this.syncOn = false;
        this.syncDisabled = true;
    }

    attendeeStatsMode: number | undefined = JSON.parse(window.localStorage.getItem('as') ?? '0');

    setAttendeeStatsMode = (mode: number) => {
        this.attendeeStatsMode = mode;
        window.localStorage.setItem('as', JSON.stringify(this.attendeeStatsMode));
    }

    setVideoStreamProgress = (playedSeconds: number) => {
        this.videoStreamProgress = playedSeconds;
    }

}