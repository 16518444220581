import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import apiclient from "./photosApi";
import { IPhoto } from "features/photos/model/photo";

export class PhotosStore {
    constructor() {
        makeAutoObservable(this);
    }

    photos: IPhoto[] = [];
    photoProgress = false;
    groupId: string | undefined = undefined;

    initialize = async (groupId: string) => {
        this.groupId = groupId;
    }

    loadPhotos = async (photoType: string, belongsToId: string) => {
        if (!this.groupId) return;
        this.photoProgress = true;
        try {
            this.photos = [];
            const result = await apiclient.Photos.list(this.groupId, photoType, belongsToId);
            runInAction(() => {
                if (result) {
                    result.forEach(p => p.isActive = (p.usageCount ?? 0) > 0)
                    this.photos = result;
                }

                this.photoProgress = false;
            })
            return this.photos;
        } catch (error) {
            runInAction(() => {
                this.photoProgress = false;
            })
        }
        return null;
    }

    uploadPhoto = async (photoType: string, belongsToId: string, file: Blob): Promise<IPhoto | null> => {
        //if (!this.groupId) return null;
        this.photoProgress = true;
        try {
            const photo = await apiclient.Photos.uploadPhoto(this.groupId!, photoType, belongsToId, file);
            runInAction(() => {
                if (this.photos) {
                    this.photos.push(photo);
                }
                this.photoProgress = false;
            })
            return photo;
        } catch (error) {
            console.error(error);
            toast.error('Problem uploading photo');
            runInAction(() => {
                this.photoProgress = false;
            })
        }
        return null;
    }

    deletePhoto = async (photoType: string, belongsToId: string, photo: IPhoto) => {
        if (!this.groupId) return;
        this.photoProgress = true;
        try {
            await apiclient.Photos.deletePhoto(this.groupId, photoType, belongsToId, photo.id);
            runInAction(() => {
                if (this.photos) this.photos = this.photos.filter(a => a.id !== photo.id);
                this.photoProgress = false;
            })
        } catch (error) {
            toast.error('Problem deleting photo');
            runInAction(() => {
                this.photoProgress = false;
            })

        }
    }

}