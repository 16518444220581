import { RestClient } from "@musicproworkshop/musicproworkshop.components";
import { IPhoto } from "features/photos/model/photo";

const Photos = {
    list: (groupId: string, photoType: string, belongsToId: string): Promise<IPhoto[]> => RestClient.get(`/groups/${groupId}/${photoType}/${belongsToId}/photos`),
    uploadPhoto: (groupId: string, photoType: string, belongsToId: string, photo: Blob): Promise<IPhoto> => RestClient.postFile(`/groups/${groupId}/${photoType}/${belongsToId}/photos`, photo),
    setMainPhoto: (groupId: string, photoType: string, belongsToId: string, photoId: string) => RestClient.post(`/groups/${groupId}/${photoType}/${belongsToId}/photos/${photoId}/setMain`, {}),
    deletePhoto: (groupId: string, photoType: string, belongsToId: string, photoId: string) => RestClient.del(`/groups/${groupId}/${photoType}/${belongsToId}/photos/${photoId}`),

}


const ApiClient = { Photos };
export default ApiClient;