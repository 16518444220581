import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IConference } from 'features/conferences/model';

const Conferences = {
    list: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IConference[]>> => RestClient.search(`/groups/${groupId}/conferences`, params),
    details: (groupId: string, conferenceId: string): Promise<IConference> => RestClient.get(`/groups/${groupId}/conferences/${conferenceId}`),
    create: (groupId: string, conference: IConference) => RestClient.post<string>(`/groups/${groupId}/conferences`, conference),
    update: (groupId: string, conference: IConference) => RestClient.put(`/groups/${groupId}/conferences/${conference.id}`, conference),
    delete: (groupId: string, conferenceId: string) => RestClient.del(`/groups/${groupId}/conferences/${conferenceId}`),
}

const ApiClient = { Conferences };
export default ApiClient;