import React, { useEffect, useRef, useState } from 'react'
import NavBar from './NavBar'
import { Outlet, useMatch } from 'react-router-dom'

const NavContainer = ({ hideNavBar }: { hideNavBar?: boolean }) => {
  const isRoot = useMatch('/');
  const navBarRef = useRef<HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>(0);
  useEffect(() => {
    if (navBarRef.current) {
      var height = navBarRef.current.firstElementChild?.clientHeight ?? 0;
      setNavBarHeight(height);
      if (height > 0) {
        document.documentElement.style.setProperty('--scroll-padding', `${height+10}px`);
      }
    } else {
      setNavBarHeight(0);
    }
  }, []);

  return (
    <>
      {(!isRoot || !hideNavBar) && <div ref={navBarRef}><NavBar /></div>}
      <div style={{ marginTop: navBarHeight }}>
        <Outlet />
      </div>

    </>
  )
}

export default NavContainer
