import React from "react";
import { createRoot } from "react-dom/client";
//import 'mobx-react-lite/batchingForReactDom';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserHistory } from "history";
import NiceModal from '@ebay/nice-modal-react';
import "react-toastify/dist/ReactToastify.min.css";
import "./app/layout/reset.css";
import "./app/layout/styles.css";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";
//import ScrollToTop from "./app/layout/ScrollToTop";
import { RootStoreProvider } from "app/stores/useRootStore";

export const history = createBrowserHistory({ window });

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container!);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<App />} />
  )
);

root.render(<>
  <HelmetProvider>
    <RootStoreProvider>
      <NiceModal.Provider>
        <RouterProvider router={router} />
      </NiceModal.Provider>
    </RootStoreProvider>
  </HelmetProvider>
</>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
