import {
    IconDefinition,
    IconName,
    IconPrefix
} from "@fortawesome/fontawesome-common-types";

export const faAudius: IconDefinition = {
    prefix: "fac" as IconPrefix,
    iconName: "audius" as IconName,
    icon: [
        448,
        512,
        [],
        "e001",
        "M458.69 455 334.78 240 276.66 139.21 210.86 25.07a12.4 12.4 0 00-21.48 0L65.18 239.84 14.43 327.6a12.41 12.41 0 0010.74 18.62l95.87.06a12.43 12.43 0 0010.75-6.19l49.82-86.18 7.62-13.18a13.36 13.36 0 011-1.46 12.41 12.41 0 0120.5 1.48l51.7 89.68L272 347a13.25 13.25 0 011.22 2.91 12.41 12.41 0 01-12 15.69l-112.78-.07a12.43 12.43 0 00-10.75 6.19l-48 83a12.4 12.4 0 0010.73 18.61l99.39.07 123.93.08 124.18.08A12.4 12.4 0 00458.69 455Z"
    ]
};