import { runInAction, makeAutoObservable } from "mobx";
import { jwtDecode} from "jwt-decode";
import { IUser, IUserDetails, IUserFormValues } from "features/account/model/user";
import accountApiClient from './accountApi';

export class AccountStore {
    constructor() {
        makeAutoObservable(this);
    }

    user: IUser | null = null;
    refreshTokenTimeout: any;
    token: string | null = window.localStorage.getItem(process.env.REACT_APP_TOKENNAME ?? 'token');
    userDetails: IUserDetails | null = null;

    get isLoggedIn() { return !!this.user }

    isCurrentUser = (userName?: string) => (userName && this.user && this.user.username === userName) ? true : false;

    login = async (creds: IUserFormValues) => {
        try {
            const user = await accountApiClient.login(creds);
            runInAction(() => {
                this.user = user;
                this.setToken(user.token);
                this.startRefreshTokenTimer(user);
            });
        } catch (error) {
            //console.error('accountStore', 'login', 'error', error, 'resoonse data', error?.response?.data);
            throw error;
        }
    }

    createUser = async (values: IUserFormValues, groupId?: string | null): Promise<IUser> => {
        try {
            const user = await accountApiClient.register(values, groupId);
            return user;
        } catch (error) {
            throw error;
        }
    }

    register = async (values: IUserFormValues, groupId?: string | null) => {
        try {
            const user = await accountApiClient.register(values, groupId);
            runInAction(() => {
                this.user = user;
                this.setToken(user.token);
                this.startRefreshTokenTimer(user);
            });
            return user;
        } catch (error) {
            throw error;
        }
    }


    forgotPassword = async (email: string, groupId?: string | null) => {
        try {
            await accountApiClient.forgotPassword(email, groupId);
        } catch (error) {
            throw error;
        }
    }

    resetPassword = async (values: IUserFormValues, groupId?: string | null) => {
        try {
            const user = await accountApiClient.resetPassword(values, groupId);
            this.setToken(user.token);
        } catch (error) {
            throw error;
        }
    }

    changePassword = async (values: IUserFormValues) => {
        try {
            await accountApiClient.changePassword(values);
        } catch (error) {
            throw error;
        }
    }

    resendEmailConfirmation = async (email: string, groupId?: string | null) => {
        try {
            await accountApiClient.resendEmailConfirmation(email, groupId);
        } catch (error) {
            throw error;
        }
    }

    confirmEmail = async (token: string, email: string) => {
        try {
            await accountApiClient.verifyEmail(token, email);
        } catch (error) {
            throw error;
        }
    }

    changeEmail = async (values: IUserFormValues) => {
        try {
            const userDetails = await accountApiClient.changeEmail(values);
            runInAction(() => {
                this.userDetails = userDetails;
            })
        } catch (error) {
            throw error;
        }
    }

    getUser = async () => {
        try {
            const user = await accountApiClient.current();
            runInAction(() => {
                this.user = user;
            })
            this.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.error('getUser', 'error', error);
        }
    }

    getUserDetails = async () => {
        try {
            const userDetails = await accountApiClient.details();
            runInAction(() => {
                this.userDetails = userDetails;
            })

        } catch (error) {
            console.error('getUserDetails', 'error', error);
        }
    }

    deleteUser = async () => {
        try {
            await accountApiClient.delete();
            runInAction(async () => {
                await this.logout();
            })
        } catch (error) {
            console.error(error);
        }
    }

    logout = async () => {
        this.setToken(null);
        this.user = null;
    }

    getValidToken = async () => {
        //check current token
        //console.log('accountStore', 'getValidToken', this.token)
        if (this.token) {
            if (this.isTokenExpired(this.token)) {
                //console.log('accountStore', 'getValidToken', 'expired')
                await this.getUser();
            }
            return this.token;
        }
    }
    isTokenExpired = (token: string) => {
        const decodedToken: any = jwtDecode(token);
        return (decodedToken && Date.now() >= decodedToken.exp * 1000 - 5000);
    }

    isAdmin = (token?: string) => {
        if (!token && !this.token) return false;
        const decodedToken: any = jwtDecode((token ?? this.token!));
        return (decodedToken && decodedToken.role === 'Admin');
    }

    userAppStart = async () => {
        if (this.token) {
            if (this.isTokenExpired(this.token) || !this.user) {
                await this.getUser();
            }
        }
    }

    setToken = (token: string | null) => {
        if (token) {
            window.localStorage.setItem(process.env.REACT_APP_TOKENNAME ?? 'token', token);
        }
        else {
            window.localStorage.removeItem(process.env.REACT_APP_TOKENNAME ?? 'token');
        }
        this.token = token;
    }


    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await accountApiClient.refreshToken();

            runInAction(() => {
                this.user = user;
                this.setToken(user.token);
                this.startRefreshTokenTimer(user);
            });
        } catch (error) {
            console.error(error)

        }
    }

    setUserPhotoUrl = (photoUrl: string) => {
        this.user!.image = photoUrl;
    }
    setUserDisplayName = (displayName?: string) => {
        if (displayName) this.user!.displayName = displayName;
    }

    private startRefreshTokenTimer(user: IUser) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }
    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}