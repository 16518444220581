import React from 'react'
import { Dimmer, Loader } from '../common/Styled'

interface ILoadingProps {
    content?: string;
    active?: boolean;
    color?: 'primary' | 'secondary' | 'white';
}

const LoadingComponent = ({ content, color, active = true }: ILoadingProps) => {
    return (
        <Dimmer active={active} >
            <div className='content'>
                <Loader className={`${color}${content ? ' text' : ''}`} active={active}>
                    {content}
                </Loader>
            </div>
        </Dimmer>
    )
}

export default LoadingComponent
