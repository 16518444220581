import styled from 'styled-components'

export const MultiLineContainer = styled.div<{ color?: string, backgroundColor?: string }>`
    position: relative;
    display:grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    margin: 1rem 0;
    padding:1em 1em;
    border-radius: 0.28571429rem;
    font-size: 1rem;
    border:none;
    color: ${props => (`${props.color ? props.color : 'rgba(255,255,255,0.9)'}`)};
    background-color: ${props => (`${props.backgroundColor ? props.backgroundColor : '#0C6291'}`)};
    box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
`;

export const LineOneLeft = styled.h3`
    border:none;
    margin:0px;
    padding:0;
    line-height:1.27em;
    grid-row:1;
    @media screen and (max-width: 700px){
        grid-column:1/span 2;
    }
`;
export const LineOneRight = styled(LineOneLeft)`
    text-align:right;
    @media screen and (max-width: 700px){
        grid-row:4;
        text-align:left;
    }
`;

export const LineTwoLeft = styled.h2`
    border:none;
    margin:0px;
    padding:0;
    line-height:1.5em;
    grid-row:2;        
    @media screen and (max-width: 700px){
        grid-column:1/span 2;
    }
`;

export const LineTwoRight = styled(LineTwoLeft)`
    text-align:right;
    @media screen and (max-width: 700px){
        grid-row:5;
        text-align:left;
    }
`;

export const StatusTextRight = styled.div`
    text-align: right;
    grid-row: 3;
    grid-column: 2;
    @media screen and (max-width: 700px){
        text-align: left;
        grid-row:6;
        grid-column: 1;
    }
`;

export const StatusTextLeft = styled.div`
    grid-row: 3;
    grid-column: 1;
`;

export const ActionButtonContainer = styled.div`
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    justify-self: right;
    align-self: center;
    @media screen and (max-width: 700px){
        grid-row:7;
        grid-column: 1/span 2;
        justify-self: left;
    }

`;
