import { ILink } from "features/links/model/link";
import { GroupMemberRole, IGroupMemberManage } from "features/members/model";
import { ISessionUser } from "features/sessiondetails/model";

export interface ISpeaker extends ISessionUser {
    displayOrder: number;
    conferenceFeatured: number;
    bio?: string;
    links?: Array<ILink>
}

export interface ISpeakerFormValues extends Partial<IGroupMemberManage> {
    email: string;
    photo: string;
}

export class SpeakerFormValues implements ISpeakerFormValues {
    username = undefined;
    displayName = '';
    image = '';
    role = GroupMemberRole.Member;
    email = '';
    photo = '{}';

    constructor(init?: IGroupMemberManage | null) {
        Object.assign(this, {}, init);
        if (init?.speakerPhoto) this.photo = JSON.stringify(init?.speakerPhoto);
    }

}