import { makeAutoObservable, runInAction } from "mobx";
import { IReactable, ReactionTypes } from "features/reactions/model";
import reactionsApi from './reactionsApi';

export class ReactionsStore {
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * 
     * @param groupId the group id the item belongs to
     * @param reactable the reactable object itself
     * @param reactableType the type of object the user is reacting to ('question', 'comment', 'post')
     * @param reactableId the id of the object the user is reacting to
     * @param reactionType the type of reaction (Vote, Like, etc.)
     */
    updateReaction = async (groupId: string, reactable: IReactable, reactableType: string, reactableId: string, reactionType: ReactionTypes): Promise<IReactable | null> => {
        try {
            if (reactable.userReaction) {
                await reactionsApi.delete(groupId, reactableType, reactableId, ReactionTypes[reactionType]);
                runInAction(() => {
                    switch (reactionType) {
                        case ReactionTypes.Vote:
                            reactable!.votes = (reactable!.votes ?? 0) > 0 ? reactable!.votes! - 1 : 0;
                            break;
                        case ReactionTypes.Like:
                            reactable!.likes = (reactable!.likes ?? 0) > 0 ? reactable!.likes! - 1 : 0;
                            break;
                    }
                    reactable!.userReaction = undefined;
                    return reactable;
                })

            } else {
                await reactionsApi.add(groupId, 'question', reactableId, ReactionTypes[reactionType]);
                runInAction(() => {
                    switch (reactionType) {
                        case ReactionTypes.Vote:
                            reactable!.votes = (reactable!.votes ?? 0) + 1;
                            break;
                        case ReactionTypes.Like:
                            reactable!.likes = (reactable!.likes ?? 0) + 1;
                            break;
                    }
                    reactable!.userReaction = reactionType;
                    return reactable;
                })
            }
        } catch (error) {

        }
        return null;
    }
}