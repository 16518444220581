import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { SubHeader, TextInput } from '@musicproworkshop/musicproworkshop.components';
import { useAccountStore } from 'app/stores/useRootStore';
import { ModalActionContainer, ModalContentContainer, ModalHeaderContainer } from 'app/common/Styled';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { FormSubmitButton } from 'app/common/controls';
import { FormElement } from 'app/common/formInputs';
import { useCurrentGroup } from 'features/groupdetails/context';
import { IUserFormValues, UserFormValues } from 'features/account/model/user';
import ValidationErrors from "features/account/views/ValidationError";

//querystring should have email and token, show a form for the new password and call api to set it
const ResetPassword = ({ closeModal }: { closeModal: () => void }) => {
  const { resetPassword } = useAccountStore();

  const { currentGroupId } = useCurrentGroup();


  const [formValues] = useState<IUserFormValues>(new UserFormValues());
  //const { handleSubmit, control, formState } = useForm<IUserFormValues>({ mode: 'onChange', reValidateMode: 'onChange' });

  let navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const validationSchema = Yup.object({
    password: Yup.string().required('Password is Required'),
  })


  const onFormSubmit = async (data: IUserFormValues, formikHelpers: FormikHelpers<IUserFormValues>): Promise<void> => {
    data.email = searchParams.get('email')!;
    data.token = searchParams.get('token')!;

    resetPassword(data, currentGroupId)
      .then(() => {
        closeModal();
        navigate('/', { replace: true });
      })
      .catch((error: any) => {
        formikHelpers.setErrors({ error })
      });

  }

  return (
    <Formik validationSchema={validationSchema} enableReinitialize initialValues={formValues} onSubmit={onFormSubmit} validateOnChange validateOnBlur>
      {({ handleSubmit, errors, dirty, isValid, isSubmitting }) => (
        <FormElement as={Form} onSubmit={handleSubmit} style={{ padding: 0 }}>
          <ModalHeaderContainer>
            <SubHeader headerColor='primary' upper>Create New Password</SubHeader>
          </ModalHeaderContainer>

          <ModalContentContainer>
            <TextInput name='password' placeholder='Password' type='password' autoComplete='password' autoCorrect='false' icon='lock' iconPosition='left' />

            <ErrorMessage name='error' render={() => <ValidationErrors errors={errors.error} />} />
          </ModalContentContainer>

          <ModalActionContainer>
            <FormSubmitButton text='Submit' loading={isSubmitting} fluid disabled={!dirty || !isValid} />
          </ModalActionContainer>

        </FormElement>
      )}
    </Formik>
  )
}

export default ResetPassword

export const ResetPasswordModal = NiceModal.create(({ size }: { size?: ModalSize }) => {
  const modal = useModal();
  return <NiceModalContainer modalIsOpen={modal.visible} body={<ResetPassword closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} />
});