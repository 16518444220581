import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IGroup, IGroupListItem, IGroupSettings } from 'features/groupdetails/model';

const GroupsApi = {
    list: (params: URLSearchParams): Promise<PaginatedResult<IGroupListItem[]>> => RestClient.search('/groups', params),
    byDomain: (domain: string): Promise<IGroupSettings> => RestClient.get(`/groups/domain/${domain}`),
    settings: (groupId: string): Promise<IGroupSettings> => RestClient.get(`/groups/${groupId}/settings`),
    details: (groupId: string): Promise<IGroup> => RestClient.get(`/groups/${groupId}`),
}

export default GroupsApi;