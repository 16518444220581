import { ITrackFileBase } from "features/tracks/model";

export enum PlayerFileLoadingState {
    Unknown = 0,
    Loading = 1,
    Loaded = 2

}
export enum PlayerFilePlaybackState {
    Unknown = 0,
    Seeking = 1,
    Ready = 2,
    InteractionRequired = 3,
    Playing = 4,
    Paused = 5,
    FinishedPlaying = 6
}

export interface IPlayerFileInfo extends ITrackFileBase {
    uriWithQuality?: string;
    loadingState?: PlayerFileLoadingState;
    playbackState?: PlayerFilePlaybackState;
    loadStarted?: Date;
    timeToLoad: number;
}