import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'

interface IProps {
    name: string;
    onValueChanged: (value: any) => void;
}
export const WatchForm = ({ name, onValueChanged }: IProps) => {
    const context = useFormikContext();
    let props = context.getFieldProps(name);

    useEffect(() => {
        if (onValueChanged) onValueChanged(props.value);
    }, [onValueChanged, props.value])

    return (<></>)
}

export default WatchForm
