import { IUser as IUserBase } from "@musicproworkshop/musicproworkshop.components";
import { VisibilityMode } from "app/common/visibility";

export interface IUser extends IUserBase {
    displayName: string;
    image?: string;
    emailConfirmed: boolean;
}

export interface IUserGroup {
    name: string;
    groupUrl: string;
}

export interface IUserDetails extends IUser {
    email: string;
    passwordLastChanged: Date;
    groups: IUserGroup[];
}

export interface IUserFormValues {
    email?: string;
    password?: string;
    displayName?: string;
    token?: string;
    currentPassword?: string;
    sendEmail?: boolean;
    profileVisibility?: VisibilityMode,
    error: any
}
export class UserFormValues implements IUserFormValues {
    email = '';
    error = null;
}