import styled from "styled-components";
import { Loader } from "./Loader";

export const Dimmer = styled.div<{ active?: boolean }>`
    display: ${props => props.active ? 'block' : 'none'};
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 1em;
    background: rgba(0, 0, 0, 0.75);
    opacity: ${props => props.active ? 1 : 0};
    line-height: 1;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    will-change: opacity;
    z-index: 1000;

    &.blurring * {
        filter: none;
    }

    .content {
        user-select: text;
    }

    ${Loader} {
        display: block;
    }

    ${Loader}:not(.primary):not(.secondary):after {
        border-top-color:#FFF;
    }

`