import React, { useState } from 'react'
import styled from 'styled-components';
import { Icon, Image, useClickOutside } from '@musicproworkshop/musicproworkshop.components';

interface IDropDownProps {
    text?: React.ReactNode;
    image?: string;
    imageAlt?: string;
    hasNew?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}


const DropDown = styled.div.attrs({ role: "listbox" })`
	cursor: pointer;
	position: relative;
	display: inline-block;
	outline: none;
	text-align: left;
	transition: box-shadow 0.1s ease, width 0.1s ease;
	user-select: none;
	font-size: 1rem;
    .text {
        display: inline-block;
        transition: none;
        font-weight: bold;
        font-size: 1.1em;
    }

    .new:after {
        content: ' *';
        color: lightgreen;
    }

    .content {
        cursor: auto;
        position: absolute;
        display: none;
        outline: none;
        top: 100%;
        bottom: auto;
        left: 0;
        right: auto;
        min-width: max-content;
        margin: 1em 0 0;
        padding: 0 0;
        background: #fff;
        font-size: 1em;
        text-shadow: none;
        text-align: left;
        box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571rem;
        transition: opacity 0.1s ease;
        z-index:11;
        animation-iteration-count: 1;
        animation-duration: 300ms;
        animation-timing-function: ease;
        animation-fill-mode: both;
        will-change: transform, opacity;        
        &.visible {
            display: block !important;
            visibility: visible !important;
        }
    }
    .description {
        color: var(--segmentSubtleTextColor);
        text-align:right;
    }
    svg ~ .text, 
    i ~ .text {
        font-weight: normal;;
    }
    .menuitem {
        display: grid !important;
        grid-template-columns: auto 1fr 20px;
        column-gap:12px;
        padding-bottom:0.15em;
        
    }
    .menuitem:last-child {
        padding-bottom:1em;
    }
`;

export const DropDownMenu = ({ text, image, imageAlt, hasNew, children, style }: IDropDownProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const ref = useClickOutside(() => setDropdownOpen(false));
    return (
        <DropDown style={style} title='Account Menu'>
            <div role="alert" className={`${hasNew && 'new'} text`} onClick={() => setDropdownOpen(!dropdownOpen)} ref={dropdownOpen ? ref : undefined}>
                {image && <Image avatar spaced='right' src={image} alt={imageAlt}></Image>}
                {text}</div>

            <Icon icon={['fas', (dropdownOpen ? 'caret-down' : 'caret-right')]} style={{ width: '9px' }} />

            <div className={`content ${dropdownOpen && 'visible'}`} style={style}>
                {children}
            </div>

        </DropDown>
    )
}

export default DropDownMenu
