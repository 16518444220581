import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ClearGroupId = () => {
    //Get the url, get rid of groups/groupId, redirect to the new custom domain url
    let { groupId } = useParams();
    let navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        if (groupId) {
            //console.log('pathname', pathname)
            let newPath = pathname.replace(`/groups/${groupId}`, '');
            //console.log('newPath', newPath)

            navigate(newPath);
        }
    }, [groupId, navigate, pathname])
    return (<></>)
}

export default ClearGroupId
