import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { ICategory } from 'features/sessionlist/model';
import { IGroupSession } from 'features/sessiondetails/model';

const SessionListApi = {
    list: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupSession[]>> => RestClient.search(`/groups/${groupId}/sessions`, params),
    submittedTracks: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupSession[]>> => RestClient.search(`/groups/${groupId}/sessions/tracks`, params),
    sessiondates: (groupId: string, params: URLSearchParams): Promise<Date[]> => RestClient.search(`/groups/${groupId}/sessionDates`, params),
    sessionCategories: (groupId: string, params: URLSearchParams): Promise<ICategory[]> => RestClient.search(`/groups/${groupId}/sessionCategories`, params),
};
export default SessionListApi;