import { UserMessageType } from "features/messaging/messages/model";

export interface IDiscordSettings {
    discordGuildName: string;
    discordGuildId?: number;
    connectionVerified: boolean;
    defaultChannelCategory: string;
}

export enum DiscordRoleType {
    Organizers = 1,
    MusicSubmitter = 2,
    AttendedLive = 3,
    ActivityLevel = 4,
    GroupMembers = 5
}

export interface IDiscordRole {
    roleType: DiscordRoleType;
    roleName: string;
    enabled: boolean;
    hoisted: boolean;
    mentionable: boolean;
    color: string;
    channelCategory: string;
    channelName: string;
    //channelId: number;
}

export interface IDiscordMessagesChannel {
    channelId: string;
    channelName: string;
    channelCategory: string;
    messageTypes: UserMessageType
}

export interface IDiscordSettingsWithRoles extends IDiscordSettings {
    roles: IDiscordRole[];
    messageChannels: IDiscordMessagesChannel[];
}

export interface IDiscordSettingsFormValues extends Partial<IDiscordSettingsWithRoles> {

}

export class DiscordSettingsFormValues implements IDiscordSettingsFormValues {
    discordGuildId = undefined;
    constructor(init?: IDiscordSettings | IDiscordSettingsWithRoles | null) {
        Object.assign(this, init);
        if (init?.discordGuildId === 0) this.discordGuildId = undefined;
    }
}