import { differenceInSeconds } from "date-fns";
import { formatTime } from "app/common/utils";
import { ITrackSubmission, TrackSubmissionType } from "features/tracks/model";
import { SessionStatus, SessionFeatures, SessionVisibility, IAttendee, ISpeaker, ChangedFields } from ".";

export interface IGroupSession {
    id: string;
    conferenceId: string;
    title: string;
    description: string;
    category: string;
    sessionFeatures: SessionFeatures;
    date: Date;
    duration: number;
    endDate?: Date;
    onlineUrl: string;
    onlineNotes: string;
    inPersonVenueName: string;
    inPersonAddress: string;
    inPersonNotes: string;
    publishOn?: Date;
    publishDaysBeforeStartDate: number;
    sessionListVisibility: SessionVisibility;
    submissionDeadline?: Date;
    submissionHoursBeforeStart: number;
    maxTracksPerUser?: number;
    maxTracks?: number;
    requireConfirmedEmailToSubmit?: boolean;
    requireDiscordToSubmit?: boolean;
    inviteOnly?: boolean;
    allowedSubmissionTypes?: TrackSubmissionType;
    slotsLeft: number;
    miniPlayerLink: string;
    videoStreamLink: string;
    videoStreamStartTime?: Date;
    questionsSubHeader: string;
    showQuestionsBeforeSession: boolean;
    totalSessionSubmittedTracks: number;
    hasTracks: boolean;
    submittedTracks: ITrackSubmission[];
    firstTrack: ITrackSubmission;
    hasInvites?: boolean;
    location: string;
    autoStartSession: boolean;
    autoEndSession: boolean;
    sessionHosts: string;
    recapLink: string;
    recapContent: string;
    recapVisibility: SessionVisibility;
    attendedSession: boolean;
    isLeader: boolean;
    canManage: boolean;
    canModerate: boolean;
    isHost: boolean;
    isMember: boolean;
    showRecap: boolean;
    startsIn: string;
    group: string;
    usesLivePage: boolean;
    published: boolean;
    canSendPublishMessage: boolean;
    canSendRecapMessage: boolean;
    canSendDeleteMessage: boolean;
    shouldSendChangesMessage: boolean;
    lastChanges: ChangedFields;
    status: SessionStatus;
    deleted: boolean;
    speakers: ISpeaker[]
    attendees: IAttendee[];
    tab: string;

    hasDetails?: boolean;
    loadedOn?: Date;
}


export interface IGroupSessionFormValues extends Partial<IGroupSession> {
    sessionSpeakers: string;
    videoStreamFirstTrack?: string | number;
}

export class GroupSessionFormValues implements IGroupSessionFormValues {
    id = undefined;
    conferenceId = undefined;
    title = '';
    description = '';
    category = '';
    sessionFeatures = 0;
    date?: Date = undefined;
    duration = 120; //default two hour sessions
    endDate?: Date = undefined;
    onlineUrl = '';
    onlineNotes = '';
    //inPersonAddress = '';
    inPersonNotes = '';
    publishDaysBeforeStartDate = 15;
    publishOn?: Date = undefined;
    sessionListVisibility = SessionVisibility.Public;
    submissionHoursBeforeStart = -101;
    submissionDeadline?: Date = undefined;
    maxTracksPerUser?: number = undefined;
    maxTracks?: number = undefined;
    requireConfirmedEmailToSubmit?: boolean = undefined;
    requireDiscordToSubmit?: boolean = undefined;
    inviteOnly?: boolean = undefined;
    allowedSubmissionTypes?: TrackSubmissionType = undefined;
    miniPlayerLink = '';
    videoStreamLink = '';
    videoStreamStartTime?: Date = undefined;
    videoStreamFirstTrack?: string | number = '0:00';
    questionsSubHeader = '';
    showQuestionsBeforeSession = true;
    autoStartSession = true;
    autoEndSession = true;
    recapLink = '';
    recapVisibility = SessionVisibility.AllMembers;
    recapContent = '';
    sessionSpeakers = '';
    speakers = [];
    sessionHosts = 'role-organizers';

    constructor(init?: IGroupSession | null, copy?: boolean) {
        Object.assign(this, {}, init);
        this.sessionSpeakers = (init?.speakers?.slice().sort((a, b) => a.displayOrder - b.displayOrder).map(({ username }) => username) ?? []).join();
        if (init?.status === SessionStatus.Ended && !copy) {
            if (!init?.videoStreamStartTime) {
                this.videoStreamStartTime = init?.date;
            } else if (init.firstTrack) {
                this.videoStreamFirstTrack = formatTime(differenceInSeconds(init.firstTrack.playbackStarted, init.videoStreamStartTime));
            } else {
                //no firsttrack, reset value
                this.videoStreamFirstTrack = '';
            }
        }
        if (copy) {
            this.recapLink = '';
        }
    }
}

