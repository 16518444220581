import { makeAutoObservable, runInAction } from "mobx";
import { parseDate } from "app/common/utils";
import { IConference } from "features/conferences/model";
import { IGroupSession } from "features/sessiondetails/model";
import apiclient from "./conferencesApi";


export class ConferenceStore {
    constructor() {
        makeAutoObservable(this);
    }

    conference: IConference | null = null;
    loadingConference = false;
    groupId: string = '';

    initialize = (groupId: string) => {
        this.groupId = groupId;
    }

    loadConference = async (conferenceId: string) => {
        if (!this.groupId) return;
        if (this.conference?.id === conferenceId) return this.conference;
        this.loadingConference = true;
        try {
            let result = await apiclient.Conferences.details(this.groupId, conferenceId);
            runInAction(() => {
                if (result) {
                    this.setConferenceProps(result);
                    this.conference = result;
                }
                this.loadingConference = false;
            })
            return result;
        } catch (error: any) {
            runInAction(() => {
                this.loadingConference = false;
            })
            console.error('load conference', 'error', error, 'status', error?.response?.status);
        }
        return null;
    }

    clearConference = () => {
        this.conference = null;
    }

    private setConferenceProps = (conference: IConference) => {
        conference.date = parseDate(conference.date)!;
        conference.endDate = parseDate(conference.endDate);
        conference.publishOn = parseDate(conference.publishOn);
        let speakers = conference.sessions?.flatMap(sessions => sessions.speakers.map(speaker => speaker));
        //console.group('setConferenceProps');
        //console.log('speakers', speakers)
        if (speakers && speakers.length > 0) {
            //let uniqueSpeakers = Array.from(new Set<ISpeaker>(speakers));
            let uniqueSpeakers = speakers.filter((speaker, index) => {
                return speakers?.findIndex((s) => s.username === speaker.username) === index;
            });
            //console.log('uniqueSpeakers', uniqueSpeakers)
            conference.speakers = uniqueSpeakers.sort((a, b) => a.conferenceFeatured - b.conferenceFeatured);
        } else {
            conference.speakers = undefined;
        }
        //console.groupEnd();
        if (conference.sessions) {
            conference.sessions.forEach((session) => this.setSessionProps(session));
            conference.sessions.sort((a, b) => a.date.getTime() - b.date.getTime());
        }
        //if (conference.speakers) conference.speakers = [...new Set(conference.speakers)]
        //console.log('conference', conference);

    }
    setSessionProps = (session: IGroupSession) => {
        session.date = parseDate(session.date)!;
        session.endDate = parseDate(session.endDate);
    }

}

