import { useLocalStorage, useMedia } from "@musicproworkshop/musicproworkshop.components";
import { DefaultDarkTheme, DefaultLightTheme, IGroupTheme } from "features/groupdetails/model";
import { useCurrentGroup } from "features/groupdetails/context";
import { useLayoutEffect, useState } from "react";
import { toJS } from "mobx";

export function useGroupTheme() {

    // Use our useLocalStorage hook to persist state through a page refresh.
    // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
    const [enabledState, setEnabledState] = useLocalStorage<boolean>(
        "dark",
        false
    );
    // See if user has set a browser or OS preference for dark mode.
    // The usePrefersDarkMode hook composes a useMedia hook (see code below).
    const prefersDarkMode = usePrefersDarkMode();
    // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
    // This allows user to override OS level setting on our website.
    const darkThemeEnabled = enabledState ?? prefersDarkMode;

    // Fire off effect that add/removes dark mode class
    useLayoutEffect(
        () => {
            const className = "dark-mode";
            const element = window.document.body;
            if (darkThemeEnabled) {
                element.classList.add(className);
            } else {
                element.classList.remove(className);
            }
        },
        [darkThemeEnabled] // Only re-call effect when value changes
    );


    //const [darkThemeEnabled, setDarkThemeEnabled] = useDarkMode();
    const { groupSettings } = useCurrentGroup();
    const [darkTheme, setDarkTheme] = useState<IGroupTheme>(new DefaultDarkTheme());
    const [lightTheme, setLightTheme] = useState<IGroupTheme>(new DefaultLightTheme());

    const [groupTheme, setGroupTheme] = useState<IGroupTheme>(new DefaultDarkTheme());

    useLayoutEffect(() => {
        //console.log('useGroupTheme - set group themes')
        const dark = groupSettings?.groupThemes.find(gt => gt.themeName.toLowerCase() === 'dark');
        if (dark) setDarkTheme(toJS(dark));
        const light = groupSettings?.groupThemes.find(gt => gt.themeName.toLowerCase() === 'light');
        if (light) setLightTheme(toJS(light));
    }, [groupSettings?.groupThemes]);

    useLayoutEffect(() => {
        //console.log('useGroupTheme - setGroupTheme to', darkThemeEnabled)
        setGroupTheme(darkThemeEnabled ? darkTheme : lightTheme);
    }, [darkTheme, darkThemeEnabled, lightTheme]);

    useLayoutEffect(() => {
        //console.log('useGroupTheme - set vars - groupTheme', groupTheme);
        for (const key in groupTheme) {
            //console.log('useGroupTheme', key, groupTheme[key]);
            document.documentElement.style.setProperty(`--${key}`, groupTheme[key]);
        }
    }, [groupTheme]);

    const toggleDarkTheme = () => {
        setEnabledState(!darkThemeEnabled);
    }

    return { groupTheme, darkThemeEnabled, toggleDarkTheme } as const;
}
// Compose our useMedia hook to detect dark mode preference.
// The API for useMedia looks a bit weird, but that's because ...
// ... it was designed to support multiple media queries and return values.
// Thanks to hook composition we can hide away that extra complexity!
// Read the recipe for useMedia to learn more: usehooks.com/useMedia
function usePrefersDarkMode() {
    return useMedia<boolean>(["(prefers-color-scheme: dark)"], [true], false);
}