import styled from "styled-components";

export const Badge = styled.div`
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0 0.1428em;
    background-color: #E8E8E8;
    background-image: none;
    text-transform: none;
    font-size: 0.85714em;
    font-weight: bold;
    border: 0 solid transparent;
    border-radius: 0.27rem;
    transition: background 0.1s ease;

    &:last-child {
        margin-right: 0;
    }
    &.top.attached {
        width: 100%;
        position: absolute;
        margin:0;
        top:0;
        left:0;
        padding:0.75em 1em;
        border-radius: 0.2142rem 0.2142rem 0 0;
    }
    &.top.right.attached {
        width:auto;
        left:auto;
        right:0;
        border-radius: 0 0.2142rem 0 0.27rem;
    }
    &.large {
        font-size: 1rem;
    }
    &.primary {
        background-color:var(--primaryColor);
        border-color: var(--primaryColor);
        color: #FFF;
    }
    &.secondary {
        background-color: var(--secondaryColor);
        border-color: var(--secondaryColor);
        color: #FFF;
    }

`;