import React from 'react'
import styled from "styled-components";

interface IProps {
    name:string;
    options: { name: string; label: string; }[];
    label?: string;
    value?: string;
    onChange?: (newValue: string) => void;
}

const RadioGroupContainer = styled.div`
    &.horizontal {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        div:first-child {
            margin-left:10px;
        }

        div {
            margin-right:25px;
        }
    }
`

const RadioOption = styled.div`
    position: relative;
    display: inline-block;
    backface-visibility: hidden;
    outline: none;
    vertical-align: baseline;
    font-style: normal;
    font-size: 1em;
    line-height: 17px;
    min-width: 17px;
    min-height: 15px;
    input {
        z-index: -1;
        opacity: 0 !important;
        position: absolute;
    }
    label {
        cursor: pointer;
        position: relative;
        display: block;
        padding-left: 1.51em;
        outline: none;
        font-size: 1em;
        color: var(--segmentTextColor);
        transition: color 0.1s ease;
        user-select: none;
        &:before {
            position: absolute;
            content: '';
            top: 1px;
            left: 0;
            width: 15px;
            height: 15px;
            background:#FFFFFF;
            border: 1px solid #D4D4D5;
            border-radius: 500rem;
            transform: none;
            transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
        }
        &:after {
            position: absolute;
            border: none;
            content: '' !important;
            line-height: 15px;
            top: 1px;
            left: 0;
            width: 15px;
            text-align: center;
            opacity: 0;
            color: rgba(0, 0, 0, 0.87);            
            height: 15px;
            border-radius: 500rem;
            transform: scale(0.46666667);
            background-color: rgba(0, 0, 0, 0.87);            
            transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
        }
    }
    input:checked ~ label {
        &:before {
            border-color: rgba(34,36,38,0.35);
        }
        &:after {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.95);
            color: rgba(0, 0, 0, 0.95);
        }
    }
`

export const RadioGroup = ({ name, options, label, value, onChange }: IProps) => {
    return (
        <RadioGroupContainer className='horizontal'>
            {label}
            {options.map((o, index) => <RadioOption key={index} onClick={() => onChange && onChange(o.name)} >
                <input name={name} type='radio' readOnly tabIndex={0} value={o.name} checked={value !== undefined && o.name === value} />
                <label>{o.label}</label>
            </RadioOption>)}
        </RadioGroupContainer>
    )
}

export default RadioGroup
