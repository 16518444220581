import React, { useState } from "react";
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { FieldError, SubHeader, TextInput } from "@musicproworkshop/musicproworkshop.components";
import { useAccountStore, useRootStore } from "app/stores/useRootStore";
import { ModalActionContainer, ModalContentContainer, ModalHeaderContainer } from 'app/common/Styled';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { FormSubmitButton, IconButton, InlineMessage, TextButton } from "app/common/controls";
import { FormElement } from "app/common/formInputs";
import { useCurrentGroup } from "features/groupdetails/context";

const LoginForm = ({ onLoggedIn, closeModal }: { onLoggedIn?: () => void, closeModal: () => void }) => {
  const { login, forgotPassword } = useAccountStore();

  const rootStore = useRootStore();
  const { resetAfterUserChange } = rootStore;

  const { currentGroupId } = useCurrentGroup();

  const [forgotPasswordVisibile, setForgotPasswordVisibile] = useState(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: forgotPasswordVisibile ? Yup.mixed().nullable().optional() : Yup.string().required('Password is Required')
  })

  const onFormSubmit = async (data: any, formikHelpers: FormikHelpers<{ email: string; password: string; error: null; }>) => {
    if (forgotPasswordVisibile) {
      forgotPassword(data.email, currentGroupId)
        .then(() => {
          setForgotPasswordMessage('If your email exists in our system, you will receive a password reset link in your email shortly. Make sure to check your spam folder.');
        })
        .catch(error => {
          formikHelpers.setErrors({ error: error.response?.data });
        });

    } else {
      try {
        await login(data);
        closeModal();
        resetAfterUserChange(currentGroupId);
        //navigate(navigateTo ?? location.pathname, { replace: true });
        if (onLoggedIn) onLoggedIn();
      } catch (error) {
        formikHelpers.setErrors({ error: 'Invalid Login' });
      };
    }
  }


  return (
    <Formik validationSchema={validationSchema} initialValues={{ email: '', password: '', error: null }} onSubmit={onFormSubmit}>
      {({ handleSubmit, dirty, isValid, isSubmitting, errors }) => (
        <FormElement as={Form} onSubmit={handleSubmit} style={{ padding: 0 }}>
          <ModalHeaderContainer>
            <SubHeader headerColor='primary' upper>{forgotPasswordVisibile ? "Password Reset" : "Please Login"}</SubHeader>
          </ModalHeaderContainer>

          <ModalContentContainer>

            <TextInput name='email' placeholder='Email' type='email' autoComplete='login' autoCorrect='false' icon='envelope' iconPosition='left' showLiveFeedback />

            {!forgotPasswordVisibile &&
              <TextInput name='password' placeholder='Password' type='password' autoComplete='password' autoCorrect='false' icon='lock' iconPosition='left' showLiveFeedback />
            }

            {forgotPasswordVisibile && forgotPasswordMessage && <InlineMessage content={forgotPasswordMessage} header='Email Sent' />}
            {!forgotPasswordVisibile &&
              <div style={{ width: '100%', textAlign: 'center' }}><TextButton text='Forgot Password' tertiary buttonColor='grey' onClick={() => { setForgotPasswordVisibile(true) }} /></div>

            }

            <ErrorMessage
              name='error' render={() =>
                <FieldError style={{ marginBottom: 10 }}>{errors.error}</FieldError>}
            />
          </ModalContentContainer>
          <ModalActionContainer>
            {forgotPasswordVisibile && forgotPasswordMessage ? <IconButton iconName='check' text='Close' buttonColor='secondary' onClick={closeModal} />
              :
              <FormSubmitButton text={forgotPasswordVisibile ? "Submit" : "Login"} buttonColor='primary' fluid loading={isSubmitting} disabled={!dirty || !isValid} />
            }
          </ModalActionContainer>

        </FormElement>
      )}
    </Formik>


  );
};

export default LoginForm;

export const LoginModal = NiceModal.create(({ onLoggedIn, size }: { onLoggedIn?: () => void, size: ModalSize }) => {
  const modal = useModal();

  return <NiceModalContainer modalIsOpen={modal.visible} body={<LoginForm onLoggedIn={onLoggedIn} closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} />
});