export interface IGroupTheme {
    themeName: string;
    siteBackgroundColor: string;
    siteTextColor: string;
    primaryColor: string;
    secondaryColor: string;
    segmentBackgroundColor: string;
    segmentBackgroundLighterColor: string;
    segmentTextColor: string;
    segmentSubtleTextColor: string;
    playerContainerColor: string;
    borderColor: string;
    tableStripeColor: string;
}

export class DefaultDarkTheme implements IGroupTheme {
    themeName = 'dark';
    siteBackgroundColor = '#123';
    siteTextColor = '#fff';
    primaryColor = 'rgb(200,100,50)';
    secondaryColor = 'rgb(50, 100, 200)';
    segmentBackgroundColor = '#0C6291';
    segmentBackgroundLighterColor = '#1A94D6';
    segmentTextColor = '#fff';
    segmentSubtleTextColor = 'rgba(255,255,255,0.6)';
    playerContainerColor = 'rgb(208,191,183)';
    borderColor = 'rgba(34, 36, 38, 0.15)'; //change this
    tableStripeColor = 'rgba(0, 0, 50, 0.02)'; //change this
}


export class DefaultLightTheme implements IGroupTheme {
    themeName = 'light';
    siteBackgroundColor = 'rgb(227, 215, 208)';
    siteTextColor = '#000';
    primaryColor = 'rgb(200,100,50)';
    secondaryColor = 'rgb(50, 100, 200)';
    segmentBackgroundColor = '#fff';
    segmentBackgroundLighterColor = '#aaa';
    segmentTextColor = '#000';
    segmentSubtleTextColor = 'rgba(0,0,0,0.6)';
    playerContainerColor = 'rgb(208,191,183)';
    borderColor = 'rgba(34, 36, 38, 0.15)';
    tableStripeColor = 'rgba(0, 0, 50, 0.02)';
}

export interface IGroupThemeFormValues {
    lightTheme: IGroupTheme;
    darkTheme: IGroupTheme;
}

export class GroupThemeFormValues implements IGroupThemeFormValues {
    lightTheme: IGroupTheme = new DefaultLightTheme();
    darkTheme: IGroupTheme = new DefaultDarkTheme();

    constructor(init?: IGroupThemeFormValues) {
        Object.assign(this, init);
    }
}
