import React from 'react'
import { SmartButtonProps } from '@musicproworkshop/musicproworkshop.components';
import { ButtonBase } from '.'

export const FormSubmitButton = ({ type = 'submit', buttonColor = 'positive', ...props }: SmartButtonProps) => {
    return (
        <ButtonBase type={type} buttonColor={buttonColor} {...props} />
    )
}

export default FormSubmitButton
