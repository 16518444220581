import React, { useEffect } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useClickOutside } from '@musicproworkshop/musicproworkshop.components';
import ModalMessage from './ModalMessage';
import { ModalContent, ModalContentContainer, ModalOverlay, ModalStyled } from './styled';

export type ModalSize = 'mini' | 'tiny' | 'small' | 'large';

interface IProps {
    modalIsOpen: boolean;
    body: any;
    size?: ModalSize;
    onlyCloseOnButton?: boolean;
    blurring?: boolean;
}

const NiceModalContainer = ({ modalIsOpen, body, size, onlyCloseOnButton, blurring }: IProps) => {
    const modal = useModal();
    const ref = useClickOutside(() => modal.hide());

    useEffect(() => {
        document.body.style.overflow = modalIsOpen ? 'hidden' : 'auto';
    }, [modalIsOpen])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") modal.hide();
        };
        if (modalIsOpen && !onlyCloseOnButton) {
            document.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [onlyCloseOnButton, modalIsOpen, modal])

    if (modalIsOpen && body) {
        if (blurring) document.body.classList.add('blurring');
        return <ModalStyled className="modal">
            <ModalOverlay active={true} />
            <ModalContentContainer className={size}>
                <ModalContent aria-modal="true" className={size} ref={(modalIsOpen && !onlyCloseOnButton) ? ref : undefined}>
                    {('content' in body) ? <ModalMessage header={body.header} content={body.content} button={body.button} /> : <div className='body'>{body}</div>}
                </ModalContent>
            </ModalContentContainer>
        </ModalStyled>
    } else {
        document.body.classList.remove('blurring');
        return <></>
    }
}

export default NiceModalContainer

export const BasicModal = NiceModal.create(({ header, content, button, size, blurring, onlyCloseOnButton }: { header?: React.ReactNode, content?: React.ReactNode, button?: React.ReactNode, size: ModalSize, blurring?: boolean, onlyCloseOnButton?: boolean }) => {
    const modal = useModal();
    return <NiceModalContainer modalIsOpen={modal.visible} body={<ModalMessage header={header} content={content} button={button} />} size={size} blurring={blurring} onlyCloseOnButton={onlyCloseOnButton} />
});