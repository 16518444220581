import { TrackSubmissionType } from "features/tracks/model";

export interface ISubmissionInvite {
    id?: string;
    inviteUrl?: string;
    allowed?: string;
    expiresOn?: Date;
    submissionType: TrackSubmissionType;

}

export class SubmissionInviteFormValues implements Partial<ISubmissionInvite> {
    submissionType?: TrackSubmissionType | undefined = TrackSubmissionType.MusicUpload;
    constructor(init?: ISubmissionInvite) {
        Object.assign(this, init);
    }
}