import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IGroupContentItem } from 'features/contentitems/model';

const GroupContentItemsApi = {
    list: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupContentItem[]>> => RestClient.search(`/groups/${groupId}/contentItems`, params),
    details: (groupId: string, contentItemId: string): Promise<IGroupContentItem> => RestClient.get(`/groups/${groupId}/contentItems/${contentItemId}`),
    create: (groupId: string, formData: FormData): Promise<IGroupContentItem> => RestClient.postForm(`/groups/${groupId}/contentItems`, formData),
    update: (groupId: string, contentItemId: string, formData: FormData): Promise<IGroupContentItem> => RestClient.putForm(`/groups/${groupId}/contentItems/${contentItemId}`, formData),
    updateSortOrder: (groupId: string, contentItemId: string, moveBy: number) => RestClient.put(`/groups/${groupId}/contentItems/${contentItemId}/sort/${moveBy}`, {}),
    delete: (groupId: string, contentItemId: string) => RestClient.del(`/groups/${groupId}/contentItems/${contentItemId}`),
    undelete: (groupId: string, contentItemId: string) => RestClient.post(`/groups/${groupId}/contentItems/${contentItemId}`, {}),
}
export default GroupContentItemsApi;