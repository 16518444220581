//return currentGroupId, groupItem and loadGroup if necessary
import { useState } from "react";
import { useLocalStorage } from "@musicproworkshop/musicproworkshop.components";
import { useGroupMemberSettingsStore, useGroupStore } from "app/stores/useRootStore";
import { useSessionStorage } from "app/common/hooks/useSessionStorage";
import { groupHasFeature } from "features/groupdetails/context";

export function useCurrentGroup() {
    const [brandGroupId, setBrandGroupId] = useLocalStorage<string | null>(
        "groupId",
        null
    );

    const [currentGroupId, setCurrentGroupId] = useSessionStorage<string | null>(
        "groupId",
        brandGroupId ?? sessionStorage.getItem("groupId"));

    const [branded, setBranded] = useState<boolean>(brandGroupId !== null);

    const { groupSettings, resetGroup } = useGroupStore();

    const {groupMemberSettings, resetMember} = useGroupMemberSettingsStore();

    const setGroupId = (id: string | undefined, isBranded: boolean) => {
        setBranded(isBranded);
        if (isBranded) {
            setBrandGroupId(id ?? null);
        }

        setCurrentGroupId(id ?? null);

        if (!id) {
            resetGroup();
            resetMember();
        }
    }

    return { groupSettings, groupMemberSettings, currentGroupId, setGroupId, branded, groupHasFeature };
}

