import { LinkOptionType } from ".";

export const trackLinkOptions: LinkOptionType[] = [
    { value: 'amazon', label: 'Amazon', color: '#fb8d22', defaultValue: 'https://amazon.com' },
    { value: 'audius', collection: 'fac', label: 'Audius', color: '#CC0FE0', defaultValue: 'https://audius.co' },
    { value: 'bandcamp', label: 'bandcamp', color: '#1da0c3', defaultValue: 'https://bandcamp.com' },
    { value: 'soundcloud', label: 'SoundCloud', color: '#ff6200', defaultValue: 'https://soundcloud.com/' },
    { value: 'spotify', label: 'Spotify', color: '#19d55d', defaultValue: 'https://open.spotify.com/artist/' },
    { value: 'apple', label: 'Apple Music', color: undefined, defaultValue: 'https://' },
    { value: 'itunes-note', label: 'iTunes', color: undefined, defaultValue: 'https://' },
    { value: 'youtube', label: 'YouTube', color: '#f00', defaultValue: 'https://youtube.com/channel/' },
    { value: 'linkify', iconName: 'link', label: 'Other', color: undefined, defaultValue: 'https://' },

]