import React, { Fragment, useEffect, useState } from 'react'
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import loadable from '@loadable/component'
import { useAccountStore, useGroupMemberSettingsStore, useGroupStore } from 'app/stores/useRootStore';
import { getTabUriPart } from 'app/common/utils';
import { MainContainer } from 'app/common/Styled';
import { useCurrentGroup } from 'features/groupdetails/context';
import { IGroupTab, TabContentType } from 'features/groupdetails/model';
import { INewsTabContentSettings } from 'features/news/model';
import { IGroupContentTabSettings } from 'features/contentitems/model';
import LoadingComponent from 'app/layout/LoadingComponent';
import NotFound from 'app/layout/NotFound';

const WelcomePage = loadable(() => import('features/groupdetails/views/front/WelcomePage'));
const GroupDetails = loadable(() => import('features/groupdetails/views/front/GroupDetails'));
const SessionContainer = loadable(() => import('features/sessiondetails/views/front/SessionContainer'));
const MyTrackSubmissions = loadable(() => import('features/tracks/views/front/MyTrackSubmissions'));
const ProfileComponent = loadable(() => import('features/profiles/views/ProfileComponent'));
const ManageGroupContainer = loadable(() => import('features/groupdetails/views/manage/ManageGroupContainer'));
const GroupNewsComponent = loadable(() => import('features/news/views/front/GroupNewsComponent'));
const GroupContentItemsComponent = loadable(() => import('features/contentitems/views/front/GroupContentItemsComponent'));
const ConferenceRoutes = loadable(() => import('features/conferences/views/front/ConferenceRoutes'));

const GroupContainer = () => {
    const { isLoggedIn } = useAccountStore();
    const { loadGroupSettings, showGuestWelcomeScreen } = useGroupStore();
    const { loadGroupMemberSettings } = useGroupMemberSettingsStore();

    const { groupSettings, groupMemberSettings, setGroupId, currentGroupId, branded } = useCurrentGroup();

    let { groupId } = useParams();

    useEffect(() => {
        if (groupId) {
            setGroupId(groupId, false); //if we got groupId from url param, it's not a branded site and we need to set groupId here
        }
    }, [groupId, setGroupId])

    useEffect(() => {
        if (currentGroupId) {
            loadGroupSettings(currentGroupId);
            loadGroupMemberSettings(currentGroupId);
        }
    }, [loadGroupSettings, currentGroupId, loadGroupMemberSettings])

    let { pathname } = useLocation();
    const [fluid, setFluid] = useState(pathname.endsWith('player') || pathname.endsWith('attendees'));

    useEffect(() => {
        setFluid(pathname.endsWith('player') || pathname.endsWith('attendees'));
    }, [pathname])

    if (!groupSettings) return <>
        <LoadingComponent active content={`Loading ${branded ? 'site' : 'group'}...`} />
    </>;

    const getRouteForTabChildren = (tab: IGroupTab) => {
        //if (tab.contentType === TabContentType.About) return <></>; //about will never have children
        let tabPath = getTabUriPart(groupSettings, tab.sectionName);
        if (tabPath) {
            switch (tab.contentType) {
                case TabContentType.Members:
                    return <Route key={tab.sectionName} path={`${tabPath}/:username`} element={<ProfileComponent />} />
                case TabContentType.Events:
                    //TODO: pass tab settings down to SessionsContainer so filters are automatically set
                    return <Fragment key={`${tab.sectionName}`}>

                        <Route path={`${tabPath}/:sessionId/*`} element={<SessionContainer tab={tabPath} />} />
                    </Fragment>;

                case TabContentType.News:
                    let newsSettings = tab.tabContentSettings ? toJS(tab.tabContentSettings) as INewsTabContentSettings : null;
                    if (newsSettings?.displayMode === "ListDetails" || newsSettings?.displayMode === "FeaturedAndListDetails") {
                        return <Route key={tab.sectionName} path={`${tabPath}/:newsItemId`} element={<GroupNewsComponent sectionName={tab.sectionName} />} />
                    }
                    break;
                case TabContentType.GroupContent:
                    let settings = tab.tabContentSettings ? toJS(tab.tabContentSettings) as IGroupContentTabSettings : null;
                    if (settings?.displayMode === "ListDetails") {
                        return <Route key={tab.sectionName} path={`${tabPath}/:contentItemId`} element={<GroupContentItemsComponent sectionName={tab.sectionName} />} />
                    }
                    break;
            }

        }
        return <Fragment key={tab.sectionName}></Fragment>;
    }

    return (<>
        <MainContainer fluid={fluid}>
            <Routes>
                {!isLoggedIn && showGuestWelcomeScreen ?
                    <Route index element={<WelcomePage />} />
                    :
                    <Route index element={<GroupDetails />} />
                }
                <Route path=":tab" element={<GroupDetails />} />
                {
                    groupSettings.groupTabs.filter(x => x.contentType !== TabContentType.About).map((tab) => getRouteForTabChildren(tab))
                }

                {isLoggedIn && groupMemberSettings?.isMember && <>
                    <Route path="mytracks" element={<MyTrackSubmissions />} />
                </>}
                {isLoggedIn && groupMemberSettings?.canManage && <Route path="manage/*" element={<ManageGroupContainer />} />}
                {(!isLoggedIn || (!groupMemberSettings?.isMember && groupMemberSettings?.joined)) &&
                    <Route path=":tab/*" element={<NotFound />} />
                }
            </Routes>
        </MainContainer>
        <ConferenceRoutes groupSettings={groupSettings} />
    </>)
}

export default observer(GroupContainer)
