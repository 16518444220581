import React from 'react'
import styled from 'styled-components';

interface IMessageProps {
    header?: React.ReactNode;
    content?: React.ReactNode;
    error?: boolean;
}
const MessageContainer = styled.div`
    position: relative;
    min-height: 1em;
    margin: 1em 0;
    background: #F8F8F9;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
    transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 rgba(0, 0, 0, 0);
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.error {
        background-color: #FFF6F6;
        color: #9F3A38;
        box-shadow: 0 0 0 1px #E0B4B4 inset, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    }
`
const MessageHeader = styled.div`
    display: block;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: -0.14285714em 0 0 0;
    font-size: 1.14285714em;
`

const MessageContent = styled.div`
    opacity: 0.85;
    margin: 0.25em 0 0.75em 0;
    
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

`

export const InlineMessage = ({ header, content, error }: IMessageProps) => {
    return (
        <MessageContainer className={error ? 'error' : ''}>
            <div>
                {header && <MessageHeader>{header}</MessageHeader>}
                {content && <MessageContent>{content}</MessageContent>}
            </div>
        </MessageContainer>
    )
}

export default InlineMessage

