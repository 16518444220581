export enum TrackSubmissionState {
    Pending = 1,
    InProgress = 2,
    Done = 3,
    Failed = 4
}

export enum TrackSubmissionType {
    MusicUpload = 1 << 0,
    VideoUrl = 1 << 1,
    MultiTrack = 1 << 2,
}

export enum TrackPlaybackStatus {
    Pending = 1,
    Playing = 2,
    Played = 3
}