import { IConferenceSettings } from "features/conferences/model";
import { GroupJoinMode, IGroupTab, MessageTarget } from "features/groupdetails/model";
import { ILink } from "features/links/model/link";
import { GroupMemberRole } from "features/members/model";
import { IPhoto } from "features/photos/model/photo";
import { SiteFeatures } from "features/plans/model";
import { GroupDisplaySettings } from "./groupDisplaySettings";
import { IGroupOrganizer } from "./grouporganizer";
import { IGroupTheme } from "./grouptheme";

export interface IGroup {
    id: string;
    name: string;
    description: string;
    shortDescription: string;
    genre: string;
    daw: string;
    experienceLevel: string;
    location: string;
    timeZone: string;
    hidden: boolean;
    createdOn: Date;
    organizers: IGroupOrganizer[];
    memberCount: number,
    currentUserRole: GroupMemberRole;
}

export interface IGroupManage extends IGroup {
    groupLogo: IPhoto | null;
    groupNavBarLogo: IPhoto | null;
    groupCover: IPhoto | null;
}

export interface IGroupListItem extends IGroup {
    groupLogo: string;
}

export interface IDateFormats {
    dateFormatShort: string;
    dateFormatLong: string;
    timeFormat: string;
}

export interface IGroupSettings extends IDateFormats {
    id: string;
    name: string;
    joinMode: GroupJoinMode;
    displaySettings: GroupDisplaySettings;
    enabledFeatures: SiteFeatures;
    enabledMessageTargets: MessageTarget;
    groupLogoUrl: string;
    navBarLogoUrl?: string;
    coverPhotoUrl?: string;
    minimumModeratorRole: GroupMemberRole;
    groupTabs: IGroupTab[];
    groupThemes: IGroupTheme[];
    links: ILink[];
    conferences: IConferenceSettings[];
    showConference: IConferenceSettings;
    hasDiscord: boolean;
}

export interface IGroupFormValues extends Partial<IGroupManage>, Partial<IGroupSettings> {
    logoPhoto: string;
    navBarLogoPhoto: string;
    //groupLogo: string;
    coverPhoto: string;
}

export class GroupFormValues implements IGroupFormValues {
    id = undefined;
    name = '';
    description = '';
    shortDescription = '';
    genre = '';
    daw = '';
    experienceLevel = '';
    location = '';
    timeZone?: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    joinMode = GroupJoinMode.Automatic;
    hidden = false;
    displaySettings = 1;
    minimumModeratorRole = 3;
    groupTabs?: IGroupTab[] | undefined = undefined;
    groupThemes?: IGroupTheme[] | undefined = undefined;
    logoPhoto = '{}';
    navBarLogoPhoto = '{}';
    coverPhoto = '{}';
    //dateFormatLong?: string | undefined;
    dateFormatShort = 'MM/dd/yyyy';
    timeFormat = 'h:mm a';

    constructor(init?: IGroupManage | null, settings?: IGroupSettings | null) {
        //Object.assign(this, {}, init);
        Object.assign(this, init, settings);
        if (init?.shortDescription === null) this.shortDescription = '';
        this.groupTabs = undefined;
        this.groupThemes = undefined;
        if (init?.groupLogo) this.logoPhoto = JSON.stringify(init?.groupLogo);
        if (init?.groupNavBarLogo) this.navBarLogoPhoto = JSON.stringify(init?.groupNavBarLogo);
        if (init?.groupCover) this.coverPhoto = JSON.stringify(init?.groupCover);
    }

}