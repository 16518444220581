import { BreadCrumbType } from "@musicproworkshop/musicproworkshop.components";
import { isVisible } from "app/common/visibility";
import { IGroupSettings, TabContentType } from "features/groupdetails/model";
import { IUser } from "features/account/model/user";
import { IGroupMemberSettings } from "features/members/model";

export const membersRootLink = (branded: boolean, group: IGroupSettings) => {
    const membersPath = getTabUriPartForContentType(group, TabContentType.Members);
    if (!branded && group?.id) return `${groupRootLink(branded, group.id)}${membersPath}`;
    return `/${membersPath}`;

}
export const artistLink = (branded: boolean, group: IGroupSettings, username: string) => {
    const membersPath = membersRootLink(branded, group);
    return `${membersPath}/${username}`;
}

export const sessionsRootPath = (branded: boolean, group: IGroupSettings, tab: string) => {
    const sessionsPath = tab?.length > 0 ? tab.toLowerCase() : getTabUriPartForContentType(group, TabContentType.Events);
    const path = `${groupRootLink(branded, group.id)}${sessionsPath}`;
    return path;
}

export const sessionLink = (branded: boolean, group: IGroupSettings, tab: string, sessionId: string) => {
    const sessionsPath = sessionsRootPath(branded, group, tab);
    const path = `${sessionsPath}/${sessionId}`;
    return path;
}

export const externalLink = (relativeLink: string) => {
    const host = `${window.location.protocol}//${window.location.hostname.toLowerCase()}`;
    //console.log(host);
    return host + relativeLink;
}

export const groupRootLink = (branded: boolean, groupId?: string | null) => {
    return (!branded && groupId) ? `/groups/${groupId}/` : "/";
}

export const getTabUriPart = (group: IGroupSettings, tabName: string) => {

    const tab = group?.groupTabs.find(gt => gt.tabHeader?.toLowerCase() === tabName.toLowerCase()) ??
        group?.groupTabs.find(gt => gt.sectionName.toLowerCase() === tabName.toLowerCase());

    return tab?.sectionName?.toLowerCase();
}

export const getTabUriPartForContentType = (group: IGroupSettings, contentType: TabContentType) => {
    const tab = group?.groupTabs.find(gt => gt.contentType === contentType);
    return tab?.sectionName.toLowerCase();
}

export const getTabLink = (groupSettings: IGroupSettings, branded: boolean, sectionName?: string, contentType?: TabContentType, user?: IUser | null, memberSettings?: IGroupMemberSettings | null): BreadCrumbType => {
    if (groupSettings) {
        //console.log('getTabLink', 'group', toJS(group), 'branded', branded, 'sectionName', sectionName, 'contentType', TabContentType[contentType ?? -1], 'user', toJS(user));
        if (sectionName || contentType) {
            const tab = sectionName ?
                groupSettings?.groupTabs.find(gt => gt.sectionName.toLowerCase() === sectionName.toLowerCase()) :
                groupSettings?.groupTabs.find(gt => gt.contentType === contentType);
            if (tab && isVisible(tab?.visibilityMode, user, memberSettings)) {
                return { path: `${groupRootLink(branded, groupSettings.id)}${tab.sectionName.toLowerCase()}`, text: (tab.tabHeader?.length > 0 ? tab.tabHeader : tab.sectionName) };
            }

        }
    }
    return { path: undefined, text: undefined };
}

export const getCleanEventId = (groupId: string, eventId: string) => {
    const normalized = groupId.toLowerCase().normalize("NFD") + '-';
    if (eventId.startsWith(normalized)) return eventId.substr(normalized.length);
    return eventId;
}

export const cleanDomain = () => {
    const host = window.location.hostname.toLowerCase();
    if (host.startsWith('www.'))
        return host.substring(4);
    if (host.startsWith('dev'))
        return host.substring(5);
    return host;
}