import React from 'react'
import { ButtonBase } from '.'
import { ISessionButtonProps } from './SessionButtonProps'

export const SessionMoreDetailsButton = ({ sessionPath, buttonColor = 'secondary', right = true, hoverEffect = false }: ISessionButtonProps) => {
    return (
        <ButtonBase text='More Details' iconName='info' title='Session Details' buttonColor={buttonColor} right={right} hoverEffect={hoverEffect} to={sessionPath} />
    )
}

export default SessionMoreDetailsButton
