import { IconName } from "@fortawesome/fontawesome-svg-core";
import { ITrack, TrackPlaybackStatus, TrackSubmissionType } from ".";

export interface IPlaylistTrack extends ITrack {
    trackNumber: number;
    displayTrackNumber: number;
    trackLength: number;
    submissionType: TrackSubmissionType;
    playbackStatus: TrackPlaybackStatus;
    playbackStarted: Date;
    playbackStartedSeconds: number;
    memberUsername: string;
    memberImage?: string;
    hidden: boolean;
    commentsEnabled: boolean;
}

export const IconForTrackSubmissionType: IconName[] = [
    'music',
    'music',
    'video',
    'bars',
    'bars'
];

export interface ISubmittedTrack extends IPlaylistTrack {
    sessionId: string;
    sessionTitle: string;
    sessionDate: Date;
    sessionTab: string;
}