import styled from 'styled-components';
import { Dimmer } from 'app/common/Styled';

export const ModalOverlay = styled(Dimmer)`
    position: fixed;
    text-align: left;
	inset: 0px;
	pointer-events: initial;
	transition: opacity 220ms ease 0s;
	z-index: 500;    
`