import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ButtonGroup, Header, Icon, Image, ButtonColor, PhotoUploadWidget } from '@musicproworkshop/musicproworkshop.components';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { Card, Cards } from 'app/common/Styled';
import ConfirmButton from 'app/common/modal/ConfirmButton';
import { IconButton } from 'app/common/controls';
import { useCurrentGroup } from 'features/groupdetails/context';
import { useRootStore } from 'app/stores/useRootStore';
import { IPhoto } from 'features/photos/model/photo';

export interface IPhotoManagerProps {
    title: string;
    canEdit: boolean;
    addButtonCaption?: string;
    addButtonColor?: ButtonColor;
    addButtonBasic?: boolean;
    photoType: string;
    belongsToId: string;
    //photos?: IPhoto[];
    selectedPhoto?: IPhoto;
    onPhotoSelected?: (photo: IPhoto) => void;
    aspectRatio?: number;
}
const PhotosManager = observer(({ title, canEdit, addButtonCaption = 'Add Photo', addButtonColor = 'primary', addButtonBasic = false, photoType, belongsToId, selectedPhoto, onPhotoSelected, aspectRatio }: IPhotoManagerProps) => {
    const { photosStore } = useRootStore();
    const { initialize, loadPhotos, photos, uploadPhoto, photoProgress, deletePhoto } = photosStore;

    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [target, setTarget] = useState<string | undefined>(undefined);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);

    const { currentGroupId } = useCurrentGroup();

    useEffect(() => {
        if (!currentGroupId) return;
        initialize(currentGroupId);
        loadPhotos(photoType, belongsToId);
        return () => {
            //cleanup
        }
    }, [loadPhotos, photoType, belongsToId, initialize, currentGroupId])

    const handleUploadImage = async (photo: Blob) => {
        if (!photo) {
            return;
        }
        let result = await uploadPhoto(photoType, belongsToId, photo);
        if (result) {
            setAddPhotoMode(false);
            if (onPhotoSelected) onPhotoSelected(result);
        }
    }

    const handlePhotoSelected = async (photo: IPhoto) => {
        if (onPhotoSelected) onPhotoSelected(toJS(photo));
    }

    return (
        <div>
            <div style={{ width: '100%', padding: '1rem', display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
                <Header size='large' compact withIcon>
                    <Icon icon={['fas', 'images']} />
                    <span>{title}</span>
                </Header>
                {canEdit &&
                    <IconButton iconName={addPhotoMode ? 'times' : 'plus'} text={addPhotoMode ? 'Cancel' : addButtonCaption} buttonColor={addButtonColor} size='mini' right basic={addButtonBasic} title={addPhotoMode ? 'Cancel Upload' : 'Upload New Photo'} onClick={() => setAddPhotoMode(!addPhotoMode)} />
                }
            </div>
            <div>
                {addPhotoMode ? (
                    <PhotoUploadWidget uploadPhoto={handleUploadImage} loading={photoProgress} aspectRatio={aspectRatio} />
                ) : (
                    <Cards itemsPerRow={5}>
                        {photos && photos.map((photo) => (
                            <Card key={photo.id} selected={selectedPhoto?.url === photo.url}>
                                <Image src={photo.url} title={onPhotoSelected ? 'Select Photo' : undefined} onClick={() => handlePhotoSelected(photo)} />
                                {canEdit &&
                                    <ButtonGroup count={2}>
                                        <IconButton name={`${photo.id}_select`} iconName='eye' title={onPhotoSelected ? 'Select Photo' : 'Set as Main (visible) Photo'}
                                            loading={photoProgress && target === photo.id} buttonColor='positive' attached='bottom' size='mini'
                                            onClick={() => {
                                                setTarget(`${photo.id}_select`);
                                                handlePhotoSelected(photo);
                                                setTarget(undefined);
                                            }}
                                            disabled={onPhotoSelected ? false : photo.isActive} />
                                        {/* <div>{photo.usageCount}</div> */}
                                        <ConfirmButton name={`${photo.id}_delete`} iconName='trash-alt' size='mini' buttonColor='negative' attached='bottom' loading={photoProgress && deleteTarget === photo.id}
                                            title='Delete Photo' type="button" disabled={photo.isActive}
                                            confirmProps={{
                                                confirmHeader: 'Are you sure?', confirmContent: photo.isActive ? 'This photo is set as a the active photo' : ((photo.usageCount ?? 0) > 0 ? `This photo is used in ${photo.usageCount} places` : ''),
                                                confirmButtonText: 'Delete Photo', confirmButtonColor: 'negative',
                                                onConfirmed: () => {
                                                    setDeleteTarget(`${photo.id}_delete`);
                                                    deletePhoto(photoType, belongsToId, photo);
                                                    setDeleteTarget(undefined);
                                                }
                                            }}
                                        />
                                    </ButtonGroup>
                                }
                            </Card>
                        ))}
                    </Cards>

                )}
            </div>
        </div>
    )
})

export default PhotosManager

export const PhotosManagerModal = NiceModal.create(({ size, props, ...rest }: { size?: ModalSize, props: IPhotoManagerProps }) => {
    const modal = useModal();
    return <NiceModalContainer modalIsOpen={modal.visible} body={<PhotosManager {...rest} {...props} />} size={size} />
});