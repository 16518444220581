import styled from "styled-components";

export const GridColumn = styled.div<{ width?: number }>`
    position: relative;
    display: inline-block;
    width:${props => (100 * (props.width ?? 16) / 16) + '% !important'};
    padding-left: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    vertical-align: top;

`

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding:0;
    margin:-1rem;

    & > * {
        padding-left:1rem;
        padding-right: 1rem;
    }

    &.centered {
        text-align: center;
        justify-content: center;

        ${GridColumn} {
            display: block;
            margin-left:auto;
            margin-right: auto;
        }
    }
`