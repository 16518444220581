import { runInAction, makeAutoObservable } from "mobx";
import PagingHelper from "app/common/paging/pagingHelper";
import { groupBy } from "app/common/utils";
import { IGroupContentItem } from "features/contentitems/model";
import contentItemsApiClient from "./groupContentApi";

export class GroupContentItemsStore {
    constructor() {
        if (!this.data)
            this.data = new PagingHelper(10, this.loadItems);
        else
            this.data.setLoadItems(this.loadItems)
        makeAutoObservable(this);
    }

    data: PagingHelper<IGroupContentItem> = new PagingHelper(10);

    groupId: string | null = null;
    submitting = false;

    initializeContentItemsStore = (groupId?: string | null) => {
        this.groupId = groupId ?? null;
    }

    get contentItem() { return this.data.item; }

    get groupItemsByCategory() {
        const sortedItems = Array.from(this.data.items.values()).sort(
            (a, b) => (a.sortOrder ?? 9999) - (b.sortOrder ?? 9999)
        );
        return Object.entries(groupBy(sortedItems, 'category'));
    }

    loadItems = async (): Promise<IGroupContentItem[]> => {

        if (!this.groupId) return [];
        this.data.loadingItems = true;
        try {
            const pagedData = await contentItemsApiClient.list(this.groupId, this.data.searchParams);
            const { data, pagination } = pagedData;

            runInAction(() => {
                data.forEach(contentItem => {
                    this.data.items.set(contentItem.id, contentItem);
                });
                this.data.paging = pagination;
                this.data.loadingItems = false;
                return data;
            })
        } catch (error: any) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            console.error(error.response);
        }
        return [];
    }

    loadContentItem = async (itemId: string) => {
        if (!this.groupId) return;
        //TODO: check local items first
        this.data.loadingItems = true;
        try {
            const item = await contentItemsApiClient.details(this.groupId, itemId);
            runInAction(() => {
                this.data.item = item;
                this.data.loadingItems = false;
            })
            return item;
        } catch (error: any) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            console.error(error.response);
        }
        return null;
    }

    clearContentItem = () => {
        this.data.item = null;
    }

}

