import React from 'react'
import styled from 'styled-components';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Icon } from '@musicproworkshop/musicproworkshop.components';

const SectionHeaderContainer = styled.div`
    display:grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    font-weight: bold;
    line-height: 1.28571em;
    text-transform: none;
    padding: 0;
    margin: -0.1428em 0px 1rem 0px;
    font-size: 1.15em;
`;
const TextContainer = styled.div`
    display:inline-block;
    vertical-align:top;
    font-size: 1.28571429em;
`;

const SubTextContainer = styled.div`
    display:block;
    font-weight:normal;
    padding:0;
    margin:0.2em 0 0;
    font-size:1rem;
    line-height: 1.2em;
    color: var(--segmentSubtleTextColor);
`;

export const SectionHeader = ({ icon, text, subText }: { icon?: string, text?: string, subText?: string }) => {
    return (
        <SectionHeaderContainer>
            {icon && <Icon icon={['fas', icon as IconName]} iconColor='primary' iconSize='large' style={{ fontSize: '1.75em', marginRight: '0.2em' }} />}

            <TextContainer>{text}
                {subText && <SubTextContainer>{subText}</SubTextContainer>}
            </TextContainer>

        </SectionHeaderContainer>
    )
}

export default SectionHeader
