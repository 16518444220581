import React from 'react'
import NiceModal from '@ebay/nice-modal-react';
import { SmartButtonProps } from '@musicproworkshop/musicproworkshop.components';
import ButtonBase from '../controls/Buttons/ButtonBase';
import { IConfirmProps } from 'app/common/modal/ConfirmModal';

interface IProps extends SmartButtonProps {
    confirmProps: IConfirmProps;
}

const ConfirmButton = ({ confirmProps, ...rest }: IProps) => {
    const showConfirmModal = () => {
        NiceModal.show('confirm-modal', { ...confirmProps });
    }

    return (
        <ButtonBase {...rest} onClick={() => showConfirmModal()} />
    )
}

export default ConfirmButton
