import React from 'react'
import { ButtonBase } from '.'
import { ISessionButtonProps } from './SessionButtonProps'

export const SessionRecapButton = ({ sessionPath, buttonColor = 'primary', right = true }: ISessionButtonProps) => {

    return (
        <ButtonBase text='Recap' iconName='play' title='Session Recap' buttonColor={buttonColor} right={right} to={`${sessionPath}${sessionPath ? '/' : ''}player`} />
    )
}


export default SessionRecapButton
