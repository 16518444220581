import { runInAction, makeAutoObservable } from "mobx";
import { parseDate } from "app/common/utils";
import { GroupMemberRole, IGroupMemberSettings } from "features/members/model";
import membersApiClient from "./groupMembersApi";

export class GroupMemberSettingsStore{
  constructor() {
    makeAutoObservable(this);
  }

  groupMemberSettings: IGroupMemberSettings | null = null;
  loadingSettings = false;

  loadGroupMemberSettings = async (groupId: string, forceRefresh?: boolean): Promise<IGroupMemberSettings | null> => {
    try {
      //TODO only if we don't have the groupSettings or fordRefresh is true
      if (this.groupMemberSettings && this.groupMemberSettings.groupId === groupId && !forceRefresh) {
        return this.groupMemberSettings;
      }

      this.loadingSettings = true;
      let groupMemberSettings = await membersApiClient.settings(groupId);
      runInAction(() => {
        this.groupMemberSettings = this.setGroupProps(groupMemberSettings);
        this.loadingSettings = false;
      })
      return groupMemberSettings;
    } catch (error) {
      runInAction(() => {
        this.loadingSettings = false;
      })
      console.error(error);
    }
    return null;
  }

  setGroupProps = (settings: IGroupMemberSettings) => {
    if (settings.previousVisit) settings.previousVisit = parseDate(settings.previousVisit)!;
    return settings;
  };

  setGroupMember = (memberRole?: GroupMemberRole) => {
    if (this.groupMemberSettings) {
      if (memberRole !== undefined) {
        this.groupMemberSettings.isMember = memberRole < GroupMemberRole.PendingMember;
        this.groupMemberSettings.joined = true;
        this.groupMemberSettings.currentUserRole = memberRole;
      } else {
        this.groupMemberSettings.isMember = false;
        this.groupMemberSettings.joined = false;
      }
    }
  }

  updateMessagesReadCount = (count: number) => {
    if (!this.groupMemberSettings) return;
    this.groupMemberSettings.messageCount = count;
  }

  resetMember = () => {
    this.groupMemberSettings = null;
  }


}