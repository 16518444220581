import { RestClient } from '@musicproworkshop/musicproworkshop.components';
import { DisplayMode, ILiveSessionLayout } from 'features/livesession/layouts/model';
import { IGroupSession, SessionStatus } from 'features/sessiondetails/model';

const SessionsDetailsApi = {
    details: (groupId: string, sessionId: string, manage?: boolean): Promise<IGroupSession> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}${manage ? '?manage=true' : ''}`),
    liveLayout: (groupId: string, sessionId: string, displayMode: DisplayMode): Promise<ILiveSessionLayout> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/layout?mode=${displayMode}`),
    updateStatus: (groupId: string, sessionId: string, status: SessionStatus) => RestClient.put(`/groups/${groupId}/sessions/${sessionId}/status/${SessionStatus[status]}`, {}),
    attend: (groupId: string, sessionId: string) => RestClient.post(`/groups/${groupId}/sessions/${sessionId}/attend`, {}),
    //unattend: (groupId: string, sessionId: string) => RestClient.del(`/groups/${groupId}/sessions/${sessionId}/attend`),
    upcoming: (groupId: string, liveOnly: boolean): Promise<IGroupSession> => RestClient.get(`/groups/${groupId}/sessions/upcoming?live=${liveOnly}`),
};
export default SessionsDetailsApi;