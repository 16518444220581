import React, { useState } from "react";
import { Formik, Form, FormikHelpers, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { SubHeader, TextInput } from "@musicproworkshop/musicproworkshop.components";
import { useAccountStore, useRootStore } from "app/stores/useRootStore";
import { ModalActionContainer, ModalContentContainer, ModalHeaderContainer } from 'app/common/Styled';
import NiceModalContainer, { ModalSize } from "app/common/modal/NiceModalContainer";
import { FormSubmitButton } from "app/common/controls";
import { FormElement } from "app/common/formInputs";
import { useCurrentGroup } from "features/groupdetails/context";
import { IUserFormValues, UserFormValues } from "features/account/model/user";
import ValidationErrors from "features/account/views/ValidationError";

const RegisterForm = ({ onRegistered, closeModal }: { onRegistered?: () => void, closeModal: () => void }) => {
  const { register } = useAccountStore();

  const rootStore = useRootStore();
  const { resetAfterUserChange } = rootStore;

  const { currentGroupId } = useCurrentGroup();

  const [formValues] = useState<IUserFormValues>(new UserFormValues());

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is Required').min(6, 'Password too short'),
    displayName: Yup.string().required('Display Name is Required').min(3, 'Display Name must be at least 3 characters').max(20, 'Display Name can\'t be longer than 20 characters'),
  })

  const onFormSubmit = async (data: IUserFormValues, formikHelpers: FormikHelpers<IUserFormValues>): Promise<void> => {
    data.sendEmail = true;
    try {
      await register(data, currentGroupId);
      closeModal();
      resetAfterUserChange(currentGroupId);
      //navigate(navigateTo ?? '/', { replace: true });
      if (onRegistered) onRegistered();
    }
    catch (error: any) {
      formikHelpers.setErrors({ error })

    }
  }

  return (
    <Formik validationSchema={validationSchema} enableReinitialize initialValues={formValues} onSubmit={(values, formikHelpers) => onFormSubmit(values, formikHelpers)} validateOnChange validateOnBlur>
      {({ handleSubmit, errors, dirty, isValid, isSubmitting }) => (
        <FormElement as={Form} onSubmit={handleSubmit} style={{ padding: 0 }}>
          <ModalHeaderContainer>
            <SubHeader headerColor='primary' upper>Create Your Account</SubHeader>
          </ModalHeaderContainer>

          <ModalContentContainer>

            <TextInput name='email' placeholder='Email' type='email' autoComplete='login' autoCorrect='false' icon='envelope' iconPosition='left' showLiveFeedback />

            <TextInput name='password' placeholder='Password' type='password' autoComplete='password' autoCorrect='false' icon='lock' iconPosition='left' showLiveFeedback />

            <TextInput name='displayName' placeholder='Display Name' type='text' autoComplete='Name' autoCorrect='false' icon='user' iconPosition='left' showLiveFeedback />

            <ErrorMessage name='error' render={() => <ValidationErrors errors={errors.error} />} />

          </ModalContentContainer>
          <ModalActionContainer>
            <FormSubmitButton text='Register' buttonColor='primary' fluid loading={isSubmitting} disabled={!dirty || !isValid} />
          </ModalActionContainer>
        </FormElement>
      )}
    </Formik>

  );
};

export default RegisterForm;

export const RegisterModal = NiceModal.create(({ onRegistered, size }: { onRegistered?: () => void, size: ModalSize }) => {
  const modal = useModal();
  return <NiceModalContainer modalIsOpen={modal.visible} body={<RegisterForm onRegistered={onRegistered} closeModal={() => { modal.resolve(); modal.hide(); }} />} size={size} />
});