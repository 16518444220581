import styled from "styled-components";

export const VoteButtonContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width:100%;
    border-radius: 0.27rem;
    & > * {
        top:0;
        height:100%;
        padding: 0.83em;
    }
    *:first-child {
        background-color: gray;
        border-radius: 0.27rem 0 0 0.27rem;
    }
    &> *:last-child {
        color: rgba(0,0,0,0.6);
    }
    &.voted.primary *:first-child {
    background-color: var(--primaryColor);

    }
    &.voted.secondary *:first-child {
        background-color: var(--secondaryColor);
    }
`;
