import React, { useEffect, useState } from 'react';
import { Header, Icon, SegmentContainer } from '@musicproworkshop/musicproworkshop.components';
import { useCurrentGroup } from 'features/groupdetails/context';
import { groupRootLink } from '../common/utils';
import { IconButton } from '../common/controls';

const NotFound = () => {
    const { currentGroupId, branded } = useCurrentGroup();
    const [opacity, setOpacity] = useState(0);
    useEffect(() => {
        setOpacity(1);
    }, []);
    
    return (
        <SegmentContainer clearing basic style={{ display: 'flex', flexDirection: 'column', placeItems: 'center', transition: '0.5s', willChange: 'opacity', opacity: opacity }}>
            <Icon icon={['fas', 'search']} iconSize='huge' />
            <Header size='large' centered>
                Oops - we've looked everywhere but couldn't find this.
            </Header>
            <IconButton iconName='home' text='Go Back To The Start' to={currentGroupId ? groupRootLink(branded, currentGroupId) : '/'} title='Go to Home Page' />

        </SegmentContainer>
    );
};

export default NotFound;