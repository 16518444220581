import { IDateFormats } from "features/groupdetails/model";
import { formatDate, formatTime } from ".";
import { IPlaylistTrack } from "../../../features/tracks/model";

export const formatTrackTitle = (artist?: string, title?: string, trackNumber?: number) => {
    let trackNumberFormatted = (trackNumber && trackNumber > 0) ? `${trackNumber}. ` : '';
    let artistTitleSeparator = (artist && title && artist !== '' && title !== '') ? ' - ' : '';
    return `${trackNumberFormatted}${artist ?? ''}${artistTitleSeparator}${title ?? ''}`;
}

export const formatSubmissionLimits = (maxTracksPerUser: number, submissionDeadline?: Date, dateFormats?: IDateFormats | null) => {
    const maxTracksSuffix = maxTracksPerUser === 1 ? ' track' : ' tracks';
    const formattedDate = submissionDeadline ? formatDate(submissionDeadline, dateFormats, false, true) : '';
    if (maxTracksPerUser > 0) {
        let header = `You can submit ${maxTracksPerUser} ${maxTracksSuffix} `;

        if (submissionDeadline) {
            header = header + ' until ' + formattedDate;
        }
        return header;

    } else if (submissionDeadline) {
        return 'You have until ' + formattedDate;
    }
    return '';

}

export const getTrackTimestamps = (tracks: IPlaylistTrack[], timeAdjsutmentInSeconds: number, sessionLink: string) => {

    let result = `${sessionLink}\n\n0:00 - Start\n`;


    tracks.slice().filter(track => track.trackNumber >= 0).sort((a, b) => a.playbackStartedSeconds - b.playbackStartedSeconds).forEach(track => {
        result = result + `${formatTime(track.playbackStartedSeconds - timeAdjsutmentInSeconds)} - ${formatTrackTitle(track.artist, track.title, track.trackNumber)}\n`;
    });

    return result;

}

export const copyTrackTimestampsToClipboard = async (tracks: IPlaylistTrack[], timeAdjsutmentInSeconds: number, sessionLink: string) => {
    let contents = getTrackTimestamps(tracks, timeAdjsutmentInSeconds, sessionLink);
    //console.log(contents);
    if (!navigator.clipboard) return
    try {
        await navigator.clipboard.writeText(contents);
    } catch (error) {
        console.error('clipboard error', error);
    }
    //navigator.clipboard.writeText(contents);
}

export const saveTrackTimestampsToFile = (sessionTitle: string, tracks: IPlaylistTrack[], timeAdjsutmentInSeconds: number, sessionLink: string) => {
    let fileContents = getTrackTimestamps(tracks, timeAdjsutmentInSeconds, sessionLink);
    let fileNameFull = `Session Recap Timestamps - ${sessionTitle}.txt`;
    let blob = new Blob([fileContents], { type: "text/plain; charset=utf-8" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", fileNameFull);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}