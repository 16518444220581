//external
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import loadable from '@loadable/component'
import { library } from '@fortawesome/fontawesome-svg-core';
import NiceModal from '@ebay/nice-modal-react';

//icons
import { faAmazon, faAndroid, faApple, faBandcamp, faChrome, faDiscord, faEdge, faFacebook, faFacebookF, faFirefoxBrowser, faInstagram, faItunesNote, faLinkedinIn, faMeetup, faSafari, faSoundcloud, faSpotify, faTwitch, faTwitter, faVimeo, faVimeoV, faWindows, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faAdjust, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBan, faBars, faBroadcastTower, faCalendarAlt, faCaretDown, faCaretRight, faCheck, faCheckDouble, faChevronUp, faClock, faCog, faCogs, faComments, faCompactDisc, faCopy, faDesktop, faDisplay, faDownload, faEdit, faEject, faEnvelope, faEye, faEyeSlash, faFilter, faGlobe, faHome, faImage, faImages, faInfo, faLink, faLock, faM, faMapLocation, faMapMarkerAlt, faMobile, faMusic, faPaperPlane, faPause, faPhone, faPlay, faPlus, faPowerOff, faQuestion, faRedoAlt, faReply, faRightFromBracket, faRightLeft, faRightToBracket, faS, faSave, faSearch, faSignOutAlt, faStepBackward, faStop, faSyncAlt, faTimes, faTrashAlt, faTv, faTvAlt, faUpload, faUser, faUserMinus, faUserPlus, faUsers, faVideo, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { faAudius } from "./customIcons";

//context
import { useAccountStore, useGroupStore } from "app/stores/useRootStore";
import { useCurrentGroup, useGroupTheme } from "features/groupdetails/context";
import { cleanDomain } from "app/common/utils";

//views
import PageMeta from "app/common/controls/PageMeta";
import NavContainer from "app/layout/NavContainer";
import LoadingComponent from "app/layout/LoadingComponent";
import LogOut from "features/account/views/LogOut";
import GroupContainer from "features/groupdetails/views/GroupContainer";
import ClearGroupId from "./ClearGroupId";
import NotFound from "./NotFound";
import { LoginModal, RegisterModal } from "features/account/views";
import { BasicModal } from "app/common/modal/NiceModalContainer";
import ConfirmModal from "app/common/modal/ConfirmModal";

const GroupListContainer = loadable(() => import('features/grouplist/views/front/GroupListContainer'));
const CreateGroupContainer = loadable(() => import('features/groupdetails/views/manage/CreateGroupContainer'));
const MessagesComponent = loadable(() => import('features/messaging/messages/views/front/MessagesComponent'));
const Account = loadable(() => import('features/account/views/Account'));
const ProfileComponent = loadable(() => import('features/profiles/views/ProfileComponent'));
const EmailLinkContainer = loadable(() => import('features/account/views/EmailLinkContainer'));
const PaymentsComponent = loadable(() => import('features/payments/views/PaymentsComponents'));

function App() {
    const { userAppStart, isLoggedIn } = useAccountStore();
    const groupStore = useGroupStore();
    const { loadGroupByDomain, showGuestWelcomeScreen, groupDetails } = groupStore;
    const { groupSettings, currentGroupId, setGroupId, branded } = useCurrentGroup();
    const { darkThemeEnabled } = useGroupTheme();

    const [appLoaded, setAppLoaded] = useState<boolean>(false);

    const [domainCheckStatus, setDomainCheckStatus] = useState<number>(0);

    library.add(faBandcamp, faFacebook, faFacebookF, faInstagram, faLinkedinIn, faLinkedinIn, faTwitch, faTwitter, faSoundcloud, faSpotify, faVimeo, faVimeoV, faYoutube, faDiscord, faMeetup, faAmazon, faApple, faItunesNote, faLink, faWindows, faAndroid, faChrome, faEdge, faSafari, faFirefoxBrowser);
    library.add(faAudius);

    library.add(faUser, faEnvelope, faPhone, faLock, faCogs, faCog, faMusic, faVideo, faBars, faAdjust, faPowerOff, faCaretRight, faCaretDown, faInfo, faUsers, faMapMarkerAlt, faCalendarAlt, faClock, faFilter, faStepBackward, faPause, faPlay, faStop, faVolumeUp, faEdit, faCopy, faGlobe, faUpload, faComments, faQuestion, faTrashAlt, faSave, faPaperPlane, faCheck, faTimes, faPlus, faEye, faEyeSlash, faImage, faImages, faArrowRight, faArrowLeft, faDownload, faSearch, faHome, faChevronUp, faReply, faMapLocation, faTv, faTvAlt, faDisplay);
    library.add(faS, faM);
    library.add(faSignOutAlt, faArrowUp, faArrowDown, faBroadcastTower, faCheckDouble, faEject, faCompactDisc, faSyncAlt, faRedoAlt, faUserPlus, faUserMinus, faBan, faMobile, faDesktop, faEyeSlash, faRightFromBracket, faRightToBracket, faRightLeft)
    //if not default domain, 

    NiceModal.register('login-modal', LoginModal);
    NiceModal.register('register-modal', RegisterModal);
    NiceModal.register('basic-modal', BasicModal)
    NiceModal.register('confirm-modal', ConfirmModal);

    // //if there's no group id in local storage, get it from API
    useEffect(() => {
        const checkCustomDomain = async () => {
            if (domainCheckStatus > 0) return;
            setDomainCheckStatus(1);
            let domain = cleanDomain();
            if (domain && domain !== process.env.REACT_APP_DOMAIN) {
                //console.log('checkCustomDomain', domain, 'is custom')
                if (!currentGroupId) {
                    //console.log('checkCustomDomain', domain, 'is custom', 'check for group')
                    let groupIdByDomain = await loadGroupByDomain(domain);
                    if (groupIdByDomain) {
                        setGroupId(groupIdByDomain, true);
                    }
                }
            }
            setDomainCheckStatus(2);
        }
        checkCustomDomain();
    }, [currentGroupId, domainCheckStatus, loadGroupByDomain, setGroupId])


    useEffect(() => {
        if (domainCheckStatus < 2) return
        userAppStart().finally(() => setAppLoaded(true));
    }, [userAppStart, setAppLoaded, domainCheckStatus]);

    if (!appLoaded)
        return <LoadingComponent active={!appLoaded} content='Loading site...' />;

    return (
        <>
            <PageMeta title='' description={groupDetails?.shortDescription ?? "For music producers, by music producers. Join a group, get feedback for you music, give feedback to others"} />
            <ToastContainer position="bottom-right" theme={darkThemeEnabled ? 'dark' : 'light'} />
            <Routes>
                <Route path="/ResetPassword" element={<NiceModal.Provider><EmailLinkContainer /></NiceModal.Provider>} />
                <Route path="/ConfirmEmail" element={<NiceModal.Provider><EmailLinkContainer /></NiceModal.Provider>} />

                {isLoggedIn && <>
                    <Route path="account" element={<NavContainer />}>
                        <Route index element={<Account />} />
                    </Route>
                    <Route path="profile" element={<NavContainer />}>
                        <Route index element={<ProfileComponent />} />
                    </Route>
                    <Route path="subscribe" element={<NavContainer />}>
                        <Route index element={<PaymentsComponent />} />
                    </Route>
                    <Route path="messages" element={<NavContainer />}>
                        <Route index element={<MessagesComponent />} />
                    </Route>
                </>}
                <Route path="logout" element={<LogOut />} />

                {!branded ?
                    <Route element={<NavContainer />}>
                        <Route index element={<GroupListContainer />} />
                        <Route path="groups" element={<GroupListContainer />} />
                        {isLoggedIn &&

                            <Route path="groups/createanew1" element={<CreateGroupContainer />} />

                        }
                    </Route>
                    :
                    <Route path="groups/:groupId/*" element={<ClearGroupId />} /> //Redirect from old groups/groupId urls
                }

                <Route path="*" element={<NavContainer hideNavBar={!isLoggedIn && ((branded && !groupSettings) || showGuestWelcomeScreen)} />}>
                    <Route path={branded ? "*" : "groups/:groupId/*"} element={<GroupContainer />} />
                </Route>


                <Route path='notfound' element={<NotFound />} />
            </Routes>
        </>
    )
}

export default observer(App);