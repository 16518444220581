import styled from "styled-components";
import { ShimmerAnimation } from ".";

export const Placeholder = styled.div`
    position: static;
    overflow: hidden;
    animation: ${ShimmerAnimation} 2s linear;;
    animation-iteration-count: infinite;
    background-color: var(--segmentBackgroundColor);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.08) 0, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.08) 30%);
    background-size: 1200px 100%;

    max-width: 30rem;
    & > :before {
        background-color: var(--segmentBackgroundColor);
    }

    &.fluid {
        max-width: none;
    }

    .image:not(.header) {
        height: 100px;
    }
    .square.image:not(.header) {
        height: 0;
        overflow: hidden;
        padding-top:100%;
    }
    .rectangular.image:not(.header) {
        height: 0;
        overflow: hidden;
        padding-top:75%;
    }

    .header {
        position: relative;
        overflow: hidden;
    }

    .line {
        position: relative;
        height: 0.85714em;
        margin-bottom: 0.5em;
        background-color: var(--segmentBackgroundColor);
        &.lighter {
            background-color: var(--segmentBackgroundLighterColor);
        }
        &:before,
        &:after {
            top: 100%;
            position: absolute;
            content: '';
            background-color: inherit;
            height: 0.5em;
        }
        &:before{
            left:0;
        }
        &:after {
            right:0;
            background-color: var(--segmentBackgroundColor);
        }
        &.lighter:after {
            background-color: var(--segmentBackgroundLighterColor);
        }
        &:not(:first-child) {
            margin-top: 0.5em;
        }

        &:nth-child(1):after {
            width:0;
        }

        &:nth-child(2):after {
            width:50%;
        }

        &:nth-child(3):after {
            width:10%;
        }

        &:nth-child(4):after {
            width:35%;
        }

        &:nth-child(5):after {
            width:65%;
        }
    }

    .header .line {
        margin-bottom: 0.64285em;
        &:before, &:after{
            height: 0.64285em;
        }
        &:not(:first-child) {
            margin-top: 0.64285em;
        }
        &:after{
            width: 20%;
        }
        &:nth-child(2):after {
            width:60%;
        }
    }

`