import { runInAction, makeAutoObservable } from "mobx";
import { parseDate } from "app/common/utils";
import { GroupDisplaySettings, IGroup, IGroupSettings, IGroupTab } from "features/groupdetails/model";
import groupsApiClient from "./groupsApi";

export class GroupStore {
  constructor() {
    makeAutoObservable(this);
  }

  groupSettings: IGroupSettings | null = null;
  groupDetails: IGroup | null = null;
  submitting = false;
  loadingGroup = false;

  get showGuestWelcomeScreen() { return GroupDisplaySettings.ShowGuestWelcomeScreen === ((this.groupSettings?.displaySettings ?? 0) & GroupDisplaySettings.ShowGuestWelcomeScreen); }

  setGroupProps = (group: IGroupSettings) => {
    group.groupTabs?.forEach((tab) => this.setTabProps(tab));
    group.conferences?.forEach((conference) => {
      conference.date = parseDate(conference.date)!;
      conference.endDate = parseDate(conference.endDate);
      conference.publishOn = parseDate(conference.publishOn);
    })
    group.showConference = group.conferences.filter(x => !x.publishOn || x.publishOn.getTime() > new Date().getTime()).sort((a, b) => a.date.getTime() - b.date.getTime())[0];
    return group;
  };

  setTabProps = (tab: IGroupTab) => {
    if (tab.tabContentSettings) {
      tab.tabContentSettings = JSON.parse(tab.tabContentSettings.toString());
    }
    return tab;
  }

  loadGroupByDomain = async (domain: string): Promise<string | null> => {
    try {
      this.loadingGroup = true;
      let group = await groupsApiClient.byDomain(domain);
      runInAction(() => {
        this.groupSettings = this.setGroupProps(group);
        this.loadingGroup = false;
      })
      return group?.id ?? null;
    } catch (error) {
      runInAction(() => {
        this.loadingGroup = false;
      })
      console.error(error);
    }
    return null;
  }

  loadGroupSettings = async (groupId: string, forceRefresh?: boolean): Promise<IGroupSettings | null> => {
    try {
      //TODO only if we don't have the groupSettings or fordRefresh is true
      if (this.groupSettings && this.groupSettings.id === groupId && !forceRefresh) {
        return this.groupSettings;
      }

      this.loadingGroup = true;
      let groupSettings = await groupsApiClient.settings(groupId);
      runInAction(() => {
        this.groupSettings = this.setGroupProps(groupSettings);
        this.loadingGroup = false;
      })
      return groupSettings;
    } catch (error) {
      runInAction(() => {
        this.loadingGroup = false;
      })
      console.error(error);
    }
    return null;
  }

  loadGroupDetails = async (id: string, forceRefresh?: boolean): Promise<IGroup | null> => {
    //this.loadingGroup = true;
    if (this.groupDetails && !forceRefresh) {
      return this.groupDetails;
    } else {
      this.loadingGroup = true;
      try {
        //TODO Is this needed? if (!this.rootStore.userStore.user && this.rootStore.userStore.token) await this.rootStore.userStore.getUser();
        const group = await groupsApiClient.details(id);
        runInAction(() => {
          this.groupDetails = group;
          this.loadingGroup = false;
        })
        return group;
      } catch (error) {
        runInAction(() => {
          this.loadingGroup = false;
        })
        console.error(error);
      }
      return null;
    }
  }

  updateCurrentPhotos = (groupLogoUrl?: string, navBarLogoUrl?: string, coverPhotoUrl?: string) => {
    if (!this.groupSettings) return;
    if (groupLogoUrl) this.groupSettings.groupLogoUrl = groupLogoUrl;
    this.groupSettings.navBarLogoUrl = navBarLogoUrl;
    this.groupSettings.coverPhotoUrl = coverPhotoUrl;
  }

  resetGroup = () => {
    this.groupSettings = null;
    this.groupDetails = null;
  }

}
