import { IconName } from "@fortawesome/fontawesome-svg-core";

export const osIcon = (os?: string): IconName | null => {
    switch (os) {
        case 'Windows':
            return 'windows';
        case 'Mac OS X':
            return 'apple';
        case 'iOS':
            return 'apple';
        case 'Android':
            return 'android';
        case 'Chrome OS':
            return 'chrome'
    }

    return null;

}

export const browserIcon = (browser?: string): IconName | null => {
    if (!browser) return null;
    if (browser.indexOf('Chrome') >= 0) {
        return 'chrome';
    } else if (browser.indexOf('Firefox') >= 0) {
        return 'firefox-browser';
    } else if (browser.indexOf('Safari') >= 0) {
        return 'safari';
    } else if (browser.indexOf('Edge') >= 0) {
        return 'edge';
    }

    return null;
}

export const deviceTypeIcon = (os?: string): IconName | null => {
    switch (os) {
        case 'iOS':
        case 'Android':
            return 'mobile';

        case 'Windows':
        case 'Mac OS X':
        case 'Chrome OS':
            return 'desktop';
    }
    return null;
}