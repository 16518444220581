import { addMinutes, formatDistance, parseJSON } from "date-fns";
import { format } from "date-fns-tz";
import { IDateFormats } from "features/groupdetails/model";

export const formatTime = (totalSeconds?: number): string => {
    if (!totalSeconds) return '';
    if (totalSeconds === 0) return '0:00';
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = Math.floor(totalSeconds - (minutes * 60));
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes - (hours * 60));
    const timeString = `${hours > 0 ? hours + ':' : ''}${hours > 0 && minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    return timeString;
    //return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export const formatDistanceToNowNoFuture = (date: Date | number, options?: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
}): string => {

    let baseDate: Date | number = new Date();
    if (baseDate < date) {
        baseDate = date;
    }

    return formatDistance(date, baseDate, options);
}


export const getTotalSeconds = (time: string): number => {
    try {
        let [hours, minutes, seconds] = time.split(':');
        if (minutes === undefined) {
            seconds = hours;
            minutes = '0';
            hours = '0';

        } else if (seconds === undefined) {
            seconds = minutes;
            minutes = hours;
            hours = '0';
        }
        let totalMinutes = (+hours * 60) + +minutes;
        let totalSeconds = (+totalMinutes * 60) + +seconds;
        return totalSeconds;
    } catch (error) {
        console.error('getTotalSeconds', error);

    }
    return 0;
}

export const getFirstOfMonth = (date: Date): Date => {

    let result = new Date(date.getFullYear(), date.getMonth(), 1);
    return result;

}

export const parseDate = (date: Date | string | number | undefined) => {
    try {
        if (date) {
            let result = parseJSON(date);
            if (result.getFullYear() > 1900) return result;
        }
    } catch (error) {
        console.error('parseDate', error);

    }
    return undefined;
}

export const getUTCDate = (date: Date | string) => {
    date = new Date(date);
    date.setUTCHours(0);
    return addMinutes(date, date.getTimezoneOffset());
}

export const formatTimeRange = (date: Date, endDate?: Date, parts: 'date' | 'time' | 'both' = 'time', dateFormats?: IDateFormats | null, withTimeZone: boolean = true) => {
    let result = '';
    if (parts === 'both' || parts === 'date') {
        result = format(date, dateFormats?.dateFormatLong ?? 'eeee, MMMM do yyyy');
    }

    if (parts === 'both') result = result + ', ';
    if (parts === 'both' || parts === 'time') {
        if (endDate) {
            //if timeFormat contains H or HH, we're using 24 hour clock and the logic is simpler
            //just format both times with the passed in format, if the first has AM or PM in it and showFirstAMPM is false, remove it. then merge the two times
            let startTime = format(date, dateFormats?.timeFormat ?? 'h:mm a');
            const endTime = format(endDate, (dateFormats?.timeFormat ?? 'h:mm a') + (withTimeZone ? ' zzz' : ''));
            const showFirstAMPM = (date.getHours() < 12 && endDate.getHours() >= 12) || (date.getHours() >= 12 && endDate.getHours() < 12);
            //console.debug('formatTimeRange', showFirstAMPM, startTime, endTime);
            if (!showFirstAMPM) {
                startTime = startTime.replace(' AM', '').replace(' PM', '');
            }
            result = result + startTime + ' - ' + endTime;
            //console.debug('formatTimeRange', showFirstAMPM, startTime, endTime, result);
            //result = result + format(date, showFirstAMPM ? 'h:mm' : 'h:mm a') + format(endDate, ' - h:mm a zzz');

        } else {
            result = result + format(date, `${dateFormats?.timeFormat ?? 'h:mm a'}${withTimeZone ? ' zzz' : ''}`);
        }
    }
    return result;
}


export const formatDate = (date?: Date, dateFormats?: IDateFormats | null, longDate: boolean = false, withTime: boolean = false, withDay: boolean = false, withTimeZone: boolean = true) => {
    //console.debug('formatDate', longDate, withTime, withDay, withTimeZone)
    if (date === undefined) return undefined;
    let formatString = (withDay ? 'eee, ' : '') + (longDate ? (dateFormats?.dateFormatLong ?? 'eeee, MMMM do yyyy') : (dateFormats?.dateFormatShort ?? 'MM/dd/yyyy'));
    //console.debug('formatDate', longDate, withTime, withDay, withTimeZone, formatString)
    if (withTime) {
        formatString = formatString + ', ' + dateFormats?.timeFormat ?? 'h:mm a';
        //return format(date, `${dateFormat} ${dateFormats?.timeFormat ?? 'h:mm a'} zzz`);
        if (withTimeZone) formatString = formatString + ' zzz'
    }

    return format(date, formatString);
}