import { makeAutoObservable, runInAction } from "mobx";
import RealTimeConnection from "app/common/realtime/realTimeConnection";
import { parseDate } from "app/common/utils";
import { ISessionQuestion } from "features/questions/model";
import apiclient from "./questionsApi";

export class SessionQuestionsStore {
    constructor() {
        makeAutoObservable(this);
    }

    realTimeConnection: RealTimeConnection | undefined = undefined;
    questions = new Map<string, ISessionQuestion>();//: ISessionQuestion[] = [];
    loading: boolean = false;
    submitting: boolean = false;
    realTimeGroup: string = '';

    getValidToken: (() => Promise<string | undefined>) | undefined;
    userName: string = '';

    get questionsByVotes() {
        let sortedQuestions = Array.from(this.questions.values()).filter(q => !q.answered).sort(
            (a: ISessionQuestion, b: ISessionQuestion) => (b.votes ?? 0) - (a.votes ?? 0)
            //(a, b) => b.createdOn.getTime() - a.createdOn.getTime()
        );

        return sortedQuestions;
    }

    get amsweredQuestions() {
        let sortedQuestions = Array.from(this.questions.values()).filter(q => q.answered).sort(
            (a: ISessionQuestion, b: ISessionQuestion) => (b.answeredOn?.getTime() ?? 0) - (a.answeredOn?.getTime() ?? 0)
            //(a, b) => b.createdOn.getTime() - a.createdOn.getTime()
        );

        return sortedQuestions;
    }

    initializeQuestions = async (getValidToken: () => Promise<string | undefined>, userName: string, groupId: string, sessionId: string) => {
        let realTimeGroup = `${sessionId}_questions`;
        if (this.realTimeGroup === realTimeGroup) return;

        this.getValidToken = getValidToken;
        this.userName = userName
        this.realTimeGroup = realTimeGroup;

        runInAction(async () => {
            this.realTimeConnection = new RealTimeConnection('questions', getValidToken, groupId, sessionId, this.onRealtimeReconnected);
            await this.realTimeConnection?.createHubConnection();
            await this.realTimeConnection?.joinRealTimeGroup(realTimeGroup);
            this.subscribeToRealtimeEvents();
        });
    }
    onRealtimeReconnected = async () => {
        if (this.realTimeConnection?.trace) console.debug('quesitonsStore', 'onRealtimeReconnected');
    }

    loadQuestions = async (groupId: string, sessionId: string) => {
        this.loading = true;
        this.questions.clear();// = [];
        try {
            let items = await apiclient.Questions.list(groupId, sessionId);
            runInAction(() => {
                this.loading = false;
                items.forEach(question => {
                    this.setQuestionProps(question, this.userName);
                    this.questions.set(question.id!, question);
                });
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    subscribeToRealtimeEvents = () => {
        this.realTimeConnection?.hubConnection?.on('ReceiveQuestion', question => {
            runInAction(() => {
                this.setQuestionProps(question, this.userName);
                this.questions.set(question.id!, question);
            })
        })

        this.realTimeConnection?.hubConnection?.on('ReceiveQuestionVote', question => {
            runInAction(() => {
                let localQuestion = this.questions.get(question.id);
                if (localQuestion) {
                    localQuestion.votes = question.votes;
                    this.questions.set(localQuestion.id!, localQuestion);
                } else {
                    this.questions.set(question.id!, question);
                }
            })
        })

        this.realTimeConnection?.hubConnection?.on('QuestionDeleted', questionId => {
            runInAction(() => {
                if (this.questions.has(questionId)) {
                    this.questions.delete(questionId);
                }
            })
        })

    }

    setQuestionProps = (question: ISessionQuestion, username?: string) => {
        question.submittedOn = parseDate(question.submittedOn);
        question.answeredOn = parseDate(question.answeredOn);
        question.isAuthor = username ? question.authorUsername === username : false;
        return question;
    }

    addQuestion = async (groupId: string, sessionId: string, questionText: string) => {
        this.submitting = true;
        try {
            const data: ISessionQuestion = {
                questionText: questionText
            };
            let question = await apiclient.Questions.add(groupId, sessionId, data);
            runInAction(() => {
                this.submitting = false;
                this.setQuestionProps(question, this.userName);
                this.questions.set(question.id!, question);
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    updateQuestion = async (groupId: string, sessionId: string, questionId: string, questionText: string) => {
        this.submitting = true;
        try {
            const data: ISessionQuestion = {
                questionText: questionText
            };
            let question = await apiclient.Questions.update(groupId, sessionId, questionId, data);
            runInAction(() => {
                this.submitting = false;
                this.setQuestionProps(question, this.userName);
                this.questions.set(question.id!, question);
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    updateQuestionReaction = (question: ISessionQuestion) => {
        this.questions.set(question.id!, question);
    }

    markQuestionAnswered = async (groupId: string, sessionId: string, questionId: string) => {
        const answerData: ISessionQuestion = {
            answered: true
        };
        await this.updateQuestionAnswer(groupId, sessionId, questionId, answerData)
    }

    updateQuestionAnswer = async (groupId: string, sessionId: string, questionId: string, answerData: ISessionQuestion) => {
        this.submitting = true;
        try {
            let question = await apiclient.Questions.update(groupId, sessionId, questionId, answerData);
            runInAction(() => {
                this.submitting = false;
                this.setQuestionProps(question, this.userName);
                this.questions.set(question.id!, question);
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    deleteQuestion = async (groupId: string, sessionId: string, questionId: string) => {
        this.loading = true;
        try {
            await apiclient.Questions.delete(groupId, sessionId, questionId);
            runInAction(() => {
                this.questions.delete(questionId);
                this.loading = false;

            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}

