import styled from "styled-components";
import { LoaderAnimation } from "./Animations";

export const Loader = styled.div<{ active?: boolean }>`
    display: ${props => props.active ? 'block' : 'none'};
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    text-align: center;
    z-index: 1000;
    transform: translateX(-50%) translateY(-50%);
    width: 2.28571rem;
    height: 2.28571rem;
    font-size: 1em;
    font-style: normal;

    //shapes
    &:before,
    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 50%;
        border-radius: 500rem;
        width: 2.28571rem;
        height: 2.28571rem;
        margin: 0 0 0 -1.14285rem;
    }

    //static shape
    &:before {
        border: 0.2em solid rgba(0,0,0,0.1);
    }

    //active shape
    &:after {
        animation: ${LoaderAnimation} 0.6s infinite linear;
        border: 0.2em solid #767676;
        box-shadow: 0 0 0 1px transparent;
        border-color: transparent;
        border-top-color: #767676;
    }

    &.fast:after {
        animation-duration: 0.3s;
    }
    &.slow:after {
        animation-duration: 0.9s;
    }
    &.indeterminate:after {
        animation-direction: reverse;
        animation-duration: 1.2s;
    }

    &.primary:after {
        border-top-color: var(--primaryColor);
    }
    &.secondary:after {
        border-top-color:var(--secondaryColor);
    }

    &.text {
        width:auto;
        height: auto;
        min-width: 2.28571rem;
        padding-top: 3.07142rem;
        color:#FFF;
    }
    &.primary.text {
        color: var(--primaryColor);
    }
    &.secondary.text {
        color:var(--secondaryColor);
    }
 
`