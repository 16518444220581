import { IPagination } from "@musicproworkshop/musicproworkshop.components";
import { makeAutoObservable } from "mobx";

export default class PagingHelper<T> {

    items = new Map();
    item: T | null = null;
    loadingItems = false;
    predicate = new Map();
    paging: IPagination = { itemsPerPage: 20, currentPage: 0, totalItems: 0, totalPages: 0 };
    sortOrder: string | null = null;
    loadItems: ((...params: any[]) => Promise<T[]>) | undefined;

    constructor(itemsPerPage: number = 20, loadItems?: ((...params: any[]) => Promise<T[]>) | undefined) {
        this.setItemsPerPage(itemsPerPage);
        this.loadItems = loadItems;
        makeAutoObservable(this);
    }

    setLoadItems(loadItems: (...params: any[]) => Promise<T[]>) {
        this.loadItems = loadItems;
    }

    get totalPages() {
        return Math.ceil(this.paging.totalItems / this.paging.itemsPerPage);
    }

    get hasMore() {
        return this.paging.currentPage < this.totalPages;
    }

    setPage = (page: number) => {
        this.paging.currentPage = page;
    }

    setItemsPerPage = (itemsPerPage: number) => {
        this.paging.itemsPerPage = itemsPerPage;
    }

    setPredicate = (predicate: string, value: string | Date, resetOthers?: boolean, reload?: boolean) => {
        //console.log('setPredicate', 'predicate', predicate, 'value', value, 'resetOthers', resetOthers);
        if (resetOthers)
            this.predicate.clear();
        else
            this.predicate.delete(predicate);

        this.predicate.set(predicate, value);

        if (reload) {
            this.reload();
        }
    }

    deletePredicate = (predicate: string, reload?: boolean) => {
        if (!this.predicate.has(predicate)) return;
        this.predicate.delete(predicate);

        if (reload) {
            this.reload();
        }
    }

    get searchParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', String(this.paging.currentPage));
        params.append('pageSize', String(this.paging.itemsPerPage));
        if (this.sortOrder) params.append('sortOrder', this.sortOrder);
        this.predicate.forEach((value, key) => {
            if (key === 'selectedDate') {
                params.append(key, value.toISOString())
            } else {
                params.append(key, value);
            }
        })
        return params;
    }

    reload = () => {
        this.items.clear();
        this.paging.currentPage = 1;
        if (this.loadItems) this.loadItems();
    }

    reset = () => {
        this.items.clear();
        this.predicate.clear();
        this.paging.currentPage = 1;
        this.paging.totalItems = 0;
    }
}