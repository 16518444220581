import styled from "styled-components";

export const FormElement = styled.form`
    position: relative;
    max-width: 100%;

    p {
        margin: 1em 0;
    }

    *:disabled {
        pointer-events: none;
        opacity: 0.45;
    }

    &.clearing:after {
        content:"";
        display:block;
        clear:both;
    }

`