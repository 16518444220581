import { createContext } from "react";
import { AccountStore } from "features/account/context/accountStore";
import { SessionListStore } from "features/sessionlist/context/sessionListStore";
import { SessionDetailsStore } from "features/sessiondetails/context";
import { TracksStore } from "features/tracks/context/tracksStore";
import { SessionQuestionsStore } from "features/questions/context/questionsStore";
import { PlayerStore } from "features/player/context/playerStore";
import { PhotosStore } from "features/photos/context/photosStore";
import { CommentsStore } from "features/comments/context/commentsStore";
import { GroupNewsStore } from "features/news/context/groupNewsStore";
import { GroupMembersStore } from "features/members/context/groupMembersStore";
import { GroupStore } from "features/groupdetails/context";
import { GroupContentItemsStore } from "features/contentitems/context/groupContentItemsStore";
import { ConferenceStore } from "features/conferences/context/conferencesStore";
import { ReactionsStore } from "features/reactions/context/reactionsStore";
import { GroupMemberSettingsStore } from "features/members/context/groupMemberSettingsStore";

export class RootStore {
    accountStore: AccountStore;
    groupStore: GroupStore;
    groupMemberSettingsStore : GroupMemberSettingsStore;
    sessionListStore: SessionListStore;
    sessionDetailsStore: SessionDetailsStore;
    groupMembersStore: GroupMembersStore;
    photosStore: PhotosStore;
    tracksStore: TracksStore;
    playerStore: PlayerStore;
    commentsStore: CommentsStore;
    sessionQuestionsStore: SessionQuestionsStore;
    groupNewsStore: GroupNewsStore;
    groupContentItemsStore: GroupContentItemsStore;
    conferenceStore: ConferenceStore;
    reactionsStore: ReactionsStore;

    constructor() {
        this.accountStore = new AccountStore();
        this.groupStore = new GroupStore();
        this.groupMemberSettingsStore = new GroupMemberSettingsStore();
        this.sessionListStore = new SessionListStore();
        this.sessionDetailsStore = new SessionDetailsStore();
        this.groupMembersStore = new GroupMembersStore();
        this.photosStore = new PhotosStore();
        this.tracksStore = new TracksStore();
        this.playerStore = new PlayerStore();
        this.commentsStore = new CommentsStore();
        this.sessionQuestionsStore = new SessionQuestionsStore();
        this.groupNewsStore = new GroupNewsStore();
        this.groupContentItemsStore = new GroupContentItemsStore();
        this.conferenceStore = new ConferenceStore();
        this.reactionsStore = new ReactionsStore();
    }

    resetAfterUserChange = (currentGroupId: string | null) => {
        this.groupStore.resetGroup();
        this.groupMemberSettingsStore.resetMember();
        this.sessionListStore.reset();
        if (currentGroupId) {
            this.groupStore.loadGroupSettings(currentGroupId, true);
            this.groupMemberSettingsStore.loadGroupMemberSettings(currentGroupId, true);
        }
    }

}

export const RootStoreContext = createContext(new RootStore());
