import React from 'react'
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useCurrentGroup } from 'features/groupdetails/context';

const PageMeta = ({ title, description, short, cardTitle, cardImageUrl }: { title?: string | string[], description?: string, short?: boolean, cardTitle?: string, cardImageUrl?: string }) => {
    const { groupSettings, branded, currentGroupId } = useCurrentGroup();
    //TODO: short should be boolean|'auto', when auto, decide if short based on length of passed in title. auto should also be the default
    let titleParts: string[] = [];
    if (title) titleParts = titleParts.concat(title);
    const groupTitle = (short || !groupSettings ? currentGroupId : groupSettings?.name);
    if (groupTitle) titleParts.push(groupTitle);
    if (!branded) titleParts.push('MusicProWorkshop.com');

    return (
        <Helmet>
            <title>{titleParts.filter(t => t).join(" - ")}</title>
            {description && <meta name='description' content={description} />}

            <meta name="twitter:card" content="summary_large_image" />
            {description && <meta name="twitter:description" content={description} />}
            {cardTitle && <meta property="og:site_name" content={cardTitle} />}
            {cardImageUrl && <meta name="og:image" content={cardImageUrl} />}
            {cardTitle && <meta name="twitter:title" content={cardTitle} />}
            {cardImageUrl && <meta name="twitter:image" content={cardImageUrl} />}

        </Helmet>
    )
}

export default observer(PageMeta)