import React from 'react'
import { SmartButtonProps } from '@musicproworkshop/musicproworkshop.components';
import { ButtonBase } from '.'

interface IProps extends SmartButtonProps {
    text: string;
}

export const TextButton = (props: IProps) => {
    return (
        <ButtonBase {...props} />
    )
}

export default TextButton
