import { makeAutoObservable, runInAction } from "mobx";
import { DisplayMode, ILiveSessionLayout } from 'features/livesession/layouts/model';
import { IGroupSession, SessionStatus } from "features/sessiondetails/model";
import { IGroupMemberSettings } from "features/members/model";
import { setSessionProps } from "features/sessiondetails/context/sessionHelpers";
import { ITrackSubmission } from "features/tracks/model";
import sessionDetailsApiClient from './sessionDetailsApi';

export class SessionDetailsStore {
    constructor() {
        makeAutoObservable(this);
    }

    groupId: string | undefined | null = null;
    item: IGroupSession | null = null;
    liveSessionLayout: ILiveSessionLayout | null = null;
    itemNotFound = false;
    upcomingSession: IGroupSession | null = null;
    loadingSession: boolean = false;
    updatingSessionState: boolean = false;

    //get sessionItem() { return this.item; }

    get recap() { return this.item?.status === SessionStatus.Ended; }

    get maxTracksPerUser() {
        return ((this.item?.maxTracksPerUser ?? 0) > 0 ? this.item?.maxTracksPerUser : 0) ?? 0;
    }

    get sortedAttendees() {
        if (!this.item?.attendees) return [];
        return this.item?.attendees.slice().sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    }

    loadSession = async (sessionId: string, memberSettings: IGroupMemberSettings, forceRefresh?: boolean, manage?: boolean): Promise<IGroupSession | null> => {
        this.groupId = memberSettings.groupId;
        this.itemNotFound = false;
        this.loadingSession = true;
        if (this.item?.id === sessionId && !forceRefresh) {
            return this.item;
        }
        else {
            try {
                let session = await sessionDetailsApiClient.details(this.groupId, sessionId, manage);
                runInAction(() => {
                    if (session) {
                        setSessionProps(session, memberSettings);
                        session.hasDetails = true;
                        this.item = session;
                    }
                    this.loadingSession = false;
                })
                return this.item;
            } catch (error: any) {
                runInAction(() => {
                    this.itemNotFound = error.response.status === 404;
                    this.loadingSession = false;
                })
                console.error('load session', 'error', error, 'status', error.response.status);
            }
        }
        return null;
    }

    loadLiveSessionLayout = async (sessionId: string, displayMode: DisplayMode) => {
        //console.log('loadLiveSessionLayout', 'sessionId', sessionId, 'groupId', this.groupId);
        if (!this.groupId) return null;
        try {
            const layout = await sessionDetailsApiClient.liveLayout(this.groupId, sessionId, displayMode);
            runInAction(() => {
                this.liveSessionLayout = layout;
            })
        } catch (error) {
            console.error('load live session layout', 'error', error);
        }

    }

    clearSession = () => {
        this.item = null;
        this.liveSessionLayout = null;
    }

    updateSessionState = async (status: SessionStatus, memberSettings: IGroupMemberSettings) => {
        if (!memberSettings) return;
        try {
            this.updatingSessionState = true;
            await sessionDetailsApiClient.updateStatus(memberSettings.groupId, this.item!.id, status);
            runInAction(async () => {
                await this.loadSession(this.item!.id, memberSettings, true);
                this.updatingSessionState = false;
            })
        } catch (error) {
            runInAction(async () => {
                this.updatingSessionState = false;
            })
        }
    }

    attendSession = async () => {
        if (!this.groupId || !this.item || this.item.attendedSession) return;
        try {
            await sessionDetailsApiClient.attend(this.groupId, this.item!.id);

        } catch (error) {
        }
    }

    updateSubmittedTrack = (trackId?: string, track?: ITrackSubmission) => {
        if (!this.item) return;
        //this.setTrackProps(track as IPlaylistTrack);
        const index = (trackId ? this.item.submittedTracks.findIndex(t => t.id === trackId) : -1) ?? -1;
        if (track && (!trackId || index < 0)) {
            //added (track was passed in with no id or id wasn't found)
            if (!this.item.submittedTracks) this.item.submittedTracks = [];
            this.item.submittedTracks.push(track);
            this.item.totalSessionSubmittedTracks += 1;
        } else {
            if (index >= 0) {
                if (track) {
                    //update
                    this.item.submittedTracks.splice(index, 1, track as ITrackSubmission);
                } else {
                    //delete
                    this.item.submittedTracks.splice(index, 1);
                    this.item.totalSessionSubmittedTracks -= 1;
                }
            }
        }
        //this.rootStore.groupSessionsStore.items.set(this.rootStore.groupSessionsStore.item.id, this.rootStore.groupSessionsStore.item);
    }

    loadUpcomingSession = async (liveOnly: boolean, memberSettings: IGroupMemberSettings, forceRefresh?: boolean): Promise<IGroupSession | null> => {
        this.groupId = memberSettings.groupId;
        //Check if asking for liveOnly and the upcomingSession isn't live
        forceRefresh = forceRefresh || (liveOnly && this.upcomingSession?.status !== SessionStatus.Live && this.upcomingSession?.status !== SessionStatus.PendingLive);
        if (this.upcomingSession && !forceRefresh) return this.upcomingSession;
        else {
            try {
                let session = await sessionDetailsApiClient.upcoming(this.groupId, liveOnly);
                runInAction(() => {
                    if (session) {
                        setSessionProps(session, memberSettings);
                        this.upcomingSession = session;
                    }
                })

                return session;
            } catch (error: any) {
                if (error.message !== 'Request failed with status code 404') {
                    console.error('loadUpcomingSession', 'error', error);
                }
            }
        }
        return null;
    }

    clearUpcomingSession = () => {
        this.upcomingSession = null;
    }
}