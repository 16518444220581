import { PaginatedResult, RestClient } from "@musicproworkshop/musicproworkshop.components";
import { IMusicSubmissionFormValues, IPlaylistTrack, ISubmissionInvite, ITrack, ITrackSubmission, ITrackSubmissionManage, TrackSubmissionType } from "../model";

const TracksApi = {

    saveTrack: (groupId: string, sessionId: string, formData: FormData, trackId?: string, inviteCode?: string, updateProgress?: (progressPercentage: number) => void): Promise<ITrackSubmission> => RestClient.postForm(`/groups/${groupId}/sessions/${sessionId}/tracks${trackId !== undefined ? '/' : ''}${trackId ?? ''}${inviteCode ? '?invite=' + inviteCode : ''}`, formData, updateProgress),
    updateTrackMetadata: (groupId: string, sessionId: string, trackId: string, track: IMusicSubmissionFormValues) => RestClient.put(`/groups/${groupId}/sessions/${sessionId}/tracks/${trackId}/metadata`, track),
    updateTrackSession: (groupId: string, sessionId: string, trackId: string) => RestClient.post(`/groups/${groupId}/sessions/${sessionId}/tracks/${trackId}/moveSession`, {}),
    deleteTrack: (groupId: string, sessionId: string, trackId: string, onlyFiles?: boolean) => RestClient.del(`/groups/${groupId}/sessions/${sessionId}/tracks/${trackId}${onlyFiles ? '?onlyFiles=true' : ''}`),
    playlist: (groupId: string, sessionId: string, manage: boolean): Promise<ITrackSubmission[] | ITrackSubmissionManage[]> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/playlist/${manage ? 'manage' : ''}`),
    updatePlaylist: (groupId: string, sessionId: string, playlist: ITrack[]) => RestClient.put(`/groups/${groupId}/sessions/${sessionId}/playlist/manage`, playlist),
    mytracksubmissions: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<ITrack[]>> => RestClient.search(`/groups/${groupId}/trackSubmissions`, params),
    trackDetails: (groupId: string, sessionId: string, trackId: string): Promise<ITrack> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/tracks/${trackId}`),
    updateTrackTimestamp: (groupId: string, sessionId: string, trackId: string, timeStamp: any): Promise<IPlaylistTrack> => RestClient.put(`/groups/${groupId}/sessions/${sessionId}/tracks/${trackId}/timestamp`, timeStamp),
    invites: (groupId: string, sessionId: string): Promise<ISubmissionInvite[]> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/invites`),
    createInvite: (groupId: string, sessionId: string, invite: ISubmissionInvite): Promise<ISubmissionInvite> => RestClient.post(`/groups/${groupId}/sessions/${sessionId}/invites`, invite),
    deleteInvite: (groupId: string, sessionId: string, inviteId: string) => RestClient.del(`/groups/${groupId}/sessions/${sessionId}/invites/${inviteId}`),
    updateInvite: (groupId: string, sessionId: string, inviteId: string, invite: ISubmissionInvite): Promise<ISubmissionInvite> => RestClient.put(`/groups/${groupId}/sessions/${sessionId}/invites/${inviteId}`, invite),
    checkInvite: (groupId: string, sessionId: string, inviteId: string): Promise<TrackSubmissionType> => RestClient.get(`/groups/${groupId}/sessions/${sessionId}/invites/${inviteId}`),

}

export default TracksApi;