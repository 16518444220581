import React from 'react'
import { IconName, IconPrefix } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkOptionType } from '../options';

interface IProps {
    iconType: string;
    size?: 'big' | 'large';
    showText?: boolean;
    inverted?: boolean;
    color?: string;
    linkOptions: LinkOptionType[];
}
const LinkIcon = ({ iconType, showText, inverted, color, linkOptions }: IProps) => {
    const iconOption = linkOptions.find(x => x.value === iconType);
    if (!iconOption) return <></>;
    let iconColor = (color ?? iconOption!.color) ?? 'siteDefault';
    if (iconColor === 'siteDefault') {
        if (inverted)
            iconColor = 'white';
        else
            iconColor = 'var(--siteTextColor)';
    }


    //const iconSize = size === 'big' ? '2x' : '2x';
    let icon = iconOption!.iconName ?? iconOption!.value;
    let collection = (iconOption!.collection ?? (icon === 'link' ? 'fas' : 'fab')) as IconPrefix; //if there are more icons from fas, it should be another prop for prefix on LinkOptionType
    const iconName: IconName = String(icon) as IconName;

    return (
        <div style={showText ? { display: 'grid', gridTemplateColumns: '40px auto', alignItems: 'center', columnGap: '5px' } : {}}>
            {iconOption!.value && <FontAwesomeIcon icon={[collection, iconName]} size='2x' color={iconColor} title={iconOption!.label ? iconOption!.label as string : ''} />}
            {showText && <span>{iconOption!.label}</span>}

        </div>
    )
}

export default LinkIcon
