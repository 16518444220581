import { parseDate } from "app/common/utils";
import { differenceInCalendarDays, differenceInCalendarWeeks, format, formatDistanceToNow, formatDistanceToNowStrict, isSameMonth, isSameWeek } from "date-fns";
import { IGroupMemberSettings } from "features/members/model";
import { IGroupSession, SessionFeatures, SessionStatus, SessionVisibility } from "features/sessiondetails/model";
import { TrackSubmissionType } from "features/tracks/model";

export const setSessionProps = (session: IGroupSession, memberSettings: IGroupMemberSettings | null) => {
    session.loadedOn = new Date();
    session.date = parseDate(session.date)!;
    session.endDate = parseDate(session.endDate);
    session.publishOn = parseDate(session.publishOn);
    session.submissionDeadline = parseDate(session.submissionDeadline);
    session.videoStreamStartTime = parseDate(session.videoStreamStartTime);
    if (session.firstTrack) {
        session.firstTrack.playbackStarted = parseDate(session.firstTrack.playbackStarted)!;
    }

    let location = ''
    if (session.onlineUrl?.length > 0) {
        location = "Online"
    }
    if (session.inPersonAddress?.length > 0) {
        if (location.length > 0) location += ' & ';
        location += 'In Person'
    }
    if (location.length > 0) location += ' Session';
    session.location = location;

    session.isMember = memberSettings?.isMember ?? false;
    session.isLeader = memberSettings?.isLeader ?? false;
    session.canManage = memberSettings?.canManage ?? false;

    session.showRecap =
        (sessionHasFeature(session, SessionFeatures.InSyncPlayer) ||
            sessionHasFeature(session, SessionFeatures.VideoStream) ||
            session.recapLink?.length > 0)
        && (session.isLeader || session.isHost ||
            session.recapVisibility === SessionVisibility.AllMembers ||
            (session.recapVisibility === SessionVisibility.Attendees && session.attendedSession));

    const now = new Date();
    session.published = session.publishOn ? session.publishOn < now : true;
    var sessionStartsIn = (session.date.getTime() - now.getTime()) / 1000 / 60;
    var sessionEndsIn = session.endDate ? (session.endDate?.getTime() - now.getTime()) : 0;
    if (session.status === undefined || session.status === SessionStatus.None) {
        if (sessionEndsIn < 0 || (sessionEndsIn === 0 && sessionStartsIn < 0))
            session.status = SessionStatus.Ended;
        else// if (sessionStartsIn >= 0)
            session.status = SessionStatus.Upcoming;
    }

    if (session.status === SessionStatus.Upcoming && sessionStartsIn <= 15 && sessionStartsIn > -60) {
        session.status = SessionStatus.PendingLive;
    } //else if (session.state !== SessionState.Past && sessionEndsIn<60) { } //An hour after a session was supposed to end, if it wasn't ended manually
    //console.log('session', session.id, 'state', session.status, 'starts in', sessionStartsIn)
    session.startsIn = getSessionStartsIn(session, sessionStartsIn);
    session.group = getSessionStartsIn(session);


    session.slotsLeft = (session.maxTracks ?? 0) > 0 ? (session.maxTracks ?? 0) - session.totalSessionSubmittedTracks : 0;

    //TODO: update with other features that turn "usesLivePage" on
    session.usesLivePage = sessionHasFeature(session, SessionFeatures.InSyncPlayer) || sessionHasFeature(session, SessionFeatures.VideoStream);

    return session;
}

//If session is today/tonight, tomorrow, in 2 days or in 3 days. Based on date, not minutes away
//TODO: Check session start time, if it's PM it should be Tonight not Today. Also, return Next Week
function timeLabel(date: Date, sessionStartsIn?: number) {
    const now = new Date();
    const days = differenceInCalendarDays(date, now);
    if (days === 0) {
        if (!sessionStartsIn) return "Today";
        if (sessionStartsIn < 60) {
            return formatDistanceToNow(date, { addSuffix: true });
        } else {
            return formatDistanceToNowStrict(date, { unit: "hour", roundingMethod: "floor", addSuffix: true });
        }
    } else if (days === 1) {
        return "Tomorrow";
    } else if (days === -1) {
        return 'Yesterday';
    } else if (isSameWeek(now, date)) {
        if (!sessionStartsIn) return "This Week";
        return formatDistanceToNowStrict(date, { unit: "day", roundingMethod: "ceil", addSuffix: true });
    } else if (differenceInCalendarWeeks(date, now) === 1) {
        if (!sessionStartsIn) return "Next Week";
        return "Next " + format(date, 'EEEE');
    } else if (days > 0 && isSameMonth(now, date)) {
        return "This Month";
    } else if (days > 0) {
        return "Later";
    } else if (days < 0) {
        return "Earlier";
    }
    return '';//should never get here
}

function getSessionStartsIn(session: IGroupSession, sessionStartsIn?: number) {
    switch (session.status) {
        case SessionStatus.Ended:
            return "Ended";
        case SessionStatus.PendingLive:
            return "About to Start";
        case SessionStatus.Live:
            return "Live Now";
        default:
            return timeLabel(session.date, sessionStartsIn);
    }
}

export function sessionHasFeature(session: IGroupSession | undefined | null, feature: SessionFeatures): boolean {
    if (!session) return false;
    return (session.sessionFeatures & feature) === feature;
}

export function hasSessionFeature(sessionFeatures: SessionFeatures | undefined | null, feature: SessionFeatures): boolean {
    return ((sessionFeatures ?? 0) & feature) === feature;
}

export function sessionAllowedSubmissionType(session: IGroupSession | undefined | null, submissionType: TrackSubmissionType): boolean {
    if (!session || !session.allowedSubmissionTypes || session.status === SessionStatus.Ended) return false;
    //TODO: Check group features here as well?
    return (session.allowedSubmissionTypes & submissionType) === submissionType;
}