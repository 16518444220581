import { IGroupMemberSettings } from "features/members/model";
import { IUser } from "features/account/model/user";
import { VisibilityMode } from ".";

export const isVisible = (visibilityMode?: VisibilityMode, user?: IUser | null, memberSettings?: IGroupMemberSettings | null): boolean => {
    if (!visibilityMode) return false;
    //console.log('isVisible', visibilityMode)

    switch (visibilityMode) {
        case VisibilityMode.Anonymous:
            //console.log('isVisible', visibilityMode, 'anon')
            return true;
        case VisibilityMode.Users:
            //console.log('isVisible', visibilityMode, 'user', user)
            return user !== null;
        case VisibilityMode.GroupMembers:
            //console.log('isVisible', visibilityMode, 'member', group?.isMember)
            return memberSettings?.isMember ?? false;
        case VisibilityMode.Organizers:
            //console.log('isVisible', visibilityMode, 'member', group?.isLeader)
            return memberSettings?.isLeader ?? false;
    }

    return false;
}