import { runInAction, makeAutoObservable } from "mobx";
import PagingHelper from "app/common/paging/pagingHelper";
import { parseDate } from "app/common/utils";
import { IGroupNewsItem } from "features/news/model";
import newsApiClient from "./newsApi";

export class GroupNewsStore {
    constructor() {
        if (!this.data)
            this.data = new PagingHelper(10, this.loadItems);
        else
            this.data.setLoadItems(this.loadItems)
        makeAutoObservable(this);
    }

    data: PagingHelper<IGroupNewsItem> = new PagingHelper(10);

    groupId: string | null = null;

    initializeNewsStore = (groupId?: string | null) => {
        this.groupId = groupId ?? null;
    }

    get newsItems() {
        if (!this.data.items) return [];
        return Array.from(this.data.items.values())
    }

    get newsItem() { return this.data.item; }

    loadItems = async (): Promise<IGroupNewsItem[]> => {
        if (!this.groupId) return [];
        this.data.loadingItems = true;
        try {
            const pagedData = await newsApiClient.list(this.groupId, this.data.searchParams);
            const { data, pagination } = pagedData;

            runInAction(() => {
                data.forEach(newsItem => {
                    this.setNewsItemProps(newsItem)
                    this.data.items.set(newsItem.id, newsItem);
                });
                this.data.paging = pagination;
                this.data.loadingItems = false;
                return data;
            })
        } catch (error: any) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            console.error(error.response);
        }
        return [];
    }

    loadNewsItem = async (itemId: string) => {
        if (!this.groupId) return;

        //TODO: check local items first
        this.data.loadingItems = true;
        try {
            const item = await newsApiClient.details(this.groupId, itemId);
            runInAction(() => {
                this.setNewsItemProps(item);
                this.data.item = item;
                this.data.loadingItems = false;
            })
            return item;
        } catch (error: any) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            console.error(error.response);
        }
        return null;
    }


    setNewsItemProps = (newsItem: IGroupNewsItem) => {
        newsItem.createdOn = parseDate(newsItem.createdOn);
        newsItem.publishOn = parseDate(newsItem.publishOn);
        newsItem.hideOn = parseDate(newsItem.hideOn);
        return newsItem;
    }
}

