import { RootStore } from "app/stores/rootStore";
import React, { createContext, ReactNode, useContext } from "react";

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

export function useRootStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider");
    }

    return context;
}

export function useAccountStore() {
    const { accountStore } = useRootStore();
    return accountStore;
}

export function useGroupStore() {
    const { groupStore } = useRootStore();
    return groupStore;
}

export function useGroupMemberSettingsStore() {
    const { groupMemberSettingsStore } = useRootStore();
    return groupMemberSettingsStore;
}
export function useGroupMembersStore() {
    const { groupMembersStore } = useRootStore();
    return groupMembersStore;
}

export function useSessionListStore() {
    const { sessionListStore } = useRootStore();
    return sessionListStore;
}

export function useSessionDetailsStore() {
    const { sessionDetailsStore } = useRootStore();
    return sessionDetailsStore;
}

export function useCommentsStore() {
    const { commentsStore } = useRootStore();
    return commentsStore;
}

export function useTracksStore() {
    const { tracksStore } = useRootStore();
    return tracksStore;
}

export function usePlayerStore() {
    const { playerStore } = useRootStore();
    return playerStore;
}

export function useContentItemsStore() {
    const { groupContentItemsStore } = useRootStore();
    return groupContentItemsStore;
}

export function useNewsStore() {
    const { groupNewsStore } = useRootStore();
    return groupNewsStore;
}

// export function use() {
//     const { } = useRootStore();
//     return;
// }

export function useConferenceStore() {
    const { conferenceStore } = useRootStore();
    return conferenceStore;
}

function initializeStore(): RootStore {
    const _store = store ?? new RootStore();

    //For server side rendering, always create a new store
    if (typeof window === "undefined") return _store;

    // Create the store once in the client
    if (!store) store = _store;
    return _store;
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
    // only create root store once (store is a singleton)
    const root = initializeStore();

    return <StoreContext.Provider value={root}> {children} </StoreContext.Provider>;
}
