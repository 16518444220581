import { formatTime } from "app/common/utils";
import { IMusicFile, IPlaylistTrack, ITrack, ITrackFile, TrackSubmissionState, TrackSubmissionType } from ".";

export interface ITrackSubmission extends IPlaylistTrack {
    submittedOn: Date;
    lastProcessingUpdate: Date;
    processingState: TrackSubmissionState;
    memberDisplayName: string;
    hasUnseenComments?: boolean;
}

export interface ITrackSubmissionManage extends ITrackSubmission {
    deletedUser: boolean;
    memberSince: Date;
    attendedSessions: number;
    totalSubmittedTracks: number;
    lastSubmittedOn: Date;
}

export interface ITrackSubmissionFile extends ITrackFile {
    currentFileName?: string;
    musicFile?: IMusicFile;
    file?: File;
    peaks?: any
    duration?: number;
    lastProcessingUpdate: Date;
    processingState: TrackSubmissionState;
}

export interface ITrackSubmissionFormValues extends Partial<ITrackSubmissionFile> {
    duration?: number;
    deleted?: boolean;
}

export class TrackSubmissionFormValues implements ITrackSubmissionFormValues {
    id?: string = undefined;
    currentFileName?: string = '';
    musicFile?: IMusicFile = undefined;
    file?: File = undefined;
    duration?: number = 0;
    peaks?: any = undefined;
    partNumber?: number = 1;
    partName?: string = '';
    deleted?: boolean = false;
}

export interface IMusicSubmissionFormValues extends Partial<IPlaylistTrack> {
    coverPhoto?: string;
    videoStreamPlaybackStart?: string;
    files?: ITrackSubmissionFormValues[];
    duration?: number; //for video
}


export class MusicSubmissionFormValues implements Partial<IMusicSubmissionFormValues> {
    artist = '';
    title?: string = '';
    lyrics?: string = '';
    url?: string = '';
    files?: TrackSubmissionFormValues[] = [{ musicFile: undefined, file: undefined, duration: 0, peaks: undefined, partNumber: 1, partName: '' }];
    duration = 0;// for video
    memberUsername?: string = '';
    memberDisplayName?: string = '';
    releaseNotes?: string = '';
    coverPhoto = '{}';
    submissionType?: TrackSubmissionType;
    videoStreamPlaybackStart?: string = '0:00';
    hidden = false;
    commentsEnabled = false;

    constructor(init?: IMusicSubmissionFormValues | ITrack | IPlaylistTrack) {
        Object.assign(this, init);
        if (!init) return;

        if ("files" in init) {
            const files = (init.files as ITrackFile[]);
            this.files = [];
            files.sort((a, b) => (a.partNumber ?? 1) - (b.partNumber ?? 1)).forEach(file => {
                this.files?.push({
                    id: file.id,
                    currentFileName: file.fileName,
                    partNumber: file.partNumber,
                    partName: file.partName,
                    musicFile: { fileName: file.fileName }
                });
            });
        }
        if ("playbackStartedSeconds" in init) {
            this.videoStreamPlaybackStart = formatTime(init.playbackStartedSeconds);
            //console.log('track form', init, this.videoStreamPlaybackStart, init.playbackStartedSeconds);
        }
        if (init?.trackCover) this.coverPhoto = JSON.stringify(init?.trackCover);

    }

}
