import React from 'react'
import { AddToCalendar } from '@musicproworkshop/musicproworkshop.components'
import { externalLink, sessionLink } from 'app/common/utils'
import { IGroupSession } from 'features/sessiondetails/model'
import { useCurrentGroup } from 'features/groupdetails/context'
import { marked } from 'marked'

interface IProps {
    session: IGroupSession;
    tabName?: string;
}
export const AddSessionToCalendar = ({ session, tabName = '' }: IProps) => {
    const { groupSettings, branded } = useCurrentGroup();
    const sessionRootUrl = externalLink(sessionLink(branded, groupSettings!, tabName, session.id));
    const description = marked.parse(session.description, {async:false}) as string;

    return (
        <AddToCalendar calendarEvent={{ id: session.id, title: `${session.title} (${groupSettings?.name})`, location: `${sessionRootUrl}/player`, startTime: session.date, endTime: session.endDate!, description: description }} />
    )
}

export default AddSessionToCalendar