import styled from 'styled-components';

export const ModalStyled = styled.div`
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 510;
	overflow-y: auto;
`