import { IGroupSettings } from "features/groupdetails/model";
import { SiteFeatures } from "features/plans/model";

export function groupHasFeature(group: IGroupSettings | undefined | null, feature: SiteFeatures): boolean {
    if (!group) return false;
    return (group.enabledFeatures & feature) === feature;
}

export function hasSessionFeature(siteFeatures: SiteFeatures | undefined | null, feature: SiteFeatures): boolean {
    return ((siteFeatures ?? 0) & feature) === feature;
}