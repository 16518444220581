import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IGroupNewsItem } from 'features/news/model';

const GroupNewsApi = {
    list: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupNewsItem[]>> => RestClient.search(`/groups/${groupId}/news`, params),
    details: (groupId: string, newsItemId: string): Promise<IGroupNewsItem> => RestClient.get(`/groups/${groupId}/news/${newsItemId}`),
    create: (groupId: string, newsItem: IGroupNewsItem): Promise<IGroupNewsItem> => RestClient.post(`/groups/${groupId}/news`, newsItem),
    update: (groupId: string, newsItemId: string, newsItem: IGroupNewsItem): Promise<IGroupNewsItem> => RestClient.put(`/groups/${groupId}/news/${newsItemId}`, newsItem),
    sticky: (groupId: string, newsItemId: string) => RestClient.post(`/groups/${groupId}/news/${newsItemId}/sticky`, {}),
    delete: (groupId: string, newsItemId: string) => RestClient.del(`/groups/${groupId}/news/${newsItemId}`),
    undelete: (groupId: string, newsItemId: string) => RestClient.post(`/groups/${groupId}/news/${newsItemId}`, {}),
}

export default GroupNewsApi;