import styled from 'styled-components';
export const ModalContentContainer = styled.div`
	position: fixed;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
	max-width: 100%;
	z-index: 510;
	transform: none;
	transition-property: transform;
	transition-duration: 350ms;
	transition-timing-function: cubic-bezier(0.15, 1, 0.3, 1);
	
	@media (min-width: 480px) {
		top: 60px;
		left:0px;
		right: 0px;
		margin-left: auto;
		margin-right: auto;
		max-width: calc(100vw - 120px);
		max-height: calc(100vh - 119px);
		pointer-events: none;
		width: max-content;
		position: absolute;

        &.mini, &.tiny {
        top:30%;
        }
        &.small {
            top:20%;
        }
        &.large {
            top: 10%;
            //max-height:90%;        
            max-height: calc(100vh - 9.1rem);
        }        
	}
`