import styled from "styled-components";

export const Divider = styled.div`
    margin:1rem 0;
    line-height: 1;
    height:0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing:0.05em;
    color:rgba(0, 0, 0, 0.85);
    user-select: none;
    font-size: 1rem;
    
    &:not(.vertical):not(.horizontal) {
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        border-bottom:1px solid rgba(255, 255, 255, 0.1);
    }

    &.primary {
        border-color: var(--primaryColor) !important;
        color: var(--primaryColor)} !important;
    }
    &.secondary {
        border-color: var(--secondaryColor) !important;
        color: var(--secondaryColor) !important;
    }
    &.spacer {
        border-color: transparent !important;        
    }

    &.horizontal {
        display: table;
        white-space:nowrap;
        height: auto;
        line-height:1;
        text-align:center;
        margin: '';

        &:before, 
        &:after {
            content:'';
            display: table-cell;
            position: relative;
            top:50%;
            width:50%;
            background-repeat: no-repeat;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC');
        }
        &:before {
            background-position: right 1em top 50%;
        }
        &:after {
            background-position: left 1em top 50%;
        }
    }
    /* &.horizontal.secondary:before,
    &.horizontal.secondary:after {
        
        filter:sepia(100%) saturate(10000%) hue-rotate(45deg);
    } */

    &.vertical {
        position: absolute;
        z-index:2;
        top:50%;
        left:50%;
        margin:0;
        padding:0;
        width:auto;
        height:50%;
        line-height:0;
        text-align:center;
        transform:translateX(-50%);
        &:before, 
        &:after {
            position: absolute;
            left:50%;
            content:'';
            z-index:3;
            border-left:1px solid rgba(34, 36, 38, 0.15);
            border-right:1px solid rgba(255, 255, 255, 0.1);
            width:0;
            height:calc(100% - 1rem);
        }
        &:before {
            top:-100%;
        }
        &:after {
            top:auto;
            bottom:0;
        }
    }
    &.inverted{
        color:#FFF;
    }

    &.inverted,
    &.inverted:before,
    &.inverted:after
     {
        border-top-color:rgba(34, 36, 38, 0.15) !important;
        border-left-color:rgba(34, 36, 38, 0.15) !important;
        border-bottom-color:rgba(255, 255, 255, 0.75) !important;
        border-right-color:rgba(255, 255, 255, 0.75) !important;
    }

    &.midpage {

        margin-top: 4rem;
    }
    
`;