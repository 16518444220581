import styled from 'styled-components';

export const ModalContent = styled.section.attrs({ role: "dialog" })`
    background: var(--segmentBackgroundLighterColor);
	text-align: left;
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
    transform-origin: 50% 25%;
	display: flex;
	flex-direction: column;
    flex: 0 0 auto;
	pointer-events: auto;
	height: 100%;
	width: 100%;
	max-height: 100vh;
	max-width: 100vw;
    border: 2px solid var(--borderColor);
	border-radius: 0.28571rem;
    user-select: text;
    will-change: top, left, margin, transform, opacity;
    align-self: center;
    left:0;
    right: 0;
    font-size: 1rem;
    
    .body > :first-child {
        padding: 1em 2em 2em;
    }
    &.large {
        overflow-y: auto;
    }

    @media only screen and (max-width: 767.98px) {
        width: 95%;
        //&.mini, &.tiny, &.small, &.large {width: 95%;}
    }
    @media only screen and (min-width: 768px) {
        width: 88%;
        &.mini {width: 35.2% }
        &.tiny {width: 52.8% }
        &.small {width: 70.4%;}
        &.large {width: 88%;}
    }
    @media only screen and (min-width: 992px) {
        width: 850px;
        &.mini {width: 340px;}
        &.tiny {width: 510px;}
        &.small {width: 680px;}
        &.large {width: 1020px;}
    }
    @media only screen and (min-width: 1200px) {
        width: 900px;
        &.mini {width: 360px;}
        &.tiny {width: 540px;}
        &.small {width: 720px;}
        &.large {width: 1080px;}
    }
    @media only screen and (min-width: 1920px) {
        width: 950px;
        &.mini {width: 380px;}
        &.tiny {width: 570px;}
        &.small {width: 760px;}
        &.large {width: 1140px;}
    }
	@media (min-width: 480px) {
		height: auto;
		width: 600px;
		max-height: inherit;
		max-width: inherit;
		margin-left: inherit;
		margin-right: inherit;
		
	}
`;
