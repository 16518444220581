import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useField } from 'formik'
import NiceModal from '@ebay/nice-modal-react';
import { FormInputContainer, FormInputContainerProps, Image } from '@musicproworkshop/musicproworkshop.components';
import { IPhoto } from 'features/photos/model/photo';
import { PhotosManagerModal } from 'features/photos/views/PhotosManager';

export type PhotoInputOwnProps = {
    photoType: string;
    belongsToId: string;
    /**
     * The aspect ratio to crop the image to
     */
    aspectRatio?: number;

    name: string;
    disabled?: boolean | undefined;
};

export type PhotoInputProps = PhotoInputOwnProps & HTMLAttributes<HTMLElement> & Omit<FormInputContainerProps, "error" | "touched">;

export const PhotoInput = ({ photoType, belongsToId, aspectRatio, ...props }: PhotoInputProps) => {
    const [field, meta, helpers] = useField(props.name);
    const [photoValue, setPhotoValue] = useState<IPhoto>(field.value ? JSON.parse(field.value) : { url: undefined });

    useEffect(() => {
        setPhotoValue(JSON.parse(field.value));
    }, [field.value]);

    const handleOnPictureSelected = (photo: IPhoto) => {

        if (typeof field.value === 'string' || field.value === undefined) {
            const newValue = JSON.stringify({ id: photo.id, url: photo.url, usageCount: 0 });
            helpers.setValue(newValue);
        } else {
            helpers.setValue(photo);
        }

        helpers.setTouched(true);
        NiceModal.hide(PhotosManagerModal);

    }

    const showPhotoManageModal = () => {
        NiceModal.show('photoManagerModal');
    }

    return (
        <FormInputContainer {...props} disabled={props.disabled} touched={meta.touched} error={meta.error}>
            <Image size='medium' src={(photoValue?.url ?? field.value?.url) || '/assets/placeholder.png'} onClick={showPhotoManageModal} />
            <PhotosManagerModal id='photoManagerModal' props={{
                    title: 'Select Photo',
                    addButtonCaption: 'Add New Photo',
                    canEdit: true,
                    photoType: photoType,
                    belongsToId: belongsToId,
                    aspectRatio: aspectRatio,
                    selectedPhoto: photoValue ?? field.value,
                    onPhotoSelected: handleOnPictureSelected,
                    
                }} size='large'/>
        </FormInputContainer>
    )
}

export default PhotoInput
