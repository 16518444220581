import styled from "styled-components";

export const BackgroundImage = styled.div<{ imageUrl: string, size: 'normal' | 'large' }>`
    background-image: url(${props => (props.imageUrl ? props.imageUrl : '')});
    background-position: right center;
    background-repeat: initial;
    background-size: cover;
    height:100%;// ${props => props.size === 'normal' ? '120%' : '200%'};
    width:100%;// ${props => props.size === 'normal' ? '120%' : '200%'};
    position: absolute !important;
    top:0;
    filter: blur(10px) brightness(50%);
    transform: scale(1.1);

    @media (max-width: 767px){
        background-position: 25% 50%;
        //height:120%
    }
`;