export enum SessionFeatures {
    //None = 0,
    InSyncPlayer = 1 << 0,
    ShowPlaylist = 1 << 1,
    MusicSubmission = 1 << 2,
    InSyncComments = 1 << 3,
    AllowCommentReplies = 1 << 4,
    Questions = 1 << 5,
    //Polls = 1 << 6,
    VideoStream = 1 << 7,
    //VideoSubmission = 1 << 8,
    TrackReleaseInfo = 1 << 9,
    //MultiTrack = 1 << 10, //Commented until regular members are allowed to submit multi-tracks
    PreSessionPage = 1 << 11,
    //All = ~(~0 << 4)
}