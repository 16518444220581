import styled from "styled-components"

const CardImage = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
`
export const CardImageLeft = styled(CardImage)`
    border-radius: 0.27rem 0 0 0.27rem;
`

export const CardImageTop = styled(CardImage)`
    border-radius: 0.27rem 0.27rem 0 0;
    
`

export const Card = styled.div<{ selected: boolean }>`
    position:relative;
    display:flex;
    flex-direction:column;
    max-width:100%;
    min-height:0;
    margin:0.875em 0.5em;
    padding:0;
    border:none;
    border-radius:0.28571rem;
    float:none;
    word-wrap:break-word;
    color: ${props => props.selected ? 'var(--primaryColor)' : 'var(--segmentTextColor)'};
    box-shadow:0 1px 3px 0, 0 0 0 1px;
    transition: box-shadow 0.1s ease, transform 0.1s ease;
    transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, top 0.1s ease 0s;
    background: var(--segmentBackgroundLighterColor);
    text-decoration:none;
    z-index: '';

    &.basic {
        background: var(--segmentBackgroundColor);
        box-shadow:none;
    }

    /* &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    } */
    & > :first-child {
        border-radius: 0.28571rem 0.28571rem 0 0 !important;
        border-top: none !important;        
    }
    & > :last-child {
        border-radius: 0 0 0.28571rem 0.28571rem !important;
    }
    & > :only-child {
        border-radius: 0.28571rem !important;
    }
    
    &:hover {
        text-decoration:none;
    }

    &.primary:not(.inverted) {
        background-color: var(--primaryColor);
    }

    &.secondary:not(.inverted) {
        background-color:var(--secondaryColor);
    }

    &.raised {
        box-shadow: 0 0 0 1px #D4D4D5, 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
    }
    &.raised:hover {
        box-shadow: 0 0 0 1px #D4D4D5, 0 2px 4px 0 rgba(34, 36, 38, 0.15), 0 2px 10px 0 rgba(34, 36, 38, 0.25);
    }
    &.link {
        transform: none;
    }
    &.link:hover {
        cursor: pointer;
        z-index: 5;
        border: none;
        box-shadow: 0 1px 3px 0 #BCBDBD, 0 0 0 1px #D4D4D5;
        transform: translateY(-3px);        
    }


    .content {
        flex-grow: 1;
        border:none;
        border-top: 1px solid rgba(34, 36, 38, 0.1);
        background:none;
        margin:0;
        padding:1em;
        box-shadow:none;
        font-size:1em;
    }

    * > .header {
        display: block;
        font-weight: bold;
        font-size:1.3em;
        line-height:1.25em;
        font-family:'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        color: var(--segmentTextColor);
        white-space: nowrap;
        overflow-x: hidden;
    }

    * > .meta {
        font-size:1.15em;
        margin-top:-0.21425em;
        padding:0.2em 0;
        color: var(--segmentSubtleTextColor);
    }
`;

export const Cards = styled.div<{ itemsPerRow?: number }>`
    display:flex;
    flex-wrap:wrap;
    margin: -0.875em -0.75em;

    &.centered {
        justify-content: center;
    }
    &.centered > ${Card} {
        margin-left: auto;
        margin-right: auto;        
    }

    ${Card} {
        width:calc(${props => (100 / (props.itemsPerRow ?? 5)) + '%'} - 1em);
        font-size: ${props => (props.itemsPerRow ?? 5) > 8 ? '10px' : '1em'};
    }

    &::after,
    ${Card}::after {
        display: block;
        content: ' ';
        height: 0;
        clear: both;
        overflow: hidden;
        visibility: hidden;        
    }
`;