import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from 'app/stores/useRootStore';

const LogOut = () => {
    const { logout } = useAccountStore();
    let navigate = useNavigate();

    useEffect(() => {
        logout().then(() => navigate('/', { replace: true }));
    }, [logout, navigate])

    return (
        <div>
            Logging out...
        </div>
    )
}

export default LogOut
