import { RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IUser, IUserDetails, IUserFormValues } from 'features/account/model/user';

const AccountApi = {
    current: (): Promise<IUser> => RestClient.get('/account'),
    login: (user: IUserFormValues): Promise<IUser> => RestClient.post('/account/login', user),
    register: (user: IUserFormValues, groupId?: string | null): Promise<IUser> => RestClient.post(`/account/register${groupId ? '?groupId=' + groupId : ''}`, user),
    details: (): Promise<IUserDetails> => RestClient.get('/account/me'),
    update: (user: IUserFormValues): Promise<IUser> => RestClient.put('/account', user),
    delete: () => RestClient.del('/account'),
    refreshToken: (): Promise<IUser> => RestClient.post<IUser>('/account/refreshToken', {}),
    verifyEmail: (token: string, email: string) => RestClient.put(`/account/email?token=${token}&email=${email}`, {}),
    resendEmailConfirmation: (email: string, groupId?: string | null) => RestClient.get(`/account/email?email=${email}${groupId ? '&groupId=' + groupId : ''}`),
    changeEmail: (user: IUserFormValues): Promise<IUserDetails> => RestClient.post(`/account/email`, user),
    forgotPassword: (email: string, groupId?: string | null) => RestClient.del(`/account/password?email=${email}${groupId ? '&groupId=' + groupId : ''}`),
    resetPassword: (user: IUserFormValues, groupId?: string | null): Promise<IUser> => RestClient.post(`/account/password${groupId ? '?groupId=' + groupId : ''}`, user),
    changePassword: (user: IUserFormValues) => RestClient.put('/account/password', user),
}

export default AccountApi;