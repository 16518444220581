import { PaginatedResult, RestClient } from '@musicproworkshop/musicproworkshop.components';
import { IGroupMember, IGroupMemberManage, IGroupMemberSettings, IMemberSessionStats } from 'features/members/model';

const GroupMembers = {
    settings: (groupId: string): Promise<IGroupMemberSettings> => RestClient.get(`/groups/${groupId}/member/settings`),
    list: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupMember[]>> => RestClient.search(`/groups/${groupId}/members`, params),
    listManage: (groupId: string, params: URLSearchParams): Promise<PaginatedResult<IGroupMemberManage[]>> => RestClient.search(`/groups/${groupId}/members/manage`, params),
    detailsManage: (groupId: string, username: string): Promise<IGroupMemberManage> => RestClient.get(`/groups/${groupId}/members/manage/${username}`),
    join: (groupId: string, userName: string) => RestClient.post(`/groups/${groupId}/members/${userName}`, {}),
    leave: (groupId: string, userName: string, soft?: boolean) => RestClient.del(`/groups/${groupId}/members/${userName}${soft !== undefined ? '?soft=' + soft : ''}`),
    approve: (groupId: string, userName: string) => RestClient.put(`/groups/${groupId}/members/${userName}`, {}),
    assignRole: (groupId: string, userName: string, role: string | number) => RestClient.post(`/groups/${groupId}/members/${userName}/roles/${role}`, {}),
    updateSpeaker: (groupId: string, member: IGroupMember) => RestClient.put(`/groups/${groupId}/members/${member.username}/speaker`, member),
    stats: (groupId: string, userName: string): Promise<IMemberSessionStats[]> => RestClient.get(`/groups/${groupId}/members/manage/${userName}/stats`),

}
export default GroupMembers;