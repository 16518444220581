import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NiceModal from '@ebay/nice-modal-react';
import { Icon, Image, Menu, MenuItem, useViewport } from '@musicproworkshop/musicproworkshop.components';
import { useAccountStore } from "app/stores/useRootStore";
import { groupRootLink } from "app/common/utils";
import { DropDownMenu, TextButton } from "app/common/controls";
import { MainContainer } from "app/common/Styled";
import { useCurrentGroup, useGroupTheme } from "features/groupdetails/context";
import { GroupDisplaySettings, MessageTarget } from "features/groupdetails/model";
import LinkIcon from 'features/links/views/LinkIcon';
import { groupLinkOptions } from 'features/links/options'



const NavBar = () => {
  const { user } = useAccountStore();

  const { groupSettings, groupMemberSettings, currentGroupId, branded } = useCurrentGroup();
  const { darkThemeEnabled, toggleDarkTheme } = useGroupTheme();

  const { width } = useViewport();
  let navigate = useNavigate();
  let location = useLocation();

  const showGroupLinks = GroupDisplaySettings.ShowGroupLinks === ((groupSettings?.displaySettings ?? 0) & GroupDisplaySettings.ShowGroupLinks)
  const showMessages = MessageTarget.Site === ((groupSettings?.enabledMessageTargets ?? 0) & MessageTarget.Site)


  const handleLogOut = async () => {
    navigate('/logout', { replace: true });
  }

  const onLoggedInOrRegistered = () => {
    navigate(location.pathname, { replace: true });
  }

  return (
    <Menu fixed='top' inverted background='gradient' className='borderless'>
      <MainContainer>
        <MenuItem as={Link} to='https://hadassah.gospringboard.com/secure/israelatwar' target="_blank" rel="noopener noreferrer">
          <Image size='tiny' spaced="right" src='/assets/flags/il.svg' alt='' style={{ maxHeight: '45px' }} />
        </MenuItem>
        {!branded &&
          <MenuItem header as={Link} to="/groups" title='All Groups'>
            <img
              src="/assets/logos/logo-black.png"
              alt="logo"
              style={{ marginRight: "10px", maxHeight: '45px' }}
            />{" "}
            MPW
          </MenuItem>
        }
        {groupSettings && <>
          <MenuItem header as={Link} to={groupRootLink(branded, currentGroupId)} style={{ padding: '0' }} title={`${currentGroupId} Home Page`}>
            {branded &&
              <Image size='tiny' spaced='right'
                src={groupSettings.navBarLogoUrl ?? groupSettings.groupLogoUrl}
                alt="group logo"
              />
            }
            {width < 500 ? '' : width < 768 ? groupSettings.id : groupSettings.name}
          </MenuItem>
        </>}

        <Menu inverted floated='right' background='clear' style={{ marginRight: '10px' }}>
          {showGroupLinks && groupSettings?.links && <>
            {width >= 768 ?
              groupSettings.links.map((link, index) => (
                <MenuItem key={index} fitted style={{ padding: '4px' }} title={`${link.linkType}`} >
                  <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">
                    <LinkIcon iconType={link.linkType} size='big' linkOptions={groupLinkOptions} color='white' />
                  </a>
                </MenuItem>
              ))
              :
              <MenuItem fitted='horizontally' position='right' style={{ paddingLeft: '10px' }}>
                <DropDownMenu text="Links" style={{ borderTop: 'none', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                  <Menu vertical background='clear' style={{ padding: '5px', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    {groupSettings.links.map((link, index) => (
                      <MenuItem key={index} style={{ padding: '4px' }} title={`${link.linkType}`}>
                        <a href={link.linkUrl} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                          <LinkIcon iconType={link.linkType} size='big' linkOptions={groupLinkOptions} color='white' inverted />
                        </a>
                      </MenuItem>
                    ))}

                  </Menu>
                </DropDownMenu>
              </MenuItem>
            }

          </>}

          {groupMemberSettings?.canManage &&
            <MenuItem fitted style={{ padding: '4px' }} as={Link} to={`${groupRootLink(branded, currentGroupId)}manage`} title='Manage Group'>
              <Icon icon={['fas', 'cogs']} iconSize='big' />
            </MenuItem>
          }
        </Menu>
        {user ? (
          <MenuItem fitted='horizontally' style={{ marginRight: '10px' }} >
            <DropDownMenu text={user?.displayName} image={user.image || "/assets/user.png"} imageAlt={`${user.displayName}'s photo`} hasNew={showMessages && (groupMemberSettings?.messageCount ?? 0) > 0}>
              <Menu vertical pointing='top left' >
                {showMessages &&
                  <MenuItem as={Link} to={`${groupRootLink(branded, currentGroupId)}messages`} >
                    <Icon icon={['fas', 'envelope']} iconColor={(groupMemberSettings?.messageCount ?? 0) > 0 ? 'positive' : 'grey'} />
                    <span className='text' style={{ color: (groupMemberSettings?.messageCount ?? 0) > 0 ? 'var(--segmentTextColor)' : 'var(--segmentSubtleTextColor)' }}>Messages</span>
                    {(groupMemberSettings?.messageCount ?? 0) > 0 && <span className='description'>{groupMemberSettings?.messageCount ?? 0}</span>}
                  </MenuItem>
                }
                {groupMemberSettings?.isMember && <MenuItem as={Link} to={`${groupRootLink(branded, currentGroupId)}mytracks`}>
                  <Icon icon={['fas', 'music']} />
                  <span className='text'>My Tracks</span>
                </MenuItem>}
                <MenuItem as={Link} to='/profile'>
                  <Icon icon={['fas', 'user']} />
                  <span className='text'>My Profile</span>
                </MenuItem>
                <MenuItem as={Link} to='/account'>
                  <Icon icon={['fas', 'cog']} />
                  <span className='text'>My Account</span>
                </MenuItem>
                <MenuItem onClick={toggleDarkTheme}>
                  <Icon icon={['fas', 'adjust']} />
                  <span className='text'>Dark Theme</span>
                  <span className='description'>{darkThemeEnabled ? 'On' : 'Off'}</span>
                </MenuItem>
                <MenuItem onClick={handleLogOut}>
                  <Icon icon={['fas', 'power-off']} />
                  <span className='text'>Logout</span>
                </MenuItem>

              </Menu>

            </DropDownMenu>
          </MenuItem>
        ) : (
          <MenuItem fitted='horizontally' style={{ padding: '9px' }} >
            <TextButton text='Register' buttonColor='white' tertiary onClick={() => NiceModal.show('register-modal', { onRegistered: onLoggedInOrRegistered, size: 'mini' })} />
            <TextButton text='Login' buttonColor='white' tertiary onClick={() => NiceModal.show('login-modal', { onLoggedIn: onLoggedInOrRegistered, size: 'mini' })} />
          </MenuItem>
        )}
      </MainContainer>
    </Menu>
  );
};

export default observer(NavBar);
