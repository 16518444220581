import { linkOptions } from "./linkOptions";

export const profileLinkOptions =linkOptions;


/*

amazon
apple
bandcamp
facebook or facebook f blue
google play

itunes or itunes note
lastfm
 or linkedin in
patreon

*/