
import React from 'react';
import styled from 'styled-components';
import { InlineMessage } from '../../../app/common/controls';

interface Props {
    errors: any;
}

const ErrorList = styled.ul`
    text-align: left;
    padding: 0;
    opacity: 0.85;
    list-style-position: inside;
    margin: 0.5em 0 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }    
`
const ErrorListItem = styled.li`
    position: relative;
    list-style-type: none;
    margin: 0 0 0.3em 1em;
    padding: 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:before {
        position: absolute;
        content: '•';
        left: -1em;
        height: 100%;
        vertical-align: baseline;
    }
`

export default function ValidationErrors({ errors }: Props) {
    return (<>
        {errors &&
            <InlineMessage error content={<ErrorList>{errors?.map((err: any, i: any) => (<ErrorListItem key={i}>{err}</ErrorListItem>))}</ErrorList>} />
        }
    </>)
}