import React from 'react'
import { SmartButton, SmartButtonProps } from '@musicproworkshop/musicproworkshop.components';
import { useHref } from 'react-router-dom';

export const ButtonBase = ({ to, ...rest }: SmartButtonProps) => {
    try {
        const l = useHref(to ?? '');
        if (to) {
            //console.log('IconButton', 'props', rest, 'to', to, 'useHref', l)
            rest.href = l;
            //rest.to = undefined;
        }

    } catch (error) {
    }
    
    return <SmartButton {...rest} />
}

export default ButtonBase
