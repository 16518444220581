import styled from "styled-components";

export const Collapsible = styled.div<{ open: boolean }>`
    width:100%;
    margin:0;
    max-height: ${props => props.open ? "100%" : "0"};
    overflow: hidden;
    padding-top: ${props => props.open ? "25px" : "0"};
    padding-left:0;
    padding-right:0;
    transition: all 0.3s ease-out;
`