import styled from 'styled-components';

export const MessageContainer = styled.div`
    text-align: left;
    background-color: var(--segmentBackgroundLighterColor);
    border: none;
    /* box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%); */
    flex:0 0 auto;
    border-radius: 0.28571rem;
    user-select: text;
    padding:0 !important;
    > :first-child {
        padding-top: 0.75em;
    }
    > :last-child {
        padding-bottom: 0.75em;
    }
    & > * {
        padding-left: 0.75em;
        padding-right: 0.75em;
    }
`;

export const MessageContent = styled.div`
    display: block;
    width: 100%;
    font-size: 1.15em;
    line-height: 1.4;
    padding: 2em 1em 3em;
    background-color: var(--segmentBackgroundLighterColor);
`;
