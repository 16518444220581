import { runInAction, makeAutoObservable } from "mobx";
import { format } from "date-fns";
import { OptionType } from "@musicproworkshop/musicproworkshop.components";
import { groupBy, parseDate } from "app/common/utils";
import { IGroupMemberSettings } from "features/members/model";
import sessionListApiClient from './sessionListApi';
import { setSessionProps } from "features/sessiondetails/context/sessionHelpers";
import { IGroupSession, SessionFeatures, SessionStatus } from "features/sessiondetails/model";
import { ICategory } from "features/sessionlist/model";
import PagingHelper from "app/common/paging/pagingHelper";
import { ISubmittedTrack } from "features/tracks/model";

export class SessionListStore {
    constructor() {
        if (!this.data)
            this.data = new PagingHelper(20, this.loadItems);
        else
            this.data.setLoadItems(this.loadItems)
        makeAutoObservable(this);
    }

    data: PagingHelper<IGroupSession> = new PagingHelper(20);
    loading = false;
    loadingCategories = false;
    loadingDates = false;
    sessionDates: Date[] = [];
    sessionCategories: ICategory[] = [];

    groupId: string | undefined | null = null;
    memberSettings: IGroupMemberSettings | null = null; //This is a temp solution for setProps 

    initializeSessionStore = (groupId: string | undefined | null, memberSettings?: IGroupMemberSettings | null) => {
        //console.log('initializeSessionStore', groupId);
        this.groupId = groupId;
        this.memberSettings = memberSettings ?? null;
    }

    get categoryOptions() {
        if (!this.sessionCategories) return [];
        let options: OptionType[] = [];
        this.sessionCategories.forEach((category) => options.push({ label: category.name, value: category.name }));
        return options;
    }

    get submittedTracksFlat() {
        return Array.from(this.data.items.values()).flatMap((item: IGroupSession) => {
            return item.submittedTracks?.flatMap((track) => {
                return { sessionId: item.id, sessionTab: item.tab, sessionTitle: item.title, sessionDate: item.date, ...track } as ISubmittedTrack;
            });
        })
    }

    get sessionsByDate() {

        let sessions = this.groupSessionsByDate(Array.from(this.data.items.values()));
        return Object.entries(sessions);
    }

    groupSessionsByDate(sessions: IGroupSession[]) {
        let sortDirection = 1;
        if (this.data.predicate.has('status') && this.data.predicate.get('status') === 'past') sortDirection = -1;
        else if (this.data.predicate.has('submitterUserName') && this.data.predicate.get('submitterUserName').length > 0) sortDirection = -1;

        const sortedSessions = sessions.sort(
            (a, b) => sortDirection === -1 ? b.date.getTime() - a.date.getTime() : a.date.getTime() - b.date.getTime()
        );
        const grouped = groupBy(sortedSessions, 'group');
        return grouped;
    }

    setFilter = (predicateName: string, value: string | Date, reload: boolean) => {
        if (predicateName === 'status') this.data.deletePredicate('selectedDate', false);
        if (predicateName === 'selectedDate') this.data.deletePredicate('status', false);

        if (this.data.predicate.has(predicateName) && this.data.predicate.get(predicateName) === value) return false;

        this.data.setPredicate(predicateName, value, false, reload);
        //if (!fromQuery) updateQueryString();
        return true;
    }

    minDate: Date = new Date();
    maxDate: Date = new Date();
    loadSessionDates = async (tab: string, fromDate: Date, toDate: Date) => {
        if (!this.groupId) return;
        // let df = 'yyyy-MM-dd HH:mm';
        // console.log('loadSessionDates', 'minDate', format(this.minDate, df), 'maxDate', format(this.maxDate, df), 'fromDate', format(fromDate, df), 'toDate', format(toDate, df))
        //TODO: Add support for skipping months in the calendar (maybe just search if we have any dates in the array between from/to)
        this.loadingDates = true;
        if (fromDate > this.minDate && toDate < this.maxDate) return;
        if (fromDate < this.minDate) this.minDate = fromDate;
        if (toDate > this.maxDate) this.maxDate = toDate;
        try {
            const params = new URLSearchParams();
            if (tab) params.append('tab', tab);
            if (fromDate) params.append('fromDate', format(fromDate, 'yyyy-MM-dd'));
            if (toDate) params.append('toDate', format(toDate, 'yyyy-MM-dd'));
            const dates = await sessionListApiClient.sessiondates(this.groupId, params);
            runInAction(() => {
                let allDates = this.sessionDates.slice();
                if (allDates.length === 0) allDates = [];
                dates.forEach(sessionDate => {
                    let d = parseDate(sessionDate)!;
                    if (allDates.indexOf(d) < 0)
                        allDates.push(d);
                });
                this.sessionDates = allDates;
                this.loadingDates = false;
            })
        } catch (error) {
            console.error(error);
            this.loadingDates = false;
        }
    }

    loadSessionCategories = async (tab?: string, manage?: boolean, groupId?: string | null) => {
        if (groupId) this.groupId = groupId
        if (!this.groupId) return [];
        this.loadingCategories = true;
        try {
            const params = new URLSearchParams();
            if (tab) params.append('tab', tab);
            if (manage) params.append('manage', String(manage));
            //TODO add dates (maybe just use the same predicate)
            const categories = await sessionListApiClient.sessionCategories(groupId ?? this.groupId, params);
            runInAction(() => {
                this.sessionCategories = [];
                categories.forEach(category => {
                    this.sessionCategories.push(category)
                })
                this.loadingCategories = false;
            })
            return categories;
        } catch (error) {
            console.error(error);
            this.loadingCategories = false;
        }
        return [];
    }

    loadItems = async (): Promise<IGroupSession[]> => {
        if (!this.groupId || this.data.loadingItems) return [];
        //console.log('loadItems', this.groupId);
        this.data.loadingItems = true;
        try {
            const result = this.data.searchParams.has('submitterUserName') ?
                await sessionListApiClient.submittedTracks(this.groupId, this.data.searchParams)
                :
                await sessionListApiClient.list(this.groupId, this.data.searchParams);
            ;

            const { data, pagination } = result;
            runInAction(() => {
                if (this.data.paging.currentPage === 1) this.data.items.clear();
                data.forEach(groupSession => {
                    setSessionProps(groupSession, this.memberSettings);
                    this.data.items.set(groupSession.id, groupSession);
                });
                this.data.paging = pagination;
                this.data.loadingItems = false;
                return data;
            })
        } catch (error) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            console.error(error);
        }
        return [];
    }

    updateSessionItem = (sessionId: string, deleted?: boolean, canSendMessage?: boolean): IGroupSession | null => {
        //This is for management, but since the session list is here, the update method is here as well
        let session = this.data.items.get(sessionId);
        if (session) {
            if (deleted !== undefined) session.deleted = deleted;
            if (canSendMessage !== undefined) {
                //just set both, since only one can be visible anyway it doesn't really matter if we turn off the other as well
                session.canSendPublishMessage = canSendMessage;
                session.canSendRecapMessage = canSendMessage;
            }
            this.data.items.set(sessionId, session);
            return session;
        }
        return null;
    }

    //This should be in management store
    loadSessionOptions = async (groupId: string) => {
        let params = new URLSearchParams();
        params.append('manage', 'true');
        params.append('status', 'featured');
        params.append('features', SessionFeatures[SessionFeatures.InSyncPlayer]);
        let groupSessions = await sessionListApiClient.list(groupId, params)
        let sessionOptions: OptionType[] = [];
        groupSessions.data.filter(s => s.status !== SessionStatus.Ended).forEach(session => {
            sessionOptions.push({ value: session.id, label: session.title })
        });
        return sessionOptions;
    }

    reset = () => {
        this.data.reset();
    }


}

