import { runInAction, makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import PagingHelper from "app/common/paging/pagingHelper";
import { parseDate } from "app/common/utils";
import { IUser } from "features/account/model/user";
import { IGroupMember, GroupMemberRole, IGroupMemberManage, IMemberSessionStats } from "features/members/model";
import membersApiClient from "./groupMembersApi";

export class GroupMembersStore {
    constructor() {
        if (!this.data)
            this.data = new PagingHelper(100, this.loadItems);
        else
            this.data.setLoadItems(this.loadItems)
        makeAutoObservable(this);
    }

    data: PagingHelper<IGroupMember> = new PagingHelper(100);

    groupId: string | null = null;
    currentUserName: string | null = null;
    organizerOnTop: boolean = true;
    loadingStats: boolean = false;
    memberStats: IMemberSessionStats[] | null = null;

    initializeGroupMemberStore = (groupId?: string, currentUserName?: string, sortOrder?: string, organizerOnTop?: boolean) => {
        this.groupId = groupId ?? null;
        this.currentUserName = currentUserName ?? null;
        this.data.sortOrder = sortOrder ?? "displayName";
        this.organizerOnTop = organizerOnTop ?? true;
        this.data.predicate.set("organizerOnTop", this.organizerOnTop);
    }

    submitting = false;

    memberNameFilter: string = '';

    get memberItem(): IGroupMemberManage { return this.data.item as IGroupMemberManage; }

    setMemberNameFilter = (nameFilter: string) => {
        //console.log('GroupMembersStore', 'setMemberNameFilter', nameFilter, this.memberNameFilter);
        const continued = this.memberNameFilter.length > 1 && nameFilter.startsWith(this.memberNameFilter);
        this.memberNameFilter = nameFilter;
        this.data.setPredicate('nf', this.memberNameFilter);
        // try to get more users with this filter
        if (!continued && this.data.paging.currentPage < this.data.paging.totalPages && this.memberNameFilter.length > 1) {
            //console.log('GroupMembersStore', 'setMemberNameFilter', 'new search, fetch more records if needed');
            this.loadItems();
        }
    }

    get groupLeaders() {
        let users = Array.from(this.data.items.values()).filter((item: IGroupMember) => item.role < GroupMemberRole.Member);
        let sorted = users.sort((a, b) => a.oso !== b.oso ? ((a.oso === 0 ? 100 : a.oso) - (b.oso === 0 ? 100 : b.oso)) : (a.joined?.getTime() ?? 0) - (b.joined?.getTime() ?? 0));
        let filtered = sorted.filter(m => m.displayName.toLowerCase().includes(this.memberNameFilter.toLowerCase()))
        return filtered;
    }

    get groupMembers() {
        let users = Array.from(this.data.items.values());
        //let sorted = users.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        if (this.organizerOnTop) {
            users = users.filter((item: IGroupMember) => item.role === GroupMemberRole.Member)
        }
        let filtered = users.filter(m => m.displayName.toLowerCase().includes(this.memberNameFilter.toLowerCase()))
        return filtered;
    }
    get groupPendingMembers() {
        let users = Array.from(this.data.items.values());
        //let sorted = users.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        if (this.organizerOnTop) {
            users = users.filter((item: IGroupMember) => item.role === GroupMemberRole.PendingMember)
        }
        let filtered = users.filter(m => m.displayName.toLowerCase().includes(this.memberNameFilter.toLowerCase()))
        return filtered;
    }

    loadItems = async (): Promise<IGroupMember[]> => {
        if (!this.groupId) return [];
        try {
            this.data.loadingItems = true;
            const manage = this.data.predicate.has('manage') && this.data.predicate.get('manage') === 'true';
            //this.data.predicate.set("links", "*");
            const pagedData = manage ?
                await membersApiClient.listManage(this.groupId, this.data.searchParams) :
                await membersApiClient.list(this.groupId, this.data.searchParams);

            runInAction(() => {
                const { data, pagination } = pagedData;
                data.forEach((member) => {
                    this.data.items.set(member.username, this.setMemberProps(member));
                });
                if (!this.memberNameFilter) {
                    this.data.paging = pagination;
                }
                this.data.loadingItems = false;
                return data;
            })
        } catch (error) {
            this.data.loadingItems = false;
            console.error('loadGroupMembers', error);
        }
        return [];
    }

    setMemberProps = (member: IGroupMember) => {
        member.joined = parseDate(member.joined);
        if ("previousVisit" in (member as any)) {
            let m = member as IGroupMemberManage;
            m.lastActive = parseDate(m.lastActive)
            m.previousVisit = parseDate(m.previousVisit)
            m.lastAttendedOn = parseDate(m.lastAttendedOn)
            m.lastSubmittedOn = parseDate(m.lastSubmittedOn)
            m.roleName = GroupMemberRole[member?.role === 1 ? 2 : member?.role].split(/(?=[A-Z])/).join('-')
            return m;
        }
        return member;

    }

    loadMemberDetails = async (username?: string) => {
        if (!this.groupId || !username) return;
        //TODO: find in members list first

        this.data.loadingItems = true;
        try {
            const member = await membersApiClient.detailsManage(this.groupId, username)
            runInAction(() => {
                this.setMemberProps(member)
                //this.data.items.set(member.username, member);
                this.data.item = member;
                this.data.loadingItems = false;
            })
        } catch (error) {
            runInAction(() => {
                this.data.loadingItems = false;
            })
            toast.error('Problem loading member');
        }
    }

    loadMemberStats = async (username?: string) => {
        if (!this.groupId || !username) return;
        this.loadingStats = true;
        try {
            const stats = await membersApiClient.stats(this.groupId, username)
            runInAction(() => {
                stats.forEach((stat) => {
                    stat.date = parseDate(stat.date)!;
                })
                this.memberStats = stats;
                this.loadingStats = false;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingStats = false;
            })
            toast.error('Problem loading member stats');
        }
    }

    restoreMember = async (username: string) => {
        if (!this.groupId) return;

        this.submitting = true;
        try {
            await membersApiClient.join(this.groupId, username);
            runInAction(() => {
                let member = this.data.items.get(username) as IGroupMemberManage;
                member.deleted = false;
                this.submitting = false;
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error('Problem restoring member');
        }
    }

    joinGroup = async (user: IUser) => {
        if (!this.groupId) return;

        this.submitting = true;
        try {
            await membersApiClient.join(this.groupId, user.username!);
            runInAction(() => {
                let member = this.createMember(user, GroupMemberRole.Member);
                this.data.items.set(member.username, member);
                this.submitting = false;
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error('Problem joining group');
        }
    }

    leaveGroup = async (username: string, soft?: boolean) => {
        if (!this.groupId) return;

        this.submitting = true;
        try {
            //soft
            await membersApiClient.leave(this.groupId, username, soft)
            runInAction(() => {
                if (soft) {
                    let member = this.data.items.get(username) as IGroupMemberManage;
                    if (member) {
                        member.deleted = true;
                        member.deletedBy = this.currentUserName!;
                        this.data.items.set(member.username, member);
                    }
                } else {
                    this.data.items.delete(username);
                }
                this.submitting = false;

            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error('Problem leaving group');
        }

    }

    approveMember = async (username: string) => {
        if (!this.groupId) return;

        this.submitting = true;
        try {
            await membersApiClient.approve(this.groupId, username);
            runInAction(() => {
                let member = this.data.items.get(username) as IGroupMemberManage;
                member.role = GroupMemberRole.Member;
                this.data.items.set(member.username, member);
                this.submitting = false;
            })
        } catch (error) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error('Problem approving member');
        }
    }

    createMember = (user: IUser, role: GroupMemberRole): IGroupMember => {
        return {
            displayName: user.displayName,
            username: user.username,
            image: user.image!,
            role: role,
            isSpeaker: false
        }
    }
}